import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyBetsComponent } from 'src/app/modules/my-bets/my-bets.component';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';

const routes: Routes = [
  {
    path: 'sports',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.SportsBook },
  },
  {
    path: 'sports/:betsTab',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.SportsBook },
  },
  {
    path: 'virtuals',
    redirectTo: 'virtuals/scheduled',
    pathMatch: 'full',
  },
  {
    path: 'virtuals/scheduled',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.Virtuals, virtualsLeague: VirtualsLeagueType.Scheduled },
  },
  {
    path: 'virtuals/scheduled/:betsTab',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.Virtuals, virtualsLeague: VirtualsLeagueType.Scheduled },
  },
  {
    path: 'virtuals/instant',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.Virtuals, virtualsLeague: VirtualsLeagueType.Instant },
  },
  {
    path: 'virtuals/instant/:betsTab',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.Virtuals, virtualsLeague: VirtualsLeagueType.Instant },
  },
  {
    path: 'jackpotbets',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.JackpotBets },
  },
  {
    path: 'jackpotbets/:betsTab',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.JackpotBets },
  },
  { path: '**', redirectTo: 'sports' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyBetsRoutingModule {}
