<div class="close" (click)="navigateBack()">
  <i class="material-icons icon">{{ 'close' }}</i>
</div>

<ng-container [ngSwitch]="receiptState$ | async">
  <!-- Bet Success with Verified Number Screen -->
  <ng-container *ngSwitchCase="receiptStates.Confirmation" [ngTemplateOutlet]="confirmation"></ng-container>
  <!-- Bet Success without Verified Number Screen -->
  <ng-container *ngSwitchCase="receiptStates.PreVerification" [ngTemplateOutlet]="preVerification"></ng-container>
  <!-- Number Input Screen -->
  <ng-container *ngSwitchCase="receiptStates.InputNumber">
    <mobver-mobile-number-verification
      [content]="couponQuery.couponReceiptPhoneVerificationContent$ | async"
      (proceedClicked)="proceedFromVerifyNumber($event)"
    >
    </mobver-mobile-number-verification>
  </ng-container>
  <!-- OTP Screen -->
  <ng-container *ngSwitchCase="receiptStates.InputOTP">
    <app-otp-screen
      [content]="couponQuery.couponReceiptPhoneVerificationContent$ | async"
      [mobilePrefix]="mobilePrefix$ | async"
      [mobileNumber]="mobileNumber$ | async"
      [genericError]="otpInputGenericError$ | async"
      [otpOption]="5"
      (postOTP)="postOTP()"
    ></app-otp-screen>
  </ng-container>
  <!-- Verification Success -->
  <ng-container *ngSwitchCase="receiptStates.NumberVerified">
    <div class="post-verification">
      <div class="top-half-bg"></div>
      <div
        class="verification-success"
        *ngIf="couponQuery.couponReceiptPhoneVerificationContent$ | async as receiptPhoneVerficiationContent"
      >
        <h1 class="success-text-header">
          {{ receiptPhoneVerficiationContent.phoneVerificationSuccessHeader }}
        </h1>
        <div class="success-text-subtitle" [innerText]="receiptPhoneVerficiationContent.phoneVerificationSuccessMessage"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="default-view">
      <div class="animation">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <h1 class="success-text-header" i18n>Bet Placed!</h1>
        <ng-container *ngTemplateOutlet="ctaButtons"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #confirmation>
    <div class="confirmation" *ngIf="receiptContent$ | async as receiptContent">
      <div class="top-half-bg"></div>
      <div class="animation">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <ng-container
          *ngTemplateOutlet="couponQuery.isLastPlacedCouponInEvaluation ? evaluation : success; context: { receiptContent: receiptContent }"
        >
        </ng-container>
      </div>
      <div class="message">
        <img
          class="logo"
          [width]="receiptContent.upsellImage.data.attributes.width"
          [height]="receiptContent.upsellImage.data.attributes.height"
          [alt]="receiptContent.upsellImage.data.attributes.name"
          [src]="receiptContent.upsellImage.data.attributes.url"
        />
        <div class="text">
          {{ receiptContent.upsellMessageText }}
        </div>
        <div class="buttons">
          <app-button
            [text]="receiptContent.upsellForwardCTAText"
            [customButtonStyle]="messageCtaAdditionalStyles"
            [buttonType]="buttonType.Transparent"
            (click)="upsellNavigateForward()"
          ></app-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #preVerification>
    <div class="pre-verification">
      <div class="top-half-bg"></div>
      <div class="animation" *ngIf="receiptContent$ | async as receiptContent">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <ng-container *ngTemplateOutlet="isInEvaluation ? evaluation : success; context: { receiptContent: receiptContent }">
        </ng-container>
      </div>
      <div class="message" *ngIf="couponQuery.couponReceiptPhoneVerificationContent$ | async as receiptPhoneVerficiationContent">
        <div class="text">
          {{ receiptPhoneVerficiationContent.phoneVerificationMessage }}
        </div>
        <div class="buttons">
          <app-button
            [text]="receiptPhoneVerficiationContent.phoneVerificationBackCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            [buttonType]="buttonType.Transparent"
            (click)="navigateBack()"
          ></app-button>
          <app-button
            [text]="receiptPhoneVerficiationContent.phoneVerificationForwardCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            (click)="navigateForward()"
          ></app-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #success let-receiptContent="receiptContent">
    <ng-container
      *ngIf="{
        accaBonusPercentage: accaBonusPercentage$ | async,
        betSuccessTitle: betSuccessTitle$ | async,
        bookingCode: bookingCode$ | async,
        flexiCut: flexiCut$ | async,
        hasFreebet: hasFreeBetSelected$ | async,
        notificationsEnabled: notificationsEnabled$ | async,
        potentialWin: potentialWin$ | async
      } as betSuccessDetails"
    >
      <h1 class="success-text-header">
        <span>{{ betSuccessDetails.betSuccessTitle }}</span>
      </h1>
      <div class="bet-details-grid">
        <ng-container *ngIf="betSuccessDetails.bookingCode">
          <div class="bet-details-grid-item title booking-code" i18n>
            {{ receiptContent.betReceiptConfirmationDetails.bookingCodeTitle }}
          </div>
          <div i18n class="bet-details-grid-item value booking-code">
            <button class="share-button-icon" (click)="performShareBet()">
              <i class="material-icons">{{ 'ios_share' }}</i>
            </button>
            <span (click)="copyBookingCode()">{{ betSuccessDetails.bookingCode }}</span>
          </div>
        </ng-container>
        <div class="bet-details-grid-item title potential-win" i18n>
          {{ receiptContent.betReceiptConfirmationDetails.netPotentialWinningsTitle }}
        </div>
        <div class="bet-details-grid-item value potential-win">{{ betSuccessDetails.potentialWin }}</div>
        <ng-container *ngIf="betSuccessDetails.accaBonusPercentage">
          <div class="bet-details-grid-item title accumulator-bonus" i18n>
            {{ receiptContent.betReceiptConfirmationDetails.accaBonusTitle }}
          </div>
          <div class="bet-details-grid-item value accumulator-bonus">{{ betSuccessDetails.accaBonusPercentage }}</div>
        </ng-container>
        <ng-container *ngIf="betSuccessDetails.flexiCut">
          <div class="bet-details-grid-item title flexicut" i18n>{{ receiptContent.betReceiptConfirmationDetails.flexicutTitle }}</div>
          <div i18n class="bet-details-grid-item value flexicut">{{ betSuccessDetails.flexiCut }}</div>
        </ng-container>
        <ng-container *ngIf="showGoalNotificationsSwitch">
          <div class="bet-details-grid-item title goal-notifications" i18n>
            {{ receiptContent.betReceiptConfirmationDetails.goalNotificationsTitle }}
          </div>
          <div i18n class="bet-details-grid-item value goal-notifications">
            <app-toggle-switch [switch]="betSuccessDetails.notificationsEnabled" (click)="toggleGoalNotifications()"></app-toggle-switch>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="ctaButtons"></ng-container>
  </ng-template>

  <ng-template #evaluation>
    <h1 class="success-text-header" i18n>Your Bet Request Is Being Evaluated</h1>
    <div class="success-text-subtitle" i18n>Please Wait A Few Moments For Confirmation.</div>
  </ng-template>

  <ng-template #ctaButtons>
    <div class="buttons-container">
      <app-button
        class="retain-selections-button"
        text="Re-Use"
        (click)="retainSelections()"
        [buttonType]="buttonType.Transparent"
        [customButtonStyle]="ctaAdditionalStyles"
        *ngIf="canRetainSelections$ | async"
      >
      </app-button>
      <app-button class="navigate-forward-button" text="View My Bets" (click)="navigateForward()" [customButtonStyle]="ctaAdditionalStyles">
      </app-button>
    </div>
  </ng-template>
</ng-container>
