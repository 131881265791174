import { EntityState, ID } from '@datorama/akita';

export class MessageModel {
  id: ID;
  subject: string;
  date: Date;
  read: Date;
  priority: number;
  state: number;
  body: any;
  direction: number;

  constructor(init: Partial<MessageModel>) {
    Object.assign(this, init);
  }
}

export class MessageFilterModel {
  filteredState: boolean;
  dateFrom: Date;
  dateTo: Date;
  state: number;
}

export interface MessagesState extends EntityState<MessageModel> {
  ui: {
    unreadMessageCount: number;
    totalMessageCount: number;
    moreMessagesLoading: boolean;
    messagesPage: number;
    filter: MessageFilterModel;
  };
  viewingMessage: MessageModel | undefined;
}

// export function createMessage(params: Partial<Message>) {
//   return {

//   } as Message;
// }
