import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'betFinalStateToUI' })
export class BetFinalStateToUI implements PipeTransform {
  transform(text: string): string {
    if (text === 'Placed') {
      return 'Running';
    } else {
      return text.replace(/([A-Z])/g, ' $1');
    }
  }
}
