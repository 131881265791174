import { AnimationOptions } from 'ngx-lottie';

export const circleLoaderLoading: AnimationOptions = {
  path: './assets/json/circle-loader-loading.json',
  autoplay: true,
  loop: true
};

export const circleLoaderSuccess: AnimationOptions = {
  path: './assets/json/circle-loader-success.json',
  autoplay: true,
  loop: false
};
