<div class="overlay-dropdown" (click)="openDropdown()">
  <ng-container *ngTemplateOutlet="buttonTemplate || defaultButton; context buttonContext">
  </ng-container>
</div>

<ng-template #defaultButton>
  <div class="selected-item" [ngClass]="{'opened over-backdrop': isOpen$ | async}">
    <i class="fa fa-sort" aria-hidden="true"></i>
    <span class="ellipsis">
      {{ selectedItem?.displayText }}
    </span>
    <i class="fa fa-caret-down" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template #dropdownOptions>
  <div class="overlay-dropdown-options" [ngClass]="{'full-page-overlay': fullPageOverlay}">

    <div class="option header" *ngIf="hasHeader" (click)="disposeDropdown()">
      <span class="material-icons icon">{{'close'}}</span>
      <span i18n class="title">{{ headerText }}</span>
    </div>

    <div class="option" *ngFor="let option of options" (click)="optionClicked(option)"
      [class.selected]="selectedItem && isOptionSelected(option)">
      <span *ngIf="showOptionIcon" class="sport-icon icon icon-{{ option.value }} text-dark"></span>
      <span>{{option.displayText}}</span>
      <div class="ribbon-container" *ngIf="showIsNewTag(option.value)">
        <div class="ribbon" i18n>NEW!</div>
      </div>
    </div>

    <div class="no-options" *ngIf="options.length === 0">
      <span class="material-icons">{{'sentiment_dissatisfied'}}</span>
      <div class="label" i18n>{{ noDataText }}</div>
      <button class="btn" (click)='disposeDropdown()' i18n>Back</button>
    </div>
  </div>
</ng-template>
