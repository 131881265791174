import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class Highlight implements PipeTransform {
  transform(value: string, keyword: string): string {
    if (!keyword) {
      return value;
    }

    const eKeyword = escapeRegExp(keyword);

    const pattern = new RegExp(eKeyword, 'gi');
    return value.replace('&amp;', '&').replace(pattern, match => `<span class="highlighted">${match}</span>`);
  }
}

function escapeRegExp(value: string): string {
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
