<div>
  <div class="logo"><img src="assets/images/logo/brand-logo-white.svg" alt="Betking Logo"></div>
  <h3 class="app-update-required">
    <span i18n>A new version is available, please update to use the App.</span>
  </h3>
  <div class="button-area">
    <app-button i18n-text text="Update" buttonType="normal" [customButtonStyle]="actionButtonStyle"
    (click)="downloadApp()">
    </app-button>
  </div>
</div>
