import { camelCase, isArray, isPlainObject } from 'lodash-es';

export const mapToCamelCase = (source: any) => {
  if (isPlainObject(source)) {
    const camelCased = {};

    Object.keys(source).forEach(key => {
      camelCased[camelCase(key)] = mapToCamelCase(source[key]);
    });

    return camelCased;
  } else if (isArray(source)) {
    return source.map(mapToCamelCase);
  }

  return source;
};
