import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { JackpotEngineQuery } from 'src/app/core/state/jackpot-engine/jackpot-engine.query';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { JackpotMappingCMS } from 'src/app/shared/models/jackpot-engine.model';
import { JackpotEngineStore } from 'src/app/core/state/jackpot-engine/jackpot-engine.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { LoggerService } from './logger.service';
import { APIService } from './api.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class JackpotEngineService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly jackpotEngineQuery: JackpotEngineQuery,
    private readonly loggingService: LoggerService,
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly jackpotEngineStore: JackpotEngineStore
  ) {}

  getJackpotsCms(): Observable<JackpotMappingCMS[]> {
    if (this.jackpotEngineQuery.jackpotEngineMappings) {
      return of(this.jackpotEngineQuery.jackpotEngineMappings);
    }

    const params = new URLSearchParams({
      locale: this.languageService.strapiCMSLocale,
      'populate[jackpots][fields][0]': 'calculateProgressBarByDropAmount',
      'populate[jackpots][fields][1]': 'jackpotDisplayName',
      'populate[jackpots][fields][2]': 'jackpotDropInfo',
      'populate[jackpots][fields][3]': 'jackpotId',
      'populate[jackpots][fields][4]': 'jackpotInfo',
      'populate[jackpots][fields][5]': 'jackpotSlug',
      'populate[jackpots][populate][jackpotLogo][fields]': 'url',
    });

    const url = `virtuals-jackpot?${params.toString()}`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(({ data }) => data?.attributes?.jackpots),
      tap(data => {
        !!data && this.jackpotEngineStore.updateJackpotEngineMapping(data);
      }),
      takeUntil(this.destroy$)
    );
  }

  getJackpotName(jackpotId: number | string): string {
    // eslint-disable-next-line
    const jackpotName = this.jackpotEngineQuery.jackpotEngineMappings?.find(jackpot => jackpot.jackpotId == jackpotId)?.jackpotDisplayName;
    if (!jackpotName) {
      this.loggingService.logEvent('JP Error', `Jackpot name mapping error for jackpot with id ${jackpotId}`, SeverityLevel.Error);
    }

    return jackpotName || 'Jackpot';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
