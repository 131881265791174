<div class="details">
  <div class="date-time" *ngIf="event.eventCategory === 'F'">
    <div class="date" *ngIf="showDate">
      {{ event.date | todayDate }}
    </div>
    <div class="time">
      {{ event.date | date: 'HH:mm' }}
    </div>
  </div>
  <!-- Current Time for Soccer -->
  <div class="match-time" *ngIf="!isLiveListWidget && event.eventCategory === 'L' && event.sportId === 1 && event.matchTime">
    <div class="progress-bar"
      [ngStyle]="{ 'background': 'radial-gradient(closest-side, #eef2fa 80%, transparent 90% 100%), conic-gradient(#03a9f4 ' + calculateTimePercentage(event.matchTime) + '%, #dee6f5 0)'}">
      <span>{{ event.matchTime }}</span>
      <span class="blink">'</span>
    </div>
  </div>

  <div class="event-competitors">
    <div class="teams" [class.live-list-widget]="isLiveListWidget">
      <div class="team"> {{ event.homeTeam }} </div>
      <div class="team"> {{ event.awayTeam }} </div>
    </div>
    <div class="service-owner-score" *ngIf="event.eventCategory === 'L'">
      <ng-container *ngIf="showExtraScores(event.sportId); else showTotalScoreOnly">
        <div class="service-owner" *ngIf="event.serviceOwner && event.serviceOwner !== serviceOwner.NotSet">
          <div class="service home">
            <span class="item-icon" *ngIf="event.serviceOwner === serviceOwner.HomeTeam"></span>
          </div>
          <div class="service away">
            <span class="item-icon" *ngIf="event.serviceOwner === serviceOwner.AwayTeam"></span>
          </div>
        </div>
        <div class="score">
          <div class="set-score" *ngIf="event.setScores">
            <div class="set" *ngFor="let score of event.setScores.split(' - '); trackBy: indexTrackBy">
              <div class="home" [@fadeIn]>{{ score | displayScore: 'home' }}</div>
              <div class="away" [@fadeIn]>{{ score | displayScore: 'away' }}</div>
            </div>
          </div>
          <!-- Current Score for Tennis -->
          <div class="set-score" *ngIf="event.sportId === 5">
            <div class="current">
              <div class="home" [class.winning]="event.homeGameScore > event.awayGameScore" [@fadeIn]>
                {{ event.homeGameScore?.replace('50', 'A') }}
              </div>
              <div class="away" [class.winning]="event.homeGameScore < event.awayGameScore" [@fadeIn]>
                {{ event.awayGameScore?.replace('50', 'A') }}
              </div>
            </div>
          </div>
          <!-- Total Score -->
          <div class="set-score">
            <div class="total">
              <div class="home" [@fadeIn]>{{ event.score | displayScore: 'home' }}</div>
              <div class="away" [@fadeIn]>{{ event.score | displayScore: 'away' }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #showTotalScoreOnly>
      <div class="score">
        <!-- Total Score -->
        <div class="set-score">
          <div class="total">
            <div class="home" [@fadeIn]>{{ event.score | displayScore: 'home' }}</div>
            <div class="away" [@fadeIn]>{{ event.score | displayScore: 'away' }}</div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
