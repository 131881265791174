import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuPageWrapperComponent } from 'src/app/modules/menu/components/menu-page-wrapper/menu-page-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: MenuPageWrapperComponent,
    pathMatch: 'full',
    data: { inCoupon: false, inMyBets: false, isSportsSection: false },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MenuRoutingModule {}
