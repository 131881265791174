import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Injectable()
export class UserDataCheckGuard implements CanActivate {
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly location: Location,
    private readonly appConfigService: AppConfigService,
    private readonly notificationService: NotificationService
  ) {}

  canActivate(): boolean {
    if (this.appConfigService.get('account').checkUserDataUponWithdraw && !this.accountQuery.isUserDataComplete) {
      this.notificationService.showNotification(
        new NotificationSettings({
          contentHtml: `
            <div class='withdraw-notif-content'>
              <i class="fa fa-university icon" aria-hidden="true"></i>
              <span class='text'>
                ${$localize`In order to be able to withdraw funds from your Betking account, we kindly ask you to complete the full registration process.`}
              </span>
            </div>
          `,
          showCloseButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: $localize`Proceed`,
          allowBackdropClose: false,
          confirmButtonCallback: () => {
            this.router.navigateByUrl('account/edit-profile');
          },
          cancelButtonCallback: () => {
            this.location.back();
          },
        })
      );
    }

    return true;
  }
}
