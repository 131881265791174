import { Injectable, OnDestroy } from '@angular/core';
import { CouponType } from 'clientside-coupon';
import { combineLatest, forkJoin, iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { APIType, APISettings } from 'src/app/shared/models/api.model';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { FreebetsStore } from 'src/app/modules/freebets/state/freebets.store';
import {
  FreeBetProductType,
  FreebetsBetslipContent,
  FreebetsConfiguration,
  FreebetsMyBetsContent,
  OldFreebetsVoucher,
} from 'src/app/modules/freebets/models/freebets.model';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { FreeBetsService } from './interfaces/free-bets-service';

@Injectable()
export class SportsbookFreeBetService implements FreeBetsService, OnDestroy {
  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly couponQuery: CouponQuery,
    private readonly freebetsQuery: FreebetsQuery,
    private readonly freebetsStore: FreebetsStore,
    private readonly languageService: LanguageService
  ) {}

  initialiseFreebetsConfig = (): Observable<void> => {
    const call$ = this.apiService
      .get<FreebetsConfiguration>(
        APIType.CMS,
        `FreeBets/GetFreeBetsConfig?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`,
        {
          timeout: this.appConfigService.get('sports').freebets.freebetCMSCallsTimeout,
        } as APISettings
      )
      .pipe(
        catchError(err => {
          this._setFreebetConfigs();
          this.freebetsStore.updateConfigCallDone(FreeBetProductType.SportsBook, true);
          return throwError(err);
        }),
        tap(response => {
          this._setFreebetConfigs(response);
          this.freebetsStore.updateConfigCallDone(FreeBetProductType.SportsBook, true);
        })
      );

    return combineLatest([this.accountQuery.isAuthenticated$, this.freebetsQuery.configCallDone$]).pipe(
      // Only perform call when user is logged in and call has not been done before
      filter(([isAuth, _callDone]) => isAuth),
      switchMap(([_isAuth, callDone]) => iif(() => callDone, of(true), call$))
    );
  };

  initialiseFreebetsForBetslip = (): Observable<void> => {
    const observables = [];

    // Should app load with freebets disabled, make sure coupon data has no pre-selected vouchers
    observables.push(
      combineLatest([this.freebetsQuery.allowFreebets$, this.freebetsQuery.configCallDone$]).pipe(
        filter(([isAllowed, contentCallDone]) => !isAllowed && contentCallDone),
        tap(() => {
          this.freebetsStore.updateCouponDataSelectedFreebetVouchers(FreeBetProductType.SportsBook);
        })
      )
    );

    // When changing coupon type, check if the current coupon type accepts freebets, if not remove current selected freebet vouchers
    observables.push(
      combineLatest([this.couponQuery.couponData$, this.freebetsQuery.configCallDone$]).pipe(
        filter(([couponData, contentCallDone]) => !!couponData && contentCallDone),
        distinctUntilChanged((prev, curr) => prev[0].CouponTypeId === curr[0].CouponTypeId),
        map(([couponData, _contentCallDone]) => couponData.CouponTypeId),
        tap((couponType: CouponType) => {
          if (!this.freebetsQuery.couponTypeCheck(couponType)) {
            this.freebetsStore.updateCouponDataSelectedFreebetVouchers(FreeBetProductType.SportsBook);
          }
        })
      )
    );

    // No need to return anything, everything is saved in query
    return forkJoin(observables).pipe(map(() => undefined));
  };

  initialiseFreebetsBetslipContent = (): Observable<void> => {
    const call$ = this.apiService
      .get<FreebetsBetslipContent>(
        APIType.CMS,
        `FreeBets/GetFreeBetsBetslipContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`,
        {
          timeout: this.appConfigService.get('sports').freebets.freebetCMSCallsTimeout,
        } as APISettings
      )
      .pipe(
        tap(response => {
          this.freebetsStore.updateBetslipContent(FreeBetProductType.SportsBook, response);
          this.freebetsStore.updateBetslipContentCallDone(FreeBetProductType.SportsBook, true);
        })
      );

    return combineLatest([
      this.accountQuery.isAuthenticated$,
      this.freebetsQuery.allowFreebets$,
      this.freebetsQuery.betslipContentCallDone$,
    ]).pipe(
      // Only perform call when user is logged in, freebets are allowed, and call has not been done before
      filter(([isAuth, freebetsAllowed, _callDone]) => isAuth && freebetsAllowed),
      switchMap(([_isAuth, _freebetsAllowed, callDone]) => iif(() => callDone, of(true), call$))
    );
  };

  initialiseFreebetsMyBetsContent = (): Observable<void> => {
    const call$ = this.apiService
      .get<FreebetsMyBetsContent>(
        APIType.CMS,
        `FreeBets/GetFreeBetsMyBetsContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`,
        {
          timeout: this.appConfigService.get('sports').freebets.freebetCMSCallsTimeout,
        } as APISettings
      )
      .pipe(
        tap(response => {
          this.freebetsStore.updateMyBetsContent(FreeBetProductType.SportsBook, response);
          this.freebetsStore.updateMyBetsContentCallDone(FreeBetProductType.SportsBook, true);
        })
      );

    return combineLatest([this.accountQuery.isAuthenticated$, this.freebetsQuery.myBetsContentCallDone$]).pipe(
      // Only perform call when user is logged in, and call has not been done before
      filter(([isAuth, _callDone]) => isAuth),
      switchMap(([_isAuth, callDone]) => iif(() => callDone, of(true), call$))
    );
  };

  setActiveFreeBetProduct = (activeProduct: FreeBetProductType): void => {
    this.freebetsStore.setActiveFreeBetProduct(activeProduct);
  };

  getUserVouchers = (): Observable<void> =>
    combineLatest([
      this.accountQuery.isAuthenticated$,
      this.freebetsQuery.allowFreebets$,
      this.freebetsQuery.lastTimeVouchersHaveBeenRetrieved$,
    ]).pipe(
      // Only perform call when user is logged in, freebets are allowed, and stale time has elapsed
      filter(([isAuth, freebetsAllowed, lastRetrieved]) => {
        const timeElapsed = Date.now() - lastRetrieved;
        return (
          isAuth &&
          freebetsAllowed &&
          timeElapsed > this.appConfigService.get('sports').freebets.freebetVoucherRetrievalstaleTimeInSeconds * 1000
        );
      }),
      switchMap(() => {
        const apiSettings: APISettings = new APISettings({
          forceAuthToken: this.accountQuery.accessToken,
          timeout: this.appConfigService.get('sports').freebets.freebetCMSCallsTimeout,
        });

        this.freebetsStore.updateVoucherCallIsLoading(FreeBetProductType.SportsBook, true);

        return this.apiService.get<any>(APIType.Engagement, 'voucher/v1/userrewards/freebeton/sb', apiSettings);
      }),
      map(responseData => {
        if (!responseData || !responseData.data) {
          return of(undefined);
        }
        return responseData.data;
      }),
      tap(vouchers => {
        this.freebetsStore.updateFreebetsVouchers(FreeBetProductType.SportsBook, vouchers);
        this.freebetsStore.updateLastTimeVouchersHaveBeenRetrieved(FreeBetProductType.SportsBook, Date.now());
        this.freebetsStore.updateVoucherCallIsLoading(FreeBetProductType.SportsBook, false);
      }),
      catchError(err => {
        this.freebetsStore.updateVoucherCallIsLoading(FreeBetProductType.SportsBook, false);
        this.freebetsStore.updateCouponDataSelectedFreebetVouchers(FreeBetProductType.SportsBook);
        return throwError(err);
      })
    );

  /**
   * Replace current selected freebet voucher with one represented by given code, or remove the current one
   * @param code Voucher code
   */
  toggleFreebetVoucherUseInCoupon = (code: string): void => {
    if (this.freebetsQuery.isSportsFreebetSelected(code)) {
      this.freebetsStore.updateCouponDataSelectedFreebetVouchers(FreeBetProductType.SportsBook);
    } else {
      this.freebetsStore.updateCouponDataSelectedFreebetVouchers(
        FreeBetProductType.SportsBook,
        this.freebetsQuery.allAvailableFreebetVouchers.find(voucher => voucher.code === code)
      );
    }
  };

  getFreeBets = (): Observable<OldFreebetsVoucher[]> => {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });
    return this.apiService.get(APIType.Website, 'agency/api/voucher/userrewards/freebeton', apiSettings).pipe(
      map(response => {
        return response;
      })
    );
  };

  resetVouchersandGetUserVouchersStaleTime = (): void => {
    this.freebetsStore.updateLastTimeVouchersHaveBeenRetrieved(FreeBetProductType.SportsBook, 0);
    this.freebetsStore.updateFreebetsVouchers(FreeBetProductType.SportsBook, []);
    this.freebetsStore.updateCouponDataSelectedFreebetVouchers(FreeBetProductType.SportsBook);
  };

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  /**
   * For an item to be turned on, it must be turned on both from CMS and from app conf file.
   * If no CMS is provided, the app conf file settings will be used.
   * @param cmsResponse Configs from CMS
   */
  private readonly _setFreebetConfigs = (cmsResponse?: FreebetsConfiguration): void => {
    const appConfigSettings = this.appConfigService.get('sports').freebets;

    this.freebetsStore.updateFreebetsConfiguration(
      FreeBetProductType.SportsBook,
      cmsResponse
        ? {
            allowFreeBets: cmsResponse.allowFreeBets && appConfigSettings.allowFreeBets,
            allowOnMultipleBets: cmsResponse.allowOnMultipleBets && appConfigSettings.allowOnMultipleBets,
            allowOnSingleBets: cmsResponse.allowOnSingleBets && appConfigSettings.allowOnSingleBets,
            allowOnSystemBets: cmsResponse.allowOnSystemBets && appConfigSettings.allowOnSystemBets,
          }
        : {
            allowFreeBets: appConfigSettings.allowFreebets,
            allowOnMultipleBets: appConfigSettings.allowOnMultipleBets,
            allowOnSingleBets: appConfigSettings.allowOnSingleBets,
            allowOnSystemBets: appConfigSettings.allowOnSystemBets,
          }
    );
  };
}
