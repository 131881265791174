import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements AfterViewInit, OnDestroy {
  @Input() preRender: boolean = true;
  @Input() unload: boolean = false;
  @Output() readonly appLazyLoad: EventEmitter<any> = new EventEmitter();

  private intersectionObserver?: IntersectionObserver;
  private scrollSubscription?: Subscription;

  constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.hasCompatibleBrowser()) {
        this.registerIntersectionObserver();
        if (this.intersectionObserver && this.element.nativeElement) {
          this.intersectionObserver.observe(this.element.nativeElement as Element);
        }
      } else {
        this.addScrollListeners();
      }
    } else {
      if (this.preRender) {
        this.load(true);
      }
    }
  }

  hasCompatibleBrowser(): boolean {
    const hasIntersectionObserver = 'IntersectionObserver' in window;
    const userAgent = window.navigator.userAgent;
    const matches = userAgent.match(/Edge\/(\d*)\./i);

    const isEdge = !!matches && matches.length > 1;
    const isEdgeVersion16OrBetter = isEdge && !!matches && parseInt(matches[1], 10) > 15;

    return hasIntersectionObserver && (!isEdge || isEdgeVersion16OrBetter);
  }

  ngOnDestroy(): void {
    this.removeListeners();
  }

  private registerIntersectionObserver(): void {
    if (this.intersectionObserver) {
      return;
    }
    this.intersectionObserver = new IntersectionObserver(entries => {
      this.checkForIntersection(entries);
    });
  }

  private readonly checkForIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.load(true);
        if (this.intersectionObserver && this.element.nativeElement) {
          this.intersectionObserver.unobserve(this.element.nativeElement as Element);
        }
      } else if (this.unload) {
        this.load(false);
      }
    });
  };

  private checkIfIntersecting(entry: IntersectionObserverEntry): boolean {
    if (entry) {
      return (entry as any).isIntersecting;
    }
    return this.isVisible();
  }

  private load(bool: boolean): void {
    this.appLazyLoad.emit(bool);
  }

  private addScrollListeners(): any {
    if (this.isVisible()) {
      this.load(true);
      return;
    } else if (this.unload) {
      this.load(false);
    }
    this.zone.runOutsideAngular(() => {
      this.scrollSubscription = fromEvent(window, 'scroll').pipe(debounceTime(50)).subscribe(this.onScroll);
    });
  }

  private removeListeners(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  private readonly onScroll = () => {
    if (this.isVisible()) {
      this.zone.run(() => {
        this.load(true);
      });
    } else if (this.unload) {
      this.zone.run(() => {
        this.load(false);
      });
    }
  };

  private isVisible(): boolean {
    const scrollPosition = this.getScrollPosition();
    const elementOffset = this.element.nativeElement.offsetTop;
    return elementOffset <= scrollPosition;
  }

  private getScrollPosition(): number {
    // Getting screen size and scroll position for IE
    return (window.scrollY || window.pageYOffset) + (document.documentElement.clientHeight || document.body.clientHeight);
  }
}
