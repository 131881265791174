<div class="offline-container">
  <div class="offline-header"><img src="assets/images/logo/brand-logo-white.svg" alt="Betking Logo"></div>
  <div class="offline-icon"><img src="assets/images/offline-icons/cloud.png" alt="Betking Logo"></div>
  <h3 class="offline-title" i18n>
    Sorry, we've encountered a problem
  </h3>
  <div class="offline-text">
    <p i18n>Unfortunately we're unable to retrieve any data. Please check your internet connection and try refreshing
      the app.</p>
    <p i18n>If the problem persists, please contact customer support.</p>
    <app-button i18n-text text="Refresh" buttonType="normal" [customButtonStyle]="actionButtonStyle"
      [disabled]="buttonDisabled" (click)="reconnect()">
    </app-button>
    <div class="contact-section">
      <p class="contact-section-element"><span i18n>Email: </span><a href="mailto:cs@betking.co.ke" i18n>cs@betking.co.ke</a> </p>
      <p class="contact-section-element"><span i18n>Phone: </span><a href="tel:0 1111 4 3000">0 1111 4 3000</a></p>
      <p class="contact-section-element" i18n>Opening Hours: 24/7</p>
    </div>
  </div>
</div>
