import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AutocompleteOTPInputFieldDirective } from 'src/app/shared/directives/autocomplete-otp-input-field.directive';

@Component({
  selector: 'app-otp-input-field',
  templateUrl: './otp-input-field.component.html',
  styleUrls: ['./otp-input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OTPInputFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AutocompleteOTPInputFieldDirective, { static: true }) verificationCode: AutocompleteOTPInputFieldDirective;
  @Input() tooManyRequests$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() limitExceeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() invalidCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() verifyingCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() otpCodeLength: number;
  @Input() autoComplete: boolean = true;
  @Input() resendTimer: number = 60; // In Seconds
  @Input() showResendButton: boolean = true;
  @Output() readonly verify = new EventEmitter();
  @Output() readonly resend = new EventEmitter();

  verificationForm: FormGroup;
  avoidCallsWithSameCode: string;
  intervalSub: Subscription;
  resendCodeNumOfSeconds: number = 0;
  resendCodeTimer$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  code$ = new BehaviorSubject<string>(''); // For external use
  showResendButton$ = new BehaviorSubject<boolean>(false);

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.resendCodeTimer$.next(this.resendTimer);
    if (!this.limitExceeded$.getValue()) {
      this.calculateCountdown();
    }
    this.verificationForm = this.initialiseForm();
    this.verificationForm.controls.otpField.valueChanges
      .pipe(
        filter(val => !!val),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.populateCodeForExternalUse();
      });
    interval(1000) // reduced the interval from 3s to 1s since there was no documented reason for the long delay
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // Should the hidden OTP field be autocompleted, unfortunately the onchange event is not triggered
        // So the element has to be polled to see if it has value
        const inputValue = this.verificationCode.elementRef.nativeElement.value;
        if (!this.verifyingCode$.getValue() && inputValue && inputValue.length === this.otpCodeLength) {
          this.onFillOTPField(inputValue);
        }
      });

    this.verifyingCode$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      status ? this.verificationForm.controls.otpField.disable() : this.verificationForm.controls.otpField.enable();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.focusOnField();
  }

  resendCode(): void {
    this.resend.emit();
    if (this.showResendButton) {
      this.resendCodeTimer$.next(this.resendTimer);
      if (!this.limitExceeded$.getValue()) {
        this.calculateCountdown();
      }
    }
    this.clearCode();
  }

  otpFieldPlaceholder(): string {
    let result = '';
    for (let i = 0; i < this.otpCodeLength; i++) {
      result += '_';
    }
    return result;
  }

  verifyCodeForAutoComplete(): void {
    if (this.autoComplete) {
      // If OTP completion is handled internally, check validity and previous code before auto-submitting
      if (this.verificationForm.controls.otpField.valid && this.avoidCallsWithSameCode !== this.verificationForm.controls.otpField.value) {
        this.avoidCallsWithSameCode = this.verificationForm.controls.otpField.value;
        // Trigger autocomplete
        this.verify.emit(this.verificationForm.controls.otpField.value);
      } else {
        this.clearCode();
      }
    } else {
      // Code validity will be handled externally
      this.populateCodeForExternalUse();
    }
  }

  populateCodeForExternalUse(): void {
    this.code$.next(this.verificationForm.controls.otpField.value);
  }

  clearCode(): void {
    this.verificationForm.controls.otpField.setValue('');
    this.code$.next('');
    this.focusOnField();
  }

  private onFillOTPField(value: string): void {
    this.verificationForm.controls.otpField.setValue(value);
    this.verifyCodeForAutoComplete();
  }

  private calculateCountdown(): void {
    this.intervalSub = interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resendCodeTimer$.next(this.resendCodeTimer$.value - 1);
        this.showResendButton$.next(this.resendCodeTimer$.value < 60);

        if (this.resendCodeTimer$.value <= 0) {
          this.intervalSub.unsubscribe();
        }
      });
  }

  private initialiseForm(): FormGroup {
    return new FormGroup({
      otpField: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(this.otpCodeLength)]),
    });
  }

  private focusOnField(): void {
    setTimeout(() => {
      this.verificationCode.elementRef.nativeElement.focus();
    });
  }
}
