import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { MetaService } from 'src/app/modules/meta/meta.service';

@Injectable({
  providedIn: 'root',
})
export class MetaGuard implements CanActivate, CanActivateChild {
  constructor(private readonly metaService: MetaService) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    const url = routerStateSnapshot.url;

    const metaSettings = activatedRouteSnapshot.hasOwnProperty('data') ? activatedRouteSnapshot.data.meta : {};

    this.metaService.update(url, metaSettings);

    return true;
  }

  canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
    return this.canActivate(activatedRouteSnapshot, routerStateSnapshot);
  }
}
