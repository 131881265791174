import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';

import { BetCoupon, BetCouponGroup, CouponType } from 'clientside-coupon';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VirtualsCouponSelectionService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-selections.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { CouponGroupingType, GroupingTabsVisibleModel } from 'src/app/shared/models/coupon.model';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { LocalStorageService } from 'ngx-webstorage';
import { VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { InstantCouponService } from 'src/app/core/services/instant-coupon/instant-coupon.service';
import { InstantCouponStore } from 'src/app/core/state/instant-coupon/instant-coupon.store';
import { VirtualsSlideUpComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-slide-up/virtuals-slide-up.component';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { VirtualsAlertType, VirtualsBetSuccessDialogModel, VirtualsSlideUpType } from 'src/app/shared/models/virtuals.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { VirtualsService } from 'src/app/core/services/virtuals.service';

@Component({
  selector: 'app-virtuals-instant-coupon',
  templateUrl: './virtuals-instant-coupon.component.html',
  styleUrls: ['./virtuals-instant-coupon.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsInstantCouponComponent implements OnInit, OnDestroy {
  @Input() slideUpRef?: VirtualsSlideUpComponent;

  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;
  @ViewChildren('couponWrapper') couponWrapperRef: QueryList<ElementRef>;

  currentGroupingsTabSelected: CouponGroupingType;
  overlayRef: OverlayRef;
  overlayTimeout: any;
  animationItem: AnimationItem;
  cmsContent: BehaviorSubject<VirtualsBetSuccessDialogModel> = new BehaviorSubject<VirtualsBetSuccessDialogModel>({
    labels: {
      betslipDialogSuccessTitle: 'Best of luck!',
      betslipDialogSuccessContentTitle: 'Your bet is placed.',
      betslipDialogSuccessContent:
        'You added {kingmakerTicketContribution} King Jackpot and {dukeTicketContribution} Duke Jackpot tickets!',
      betslipDialogSuccessJPSingular: 'You added 1 Duke Jackpot ticket!',
      betslipDialogSuccessCTA: 'CONTINUE BETTING',
    },
    settings: {
      ticketPriceForKingJP: 1000,
      dukeTicketContribution: 1,
    },
  });
  readonly buttonType: typeof ButtonType = ButtonType;
  readonly couponType: typeof CouponType = CouponType;
  readonly groupingType: typeof CouponGroupingType = CouponGroupingType;
  readonly virtualsAlertType: typeof VirtualsAlertType = VirtualsAlertType;
  readonly canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly viewInitialized$ = new BehaviorSubject(false);
  readonly minTotalStakeErrorMessage$ = new BehaviorSubject<string>(undefined);
  readonly maxTotalStakeErrorMessage$ = new BehaviorSubject<string>(undefined);
  readonly virtualsConfig = this.appConfigService.get('virtuals');
  readonly allowCombinationBets = this.virtualsConfig.instantLeague.allowCombinationBets;

  readonly options: AnimationOptions = {
    path: '/assets/json/ondemand-transition.json',
    loop: false,
  };
  readonly showAnimation$ = new BehaviorSubject<boolean>(false);
  readonly groupingTabsVisible: GroupingTabsVisibleModel = {
    multiple: false,
    split: false,
    singles: false,
    combination: false,
  };
  readonly actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '15px',
    height: '44px',
    borderRadius: '50px',
  };

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationService: ApplicationService,
    readonly instantCouponQuery: InstantCouponQuery,
    readonly virtualsQuery: VirtualsQuery,
    private readonly instantCouponService: InstantCouponService,
    private readonly instantCouponStore: InstantCouponStore,
    private readonly notificationService: NotificationService,
    private readonly appConfigService: AppConfigService,
    private readonly couponSelectionService: VirtualsCouponSelectionService,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly virtualsStore: VirtualsStore,
    private readonly dataLayerService: DataLayerService,
    private readonly currencyFormat: CurrencyFormatPipe,
    private readonly virtualsService: VirtualsService
  ) {}

  ngOnInit(): void {
    this.scrollToBottom();
    this.accountQuery.isAuthenticated && this.addEventToDataLayer(BetslipActions.BetslipOpen);
    this.instantCouponQuery.couponData$
      .pipe(
        filter(coupon => !!coupon),
        tap(this.parseSelections),
        tap(this.handleTabs),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.currentGroupingsTabSelected = this.instantCouponQuery.groupingsTabSelected;

    this.instantCouponQuery.groupingsTabSelected$.pipe(takeUntil(this.destroy$)).subscribe(groupingType => {
      if (groupingType !== undefined && groupingType !== this.currentGroupingsTabSelected) {
        this.currentGroupingsTabSelected = groupingType;
        this.updateGroupingsTab(groupingType);
      }
    });

    let prevCouponSelectionIds = 0;
    this.instantCouponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;

        if (
          this.isGroupingButtonVisible(CouponGroupingType.Multiple) &&
          this.instantCouponQuery.groupingsTabSelected === CouponGroupingType.Split
        ) {
          this.instantCouponStore.updateGroupingTab(CouponGroupingType.Multiple);
        } else if (
          this.isGroupingButtonVisible(CouponGroupingType.Split) &&
          this.instantCouponQuery.groupingsTabSelected === CouponGroupingType.Multiple
        ) {
          this.instantCouponStore.updateGroupingTab(CouponGroupingType.Split);
        }
      }
    });

    this.instantCouponQuery.minTotalStake$.pipe(takeUntil(this.destroy$)).subscribe(minTotalStake => {
      const message = $localize`Min. total stake is`;
      const value = this.currencyFormat.transform(minTotalStake, Number.isInteger(minTotalStake) ? '1.0-0' : '1.2-2');
      this.minTotalStakeErrorMessage$.next(`${message} ${value}`);
    });

    this.instantCouponQuery.maxTotalStake$.pipe(takeUntil(this.destroy$)).subscribe(maxTotalStake => {
      const message = $localize`Max. total stake is`;
      const value = this.currencyFormat.transform(maxTotalStake, Number.isInteger(maxTotalStake) ? '1.0-0' : '1.2-2');
      this.maxTotalStakeErrorMessage$.next(`${message} ${value}`);
    });

    setTimeout(() => {
      this.viewInitialized$.next(true);
    });

    this.virtualsService
      .getSuccessBetDialogContentStrapi()
      .pipe(
        map((content: VirtualsBetSuccessDialogModel) => {
          if (content || Object.values(content).some(propertyValue => propertyValue)) {
            this.replaceAndSaveCmsContent(content);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  scrollToBottom(): void {
    const initializationSub = combineLatest([this.viewInitialized$, this.virtualsQuery.showingCouponSlideUp$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([viewInitialized, inSlideUp]) => {
        if (viewInitialized) {
          if (inSlideUp) {
            // need to slide both the coupon-wrapper and the virtuals-slide-up component
            // to cater for both mobile and desktop views
            this.couponWrapperRef?.first?.nativeElement?.scrollTo(0, this.couponWrapperRef.first.nativeElement.scrollHeight);
            this.slideUpRef?.scrollToBottom();
          } else {
            window.scrollTo(0, window.document.body.scrollHeight);
          }
          initializationSub.unsubscribe();
        }
      });
  }

  continueBetting(): void {
    const couponDataCategoryName = this.instantCouponQuery.selections?.[0]?.categories?.[0].name;
    const instantCategories: any = Object.values(this.virtualsConfig?.instantLeague?.categories);
    const selectedLeague = instantCategories.find(category => category.name === couponDataCategoryName);
    const redirectLeagueUrl = selectedLeague ? selectedLeague.url : '';

    this.virtualsQuery.showingCouponSlideUp$
      .pipe(first())
      .subscribe(inSlideUp =>
        inSlideUp
          ? this.virtualsStore.closeSlideUp(VirtualsSlideUpType.Coupon)
          : this.router.navigateByUrl(`/virtual/instant/${redirectLeagueUrl}`)
      );

    this.virtualsStore.updateSuccessBetDialogContent(undefined);
  }

  goBack(): void {
    this.virtualsQuery.showingCouponSlideUp$
      .pipe(first())
      .subscribe(inSlideUp =>
        inSlideUp ? this.virtualsStore.closeSlideUp(VirtualsSlideUpType.Coupon) : this.router.navigate(['/virtual/instant'])
      );
  }

  updateGroupingsTab(groupingType: CouponGroupingType): void {
    const groupUpdates: BetCouponGroup[] = [];

    this.instantCouponQuery.couponData.AllGroupings.forEach(group => {
      if (!group.Selected) {
        return;
      }

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = false;

      groupUpdates.push(newGrouping);
    });

    if (groupingType === CouponGroupingType.Multiple || groupingType === CouponGroupingType.Split) {
      const lastGroup = this.instantCouponQuery.couponData.AllGroupings[this.instantCouponQuery.couponData.AllGroupings.length - 1];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = lastGroup.Grouping;
      newGrouping.Combinations = lastGroup.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Singles) {
      const singlesGrouping = this.instantCouponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = singlesGrouping.Grouping;
      newGrouping.Combinations = singlesGrouping.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Combination) {
      const group = this.instantCouponQuery.couponData.AllGroupings[this.instantCouponQuery.couponData.AllGroupings.length - 2];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    }

    if (groupUpdates.length > 0) {
      this.updateGroupings(groupUpdates);
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): void {
    this.instantCouponService.updateGroupings(groupings);
  }

  isGroupingButtonVisible(groupingButtonType: CouponGroupingType): boolean {
    if (this.instantCouponQuery.couponData) {
      const lastGrouping = this.instantCouponQuery.couponData.AllGroupings[this.instantCouponQuery.couponData.AllGroupings.length - 1];
      const singlesGrouping = this.instantCouponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      if (groupingButtonType === CouponGroupingType.Multiple) {
        if (lastGrouping.Combinations === 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Split) {
        if (lastGrouping !== singlesGrouping && lastGrouping.Combinations > 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Singles) {
        if (singlesGrouping !== null) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Combination) {
        if (this.instantCouponQuery.couponData.AllGroupings.filter(g => g.Grouping !== 1).length > 1) {
          return true;
        }
      }
    }
    return false;
  }

  groupingTabClicked(groupingType: CouponGroupingType): void {
    if (groupingType !== this.instantCouponQuery.groupingsTabSelected) {
      if (groupingType === CouponGroupingType.Combination) {
        this.instantCouponService.enforceSingleCombination = true;
      }
      this.setGroupingTab(groupingType);
    }
  }

  setGroupingTab(groupingType: CouponGroupingType): void {
    if (groupingType !== this.instantCouponQuery.groupingsTabSelected) {
      this.instantCouponStore.updateGroupingTab(groupingType);
    }
  }

  updateStake(stakeValue: number): void {
    this.instantCouponService.updateStakeValue(stakeValue);
    this.instantCouponStore.updateUI({ stakeChanged: true });
  }

  clearCouponData(): void {
    this.instantCouponService.clearCouponData();
  }

  postCoupon(): void {
    if (!this.canPostCoupon$) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      this.canPostCoupon$.next(false);
      this.addEventToDataLayer(BetslipActions.BetSubmitted);

      this.overlayTimeout = setTimeout(() => {
        this.showOverlay();
      }, 1000);

      this.instantCouponService
        .validateAndPostCoupon()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          success => {
            // Success
            this.canPostCoupon$.next(true);
            this.disposeOverlay();
            if (success) {
              this.successSendCouponActions();
              this.instantCouponService.clearCouponData();
            }
          },
          () => {
            // Error
            this.canPostCoupon$.next(true);
            this.showAnimation$.next(false);
            this.virtualsStore.updateSuccessBetDialogContent(undefined);
            this.animationItem?.stop();
            this.localStorage.store('showAnimation', this.showAnimation$.value);
            this.disposeOverlay();
            this.notificationService.showErrorNotification(
              $localize`An error has occurred. Please try again.`,
              $localize`Coupon Not Posted`
            );
          }
        );
    } else {
      this.notificationService.showInfoNotification(
        $localize`If you wish to confirm your bet, please log into the site`,
        $localize`Login Required`
      );
    }
  }

  private successSendCouponActions(): void {
    this.instantCouponStore.updateCouponStatusIsSend(true);
    this.showAnimation$.next(true);
    this.animationItem?.play();
    setTimeout(() => {
      this.continueBetting();
    }, 4000);
  }

  private showOverlay(): void {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay',
      panelClass: 'bet-loading',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const betInProgress = new TemplatePortal(this.betInProgress, this.vcr);
    this.overlayRef.attach(betInProgress);
  }

  private disposeOverlay(): void {
    if (this.overlayTimeout) {
      clearTimeout(this.overlayTimeout);
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private readonly parseSelections = (coupon: BetCoupon): void => {
    setTimeout(() => {
      // This had to be done so that the parsing is done in a separate digest cycle
      // if the coupon is edited within the betslip screen
      this.couponSelectionService.parseSelections(coupon.Odds, VirtualsLeagueType.Instant);
    });
  };

  private readonly handleTabs = (coupon: BetCoupon): void => {
    if (coupon.CouponType === CouponType.Single || coupon.AllGroupings === null) {
      return;
    }

    if (
      this.instantCouponQuery.groupingsTabSelected === undefined ||
      this.currentGroupingsTabSelected !== this.instantCouponQuery.groupingsTabSelected
    ) {
      // determine which combination tab to show
      const lastGrouping = coupon.AllGroupings[coupon.AllGroupings.length - 1];
      const singlesGrouping = coupon.AllGroupings.find(g => g.Grouping === 1);

      if (coupon.Groupings.length === 1 && lastGrouping.Selected) {
        if (lastGrouping.Combinations === 1) {
          this.setGroupingTab(CouponGroupingType.Multiple);
        } else if (lastGrouping === singlesGrouping) {
          this.setGroupingTab(CouponGroupingType.Singles);
        } else {
          this.setGroupingTab(CouponGroupingType.Split);
        }
      } else if (coupon.Groupings.length === 1 && singlesGrouping && singlesGrouping.Selected) {
        this.setGroupingTab(CouponGroupingType.Singles);
      } else {
        this.setGroupingTab(CouponGroupingType.Combination);
      }
    }

    // check group tabs visibility
    this.groupingTabsVisible.multiple = this.isGroupingButtonVisible(CouponGroupingType.Multiple);
    this.groupingTabsVisible.split = this.isGroupingButtonVisible(CouponGroupingType.Split);
    this.groupingTabsVisible.singles = this.isGroupingButtonVisible(CouponGroupingType.Singles);
    this.groupingTabsVisible.combination = this.isGroupingButtonVisible(CouponGroupingType.Combination);
  };

  private addEventToDataLayer(event: BetslipActions): void {
    const numberOfSelections = this.instantCouponQuery.couponData?.Odds?.length ?? 0;

    this.dataLayerService.addBetslipEventToDataLayer({
      event: event,
      userId: this.accountQuery.userData?.id,
      product: DataLayerProduct.VirtualsInstant,
      selections: numberOfSelections,
    });
  }

  private replaceAndSaveCmsContent(content: VirtualsBetSuccessDialogModel): void {
    this.instantCouponQuery.lastCouponStake$.subscribe(stake => {
      const dukeTicketContribution = content.settings.dukeTicketContribution;
      const kingmakerTicketContribution = Math.floor((stake ?? 0) / content.settings.ticketPriceForKingJP);
      const betslipDialogContent =
        dukeTicketContribution && kingmakerTicketContribution < 1
          ? content.labels.betslipDialogSuccessJPSingular.replace('{dukeTicketContribution}', `${dukeTicketContribution}`)
          : content.labels.betslipDialogSuccessContent
              .replace('{kingmakerTicketContribution}', `${kingmakerTicketContribution}`)
              .replace('{dukeTicketContribution}', `${dukeTicketContribution}`);

      this.cmsContent.next({
        ...content,
        labels: {
          ...content.labels,
          betslipDialogSuccessContent: betslipDialogContent,
        },
      });

      this.virtualsStore.updateSuccessBetDialogContent(this.cmsContent.getValue());
    });
  }
}
