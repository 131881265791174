import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { subDays } from 'date-fns';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponDetailsService } from 'src/app/core/services/coupon/coupon-details.service';
import { ParseCouponDetailsService } from 'src/app/core/services/coupon/parse-coupon-details.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { MyBetsCouponStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import {
  BetDetailsModel,
  BetLiveDetailsModel,
  RecentBetModel,
  VirtualsRecentBetRequestModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsProductInterface } from 'src/app/modules/my-bets/services/interfaces/my-bets-product-interface';
import {
  mapCouponStatus,
  mapGetBetDetailsCallResponse,
  mapGetMyBetsCallResponse,
} from 'src/app/modules/my-bets/services/my-bets-api-helpers';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { APIType } from 'src/app/shared/models/api.model';
import { LogTypeModel } from 'src/app/shared/models/log.model';
import { VirtualsLeagueType } from 'src/app/shared/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class VirtualsMyBetsService implements MyBetsProductInterface, OnDestroy {
  private readonly virtualsConfig = this.appConfigService.get('virtuals');
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly languageService: LanguageService,
    private readonly loggerService: LoggerService,
    private readonly myBetsStore: MyBetsStore,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly virtualsService: VirtualsService,
    private readonly virtualsInstantService: VirtualsInstantService,
    private readonly couponDetailsService: CouponDetailsService,
    private readonly parseCouponDetailsService: ParseCouponDetailsService
  ) {
    this.populateVirtualsLeaguesTabs();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  populateVirtualsLeaguesTabs(): void {
    const enabledVirtualsLeagues: VirtualsLeagueType[] = [];
    if (this.virtualsService.isVirtualsEnabled) {
      if (this.virtualsService.isScheduledLeagueEnabled) {
        enabledVirtualsLeagues.push(VirtualsLeagueType.Scheduled);
      }
      if (this.virtualsService.isInstantLeagueEnabled) {
        enabledVirtualsLeagues.push(VirtualsLeagueType.Instant);
      }
    }
    this.myBetsStore.setVirtualsLeagueTabs(enabledVirtualsLeagues);
    this.myBetsStore.setDefaultVirtualsLeagueTab();
  }

  getMyBetsCall(couponStatus: MyBetsCouponStatus): Observable<any> {
    // clear any previously saved data to ensure we don't show bets for the wrong league
    if (couponStatus === MyBetsCouponStatus.Open) {
      this.myBetsStore.clearOpenBets();
    } else if (couponStatus === MyBetsCouponStatus.Settled) {
      this.myBetsStore.clearSettledBets();
    }

    switch (this.myBetsQuery.selectedVirtualsLeagueTab) {
      case VirtualsLeagueType.Instant:
        return this.getInstantMyBets(couponStatus);
      case VirtualsLeagueType.Scheduled:
      default:
        return this.getScheduledMyBets(couponStatus);
    }
  }

  getMyBetsCount(couponStatus: MyBetsCouponStatus) {
    // API not available to retrieve the open bets count in Virtuals, so fetching its list
    this.getMyBetsCall(couponStatus).subscribe(data => {
      const openBets = this.parseGetMyBetsCallResponse(data);
      this.myBetsStore.updateOpenBetsCount(openBets.length);
    });
  }

  parseGetMyBetsCallResponse = (response: any, isOpen: boolean = true): RecentBetModel[] => {
    switch (this.myBetsQuery.selectedVirtualsLeagueTab) {
      case VirtualsLeagueType.Instant:
        return this.mapInstantMyBetsResponse(response, isOpen);
      case VirtualsLeagueType.Scheduled:
      default:
        return mapGetMyBetsCallResponse(response, isOpen);
    }
  };

  getBetDetails(bet: RecentBetModel): Observable<any> {
    switch (this.myBetsQuery.selectedVirtualsLeagueTab) {
      case VirtualsLeagueType.Instant:
        // Not needed for Instant League since the getMyBetsCall returns all the necessary data
        return;
      case VirtualsLeagueType.Scheduled:
      default:
        return this.getScheduledBetDetails(bet.couponCode);
    }
  }

  parseBetDetailsResponse = (response: any): BetDetailsModel => {
    switch (this.myBetsQuery.selectedVirtualsLeagueTab) {
      case VirtualsLeagueType.Instant:
        // Not needed for Instant League since the getMyBetsCall returns all the necessary data
        return;
      case VirtualsLeagueType.Scheduled:
      default:
        return mapGetBetDetailsCallResponse(response);
    }
  };

  getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    this.loggerService.logEvent(
      'Live events for MyBets Virtuals not available',
      'VIRTUALS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
    return null;
  }

  retrieveCashoutStatusesForOpenBets(bets: RecentBetModel[]): void {
    this.loggerService.logEvent('Cashout not available', 'VIRTUALS', SeverityLevel.Warning, LogTypeModel.Application);
  }

  private getScheduledMyBets(couponStatus: MyBetsCouponStatus): Observable<any> {
    return this.apiService
      .post<any>(APIType.VirtualsBetSearch, 'v1/coupons', {
        couponStatus: mapCouponStatus(couponStatus),
        includUsers: true,
        // Get one more to see if there are more than the configured maximum
        pageSize: (this.virtualsConfig.myBets.couponCount as number) + 1,
        lang: this.languageService.selectedLanguage ? this.languageService.selectedLanguage.language : 'en',
      } as VirtualsRecentBetRequestModel)
      .pipe(takeUntil(this.destroy$));
  }

  private getInstantMyBets(couponStatus: MyBetsCouponStatus): Observable<any> {
    const requestParams = {
      startTime: new Date().toISOString(),
      endTime: subDays(new Date(), this.virtualsConfig.myBets.instantLeagueDaysToSearch).toISOString(),
      n: this.virtualsConfig.myBets.couponCount,
      filter: couponStatus === MyBetsCouponStatus.Settled ? 'RESOLVED' : 'OPEN',
    };
    const getMyBets$ = this.virtualsInstantService.getDataFromGR('/tickets/findByTime', requestParams).pipe(takeUntil(this.destroy$));

    return forkJoin([this.virtualsInstantService.getInstantLeagueMarketMapping(), getMyBets$]).pipe(
      map(([, myBets]) => myBets),
      takeUntil(this.destroy$)
    );
  }

  private mapInstantMyBetsResponse(response: any, isOpen: boolean = true): RecentBetModel[] {
    return response?.length
      ? response
          .map(ticket => this.parseCouponDetailsService.parseVirtualsInstantCoupon(ticket))
          .map(betDetails => this.couponDetailsService.mapToRecentBetsModel(betDetails))
      : [];
  }

  private getScheduledBetDetails(couponCode: string): Observable<any> {
    return this.apiService.get<any>(APIType.VirtualsBetSearch, `v1/coupons/${couponCode}`).pipe(takeUntil(this.destroy$));
  }
}
