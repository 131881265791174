import { registerPlugin } from '@capacitor/core';

export interface XtremePushPluginModel {
  addListener(name: string, callback: Function): Promise<{ value: any }>;
  getBackgroundEvents(): Promise<{ value: any }>;
  hitSessionStartEvent(): Promise<{ value: any }>;
  hitEvent(options: object): Promise<{ value: any }>;
  setUserId(options: object): Promise<{ value: any }>;
}

export const XtremePushPlugin = registerPlugin<XtremePushPluginModel>('XPushPlugin');
