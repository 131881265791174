import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { BannerStore } from 'src/app/core/state/banner/banner.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  BannerModel,
  MarketsData,
  Market,
  Module,
  bannerStateHistory,
  BannerVisibility,
  BannerType,
} from 'src/app/shared/models/banner.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BANNER_STATE_HISTORY } from 'src/app/shared/utils/local-storage-keys';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  readonly ODDS_NAMES = {
    1: $localize`Home (1)`,
    X: $localize`Draw (X)`,
    2: $localize`Away (2)`,
    '1 HH': $localize`Home (1 HH)`,
    '2 HH': $localize`Away (2 HH)`,
  };

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly apiService: APIService,
    private readonly bannerStore: BannerStore,
    private readonly applicationService: ApplicationService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly couponService: CouponService,
    private readonly localStorage: LocalStorageService
  ) {}

  setBannerState(state: bannerStateHistory) {
    if (state) {
      this.localStorage.store(BANNER_STATE_HISTORY, JSON.stringify(state));
      this.bannerStore.update({
        bannerStateHistory: state,
      });
    }
  }

  getAllBannerHistoryState() {
    try {
      const state =
        this.bannerStore.getValue().bannerStateHistory ||
        (JSON.parse(this.localStorage.retrieve(BANNER_STATE_HISTORY)) as unknown as bannerStateHistory);
      return state;
    } catch (error) {
      return null;
    }
  }

  getBannerHistoryStateByUserId(userId) {
    try {
      return this.getAllBannerHistoryState()[userId];
    } catch (error) {
      return undefined;
    }
  }

  getBannersData(module: Module): Observable<void> {
    const url: string = 'betking-mobile-banners?sort=rank&populate=*';

    const storeUpdateParameter: string = 'lastSportsBannerUpdate';
    const lastBannerUpdate: number = this.applicationQuery.cms.lastSportsBannerUpdate;

    if (this.applicationService.getEpochTimeDifference(lastBannerUpdate) > this.applicationQuery.cms.cacheTTL) {
      // Since we are doing an update, reset cache timers and current list state
      this.bannerStore.update({ bannerDetails: undefined });
      this.applicationService.resetBannerUpdateCaches();
      const apiSettings: APISettings = new APISettings({
        noAuthToken: true,
      });

      return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
        first(),
        tap(response => {
          const data = this.fromUmbracoToStrapiBannerDataTransformation(response);
          this.bannerStore.update({ bannerDetails: data, allBanners: data });
          const update = {};
          update[storeUpdateParameter] = this.applicationService.getEpochTime();
          this.applicationService.updateCms(update);
        })
      );
    } else {
      return EMPTY;
    }
  }

  fromUmbracoToStrapiBannerDataTransformation(response): BannerModel[] {
    const currentDate = new Date();

    const banners = response
      .filter(banner => {
        if (banner.startDate && banner.endDate) {
          const startDate = new Date(banner.startDate);
          const endDate = new Date(banner.endDate);
          return startDate <= currentDate && currentDate <= endDate;
        } else if (banner.startDate) {
          const startDate = new Date(banner.startDate);
          return startDate <= currentDate;
        } else if (banner.endDate) {
          const endDate = new Date(banner.endDate);
          return currentDate <= endDate;
        }
        return true;
      })
      .map(banner => {
        const {
          bannerBackgroundCss,
          title,
          couponCode,
          eventOnlineCode,
          imageUrl,
          redirectionUrl,
          homeShirt,
          awayShirt,
          display,
          bannerType,
        } = banner;

        return {
          title: title || '',
          bannerBackgroundCss: bannerBackgroundCss || '',
          bannerType: BannerType[bannerType],
          couponCode: couponCode || '',
          display: BannerVisibility[display],
          eventOnlineCode: eventOnlineCode || '',
          ...(imageUrl && { imageUrl }),
          ...(homeShirt && { homeShirtUrl: homeShirt }),
          ...(awayShirt && { awayShirtUrl: awayShirt }),
          redirectionUrl: redirectionUrl || '',
        };
      });
    return banners;
  }

  getBannerOdds(banner: BannerModel, bannerDetails, marketTypes, index): Observable<void> {
    const marketId = marketTypes[banner.bannerType];
    const URL = `api/feeds/prematch/QuickBet/ByMarketTypes/${banner.eventOnlineCode}/${marketId}/en`;
    return this.apiService.get(APIType.SportsbookFeed, URL).pipe(
      first(),
      map(data => {
        const marketsData = new MarketsData({
          categoryName: data.CategoryName,
          eventDate: data.EventDate,
          eventName: data.EventName,
          idCategory: data.IDCategory,
          idEvent: data.IDEvent,
          idEventType: data.IDEventType,
          idSport: data.IDSport,
          idTournament: data.IDTournament,
          markets: [],
          smartBetCode: data.SmartBetCode,
          sportName: data.SportName,
          teamHome: data.EventName.split(' - ')[0],
          teamAway: data.EventName.split(' - ')[1],
          totalOdds: data.TotalOdds,
          tournamentName: data.TournamentName,
        });

        data.Markets.forEach(market => {
          const markets = new Market({
            gamePlay: market.GamePlay,
            markets: [],
            oddsID: market.OddsID,
            specialBetValue: market.SpecialBetValue,
            specialValueDisplay: market.specialValueDisplay,
          });

          market.Markets.forEach(innerMarket => {
            const inner = new OddModel({
              id: innerMarket.MatchOddsID,
              value: innerMarket.OddOutcome,
              unboostedValue: innerMarket.UnboostedOddValue,
              spreadValue: innerMarket.OddAttribute.SpecialValue,
              sportId: data.IDSport,
              sportName: data.SportName,
              categoryId: data.IDCategory,
              categoryName: data.CategoryName,
              eventCategory: 'F', // F = prematch, L = live
              smartCode: data.SmartBetCode,
              tournamentId: data.IDTournament,
              tournamentName: data.TournamentName,
              matchId: data.IDEvent,
              matchName: data.EventName,
              matchDate: data.EventDate,
              marketId: market.OddsID,
              marketTypeId: market.OddsType.OddsTypeID,
              marketName: market.OddsType.OddsTypeName,
              selectionId: innerMarket.OddAttribute.OddTypeID,
              selectionName: this.ODDS_NAMES[innerMarket.OddAttribute.OddName],
              incompatibleEvents: data.IncompatibleEvents,
              combinability: market.GamePlay,
              selected: this.couponService.isOddInCoupon(innerMarket.MatchOddsID),
              enabled: true,
            });
            markets.markets.push(inner);
          });

          marketsData.markets.push(markets);
          banner.marketsData = marketsData;
          bannerDetails[index] = banner;
          this.bannerStore.update({ bannerDetails });
        });
      })
    );
  }
}
