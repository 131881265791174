import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { APISettings, APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class CouponGoalNotificationsService implements OnDestroy {
  private readonly couponCode$ = new BehaviorSubject<string>('');
  private readonly fixtureIds$ = new BehaviorSubject<number[]>([]);
  private readonly subscriptionExpiration$ = new BehaviorSubject<Date>(undefined);
  private readonly userId$ = new BehaviorSubject<number>(undefined);

  private readonly destroy$ = new Subject<boolean>();

  private readonly NOTIFICATION_TYPE = 1; // There is only one right not, ScoreChange = 1

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly apiService: APIService,
    private readonly couponQuery: CouponQuery
  ) {
    this.couponQuery.lastPlacedCoupon$.pipe(takeUntil(this.destroy$)).subscribe(coupon => {
      if (coupon) {
        this.fixtureIds$.next([...new Set(coupon.Odds.map(odd => odd.EventId))]);
        this.couponCode$.next(coupon.couponCode);
        this.subscriptionExpiration$.next(
          coupon.Odds.map(odd => odd.EventDate).reduce((a, b) => {
            return a > b ? a : b;
          })
        );
      } else {
        this.fixtureIds$.next([]);
        this.couponCode$.next('');
        this.subscriptionExpiration$.next(undefined);
      }
    });

    this.accountQuery.userData$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (user) {
        this.userId$.next(user.id);
      } else {
        this.userId$.next(undefined);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  enableGoalNotifications = () =>
    this.apiService
      .post(
        APIType.BFFSportsbook,
        'v1/goal-notifications-subscription',
        {
          couponCode: this.couponCode$.value,
          selectionIds: this.fixtureIds$.value,
          notificationType: this.NOTIFICATION_TYPE,
          userId: this.userId$.value,
          subscriptionExpirationDate: this.subscriptionExpiration$.value,
        },
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(first());

  disableGoalNotifications = () =>
    this.apiService
      .deleteWithData(
        APIType.BFFSportsbook,
        'v1/goal-notifications-subscription',
        {
          couponCode: this.couponCode$.value,
          selectionIds: this.fixtureIds$.value,
          notificationType: this.NOTIFICATION_TYPE,
          userId: this.userId$.value,
        },
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(first());
}
