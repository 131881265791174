<ng-container *ngIf="odd && odd.enabled; else missingOdd">
  <div class="odd" [class.insight-odd]="odd.showSelectionName" [@oddChange]="odd.value">
    <span class="odds-boost-badge" *ngIf="odd?.isBoosted && !betBuilderBoosted"></span>
    <div *ngIf="betBuilderBoosted; else default" class="odd-value">
      <span class="old-odd-value">
        {{ odd.unboostedValue | formatOdd }}
      </span>
      <div class="boosted-badge odds-boost-badge"></div>
      {{ odd.value | formatOdd }}
    </div>
    <ng-template #default>
      <div class="odd-value" [class.boosted]="odd.isBoosted" [class.boosted-insight]="odd.showSelectionName && odd.isBoosted">
          <span [class.grouped]="odd.showSelectionName && !odd.isBoosted">
            <span class="selection-name" *ngIf="odd.showSelectionName">{{odd.selectionName}}</span>
            <span *ngIf="!odd.showSelectionName || (odd.showSelectionName && !odd.isBoosted)">{{ odd.value | formatOdd }}</span>
          </span>
          <span *ngIf="odd.isBoosted">
            <span *ngIf="odd.showSelectionName">{{ odd.value | formatOdd }}</span>
            <span class="old-odd-value">
              <br>{{ odd.unboostedValue | formatOdd }}
            </span>
          </span>
      </div>
    </ng-template>
    <ng-container *ngIf="isLive">
      <div class="animation-helper">
        {{ odd.value | formatOdd }}
      </div>
      <div class="increased"></div>
      <div class="decreased"></div>
    </ng-container>
  </div>
</ng-container>

<ng-template #missingOdd>
  <span *ngIf="!odd || odd.value === undefined" i18n="Missing odd indicator">-</span>
  <i *ngIf="odd && odd.value !== undefined" class="fa fa-lock"></i>
</ng-template>
