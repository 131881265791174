import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';

@Directive({
  selector: '[appMatchScroll]',
})
export class MatchScrollDirective implements AfterViewInit {
  private readonly el: ElementRef;

  constructor(element: ElementRef) {
    this.el = element;
  }

  ngAfterViewInit(): void {
    this.scrollView();
  }

  scrollView(): void {
    setTimeout(() => {
      const selectedElement = Array.from(this.el.nativeElement.children).find(
        (ele: HTMLElement) => ele.getElementsByClassName('selected').length > 0
      ) as HTMLElement;

      if (selectedElement) {
        const scrollValue = selectedElement.getElementsByClassName('selected') as HTMLCollectionOf<HTMLElement>;
        smoothScrollTo(window, 'top', scrollValue[0].offsetTop);
      }
    }, 0);
  }
}
