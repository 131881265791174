<form [formGroup]="bookBetForm" (keydown.enter)="$event.preventDefault()">
  <div class="book-bet-container">
    <div class="container">
      <div class="content">
        <div class="label" i18n>Insert a booking code to add the selections to your bet slip:</div>
        <div class="value">
          <input type="text" formControlName="bookCode" placeholder="e.g. 1XBGHH" />
        </div>
      </div>
    </div>

    <div class="buttons">
      <app-button text="Cancel" [buttonType]="buttonType.Danger" (click)="onCancel()"></app-button>
      <app-button text="Proceed" [buttonType]="buttonType.Success" (click)="onSubmit()"
        [disabled]="bookBetForm.invalid || (disableSend$ | async) ">
      </app-button>
    </div>
  </div>
</form>
