import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { CouponType } from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponSelectionService } from 'src/app/core/services/coupon/coupon-selections.service';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { fadeInOut } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import {
  BookedCouponModel,
  CouponGroupingType,
  CouponOddsModel,
  GroupingTabsVisibleModel,
  DefaultCouponStake,
} from 'src/app/shared/models/coupon.model';
import { brandInfo } from 'src/brand-info';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { CouponGroupingsService } from 'src/app/core/services/coupon/coupon-groupings.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  animations: [fadeInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;

  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly canPlaceBet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly canBookCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showLoadingOverlay$ = new BehaviorSubject<boolean>(false);
  readonly showBetslipSettings$ = new BehaviorSubject<boolean>(false);
  readonly viewInitialized$ = new BehaviorSubject(false);
  readonly showFreebetsOverlay$ = combineLatest([
    this.freebetsQuery.updateVoucherCallIsLoading$,
    this.freebetsQuery.hasSelectedFreebetVoucher$(FreeBetProductType.SportsBook),
  ]).pipe(map(([updateVoucherCallIsLoading, hasVoucher]) => updateVoucherCallIsLoading && hasVoucher));
  readonly hasInvalidFlexicutOdds$ = combineLatest([this.couponQuery.oddsBelowFlexicutMinOdds$, this.couponQuery.isFlexicutCoupon$]).pipe(
    map(([oddsBelow, isFlexicutCoupon]) => isFlexicutCoupon && oddsBelow.length > 0)
  );
  readonly isFlexicutCoupon$ = this.couponQuery.isFlexicutCoupon$;

  readonly hasInsufficientFunds$ = combineLatest([this.accountQuery.userBalance$, this.couponQuery.minBetStake$]).pipe(
    map(([balance, minBetStake]) => balance < minBetStake)
  );
  readonly showDepositButton$ = combineLatest([
    this.hasInsufficientFunds$,
    this.accountQuery.isAuthenticated$,
    this.couponQuery.couponData$,
  ]).pipe(
    map(
      ([insufficient, isAuthenticated, couponData]) =>
        insufficient && isAuthenticated && couponData && !couponData.BetDetails?.FreeBetDetails?.code
    )
  );

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  groupingType: typeof CouponGroupingType = CouponGroupingType;
  currentGroupingsTabSelected: CouponGroupingType;
  bookedCouponData: BookedCouponModel = undefined;
  canBookWhenLoggedIn: boolean = false;
  loadingTimeout: any;
  brandInfo = brandInfo;
  allowCombinationBets = this.appConfigService.get('sports').allowCombinationBets;
  showBookedBetRetailMessage = this.appConfigService.get('sports').coupon.showBookedBetRetailMessage;
  freeBetProductType: typeof FreeBetProductType = FreeBetProductType;
  groupingTabsVisible: GroupingTabsVisibleModel = {
    multiple: false,
    split: false,
    singles: false,
    combination: false,
  };
  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '15px',
    height: '44px',
    borderRadius: '50px',
  };

  actionDisabledButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '15px',
    height: '44px',
    borderRadius: '50px',
    'background-color': 'rgba(0, 16, 65, 0.12)',
  };

  oddChangesInterval = undefined;
  enableSlideUps = this.appConfigService.get('enableSlideUps');

  private oddChangesTimer;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly appConfigService: AppConfigService,
    readonly applicationService: ApplicationService,
    readonly couponQuery: CouponQuery,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly couponEditService: CouponEditService,
    private readonly couponGroupingsService: CouponGroupingsService,
    private readonly couponSelectionService: CouponSelectionService,
    private readonly couponService: CouponService,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly couponStore: CouponStore,
    private readonly freebetsQuery: FreebetsQuery,
    private readonly freebetsService: SportsbookFreeBetService,
    private readonly location: Location,
    private readonly notificationService: NotificationService,
    private readonly couponFlexicutService: CouponFlexicutService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
    this.canBookWhenLoggedIn = this.appConfigService.get('sports').coupon.canBookWhenLoggedIn;

    this.accountQuery.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      this.canPlaceBet$.next(response);
    });
  }

  ngOnInit(): void {
    // Coupon Init
    this.bookedCouponData = undefined;
    this.couponService.sendOpenBetslipEvent();

    if (this.couponQuery.expiredEvents && this.couponQuery.expiredEvents.expiredEvents.length > 0) {
      this.couponService.showExpiredNotification();

      this.couponService.clearExpiredEvents();
    }

    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(couponData => {
      if (couponData) {
        setTimeout(() => {
          // This had to be done so that the parsing is done in a separate digest cycle
          // if the coupon is edited within the betslip screen
          this.couponSelectionService.parseSelections(couponData.Odds);
          if (this.couponQuery.isFlexicutApplicable) {
            // After parsing selections, get flexicut calculation
            this.couponFlexicutService.updateFlexicutOdds();
          }
        });

        if (couponData.CouponType === CouponType.Single || couponData.AllGroupings === null) {
          return;
        }

        if (
          this.couponQuery.groupingsTabSelected === undefined ||
          this.currentGroupingsTabSelected !== this.couponQuery.groupingsTabSelected
        ) {
          // determine which combination tab to show
          const lastGrouping = couponData.AllGroupings[couponData.AllGroupings.length - 1];
          const singlesGrouping = couponData.AllGroupings.find(g => g.Grouping === 1);

          if (couponData.Groupings.length === 1 && lastGrouping.Selected) {
            if (lastGrouping.Combinations === 1) {
              this.couponGroupingsService.setGroupingTab(CouponGroupingType.Multiple);
            } else if (lastGrouping === singlesGrouping) {
              this.couponGroupingsService.setGroupingTab(CouponGroupingType.Singles);
            } else {
              this.couponGroupingsService.setGroupingTab(CouponGroupingType.Split);
            }
          } else if (couponData.Groupings.length === 1 && singlesGrouping && singlesGrouping.Selected) {
            this.couponGroupingsService.setGroupingTab(CouponGroupingType.Singles);
          } else {
            this.couponGroupingsService.setGroupingTab(CouponGroupingType.Combination);
          }
        }

        // check group tabs visability
        this.groupingTabsVisible.multiple = this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Multiple);
        this.groupingTabsVisible.split = this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Split);
        this.groupingTabsVisible.singles = this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Singles);
        this.groupingTabsVisible.combination = this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Combination);
      }
    });

    this.currentGroupingsTabSelected = this.couponQuery.groupingsTabSelected;

    this.couponQuery.groupingsTabSelected$
      .pipe(
        filter(() => !!this.couponQuery.couponData),
        takeUntil(this.destroy$)
      )
      .subscribe(groupingType => {
        if (groupingType !== undefined && groupingType !== this.currentGroupingsTabSelected) {
          this.currentGroupingsTabSelected = groupingType;
          this.couponGroupingsService.updateGroupingsTab(groupingType);
        }
      });

    // If acca bonus list is initialised
    this.accumulatorBonusQuery.isSportsBonusListInit$
      .pipe(
        filter(isInit => !!isInit),
        switchMap(() => this.couponQuery.couponData$),
        distinctUntilChanged((prev, curr) => prev?.Odds.length === curr?.Odds.length),
        takeUntil(this.destroy$)
      )
      // Start odds sync
      .subscribe(couponData => (couponData?.Odds.length ? this.runCouponOddChanges() : this.stopCouponOddChanges()));

    let prevCouponSelectionIds = 0;
    this.couponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;

        if (
          this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Multiple) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Split
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Multiple);
        } else if (
          this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Split) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Multiple
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Split);
        }
      }
    });

    // Freebets Initialisation
    forkJoin([
      this.freebetsService.initialiseFreebetsConfig(),
      this.freebetsService.initialiseFreebetsForBetslip(),
      this.freebetsService.initialiseFreebetsBetslipContent(),
      this.freebetsService.getUserVouchers(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    setTimeout(() => {
      this.viewInitialized$.next(true);
    });
  }

  ngAfterViewInit() {
    this.scrollToBottomAfterInitialization();
  }

  continueBetting(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }

    this.router.navigate(['/']);
  }

  close(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }
  }

  navigateBack(): void {
    this.router.navigateByUrl(this.couponQuery.previousPagePath);
  }

  runCouponOddChanges(): void {
    this.stopCouponOddChanges();

    const couponOdds = this.getCouponOdds();

    if (couponOdds !== undefined) {
      const containsLiveEvents = couponOdds.some(odd => odd.isLive);

      if (containsLiveEvents) {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.liveOddChangesTimer;
      } else {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
      }

      this.couponService.getOddsChanged(couponOdds).subscribe(() => {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      });
    }

    this.oddChangesInterval = window.setInterval(() => {
      this.runCouponOddChanges();
    }, this.oddChangesTimer);
  }

  acceptOddChanges(): void {
    this.couponService.acceptOddChanges();
    this.hasOddChanges$.next(false);
  }

  clearCouponData(): void {
    this.couponService.clearCouponData();
  }

  showSettingsModal(): void {
    this.showBetslipSettings$.next(!this.showBetslipSettings$.getValue());
  }

  clearExpiredEvents(): void {
    this.couponStore.updateFlexicutSelectedOption(undefined);
    this.couponService.clearExpiredEvents();
  }

  postCoupon(): void {
    if (!this.couponStakeHandlerService.checkCouponStake()) {
      return;
    }

    if (!this.canPostCoupon$) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      this.canPostCoupon$.next(false);

      this.loadingTimeout = setTimeout(() => {
        this.showLoading();
      }, 1000);

      this.couponService
        .validateAndPostCoupon()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          success => {
            this.canPostCoupon$.next(true);
            this.disposeLoading();
            if (success) {
              this.router.navigate(['coupon/bet-receipt']);
            }
          },
          error => {
            this.canPostCoupon$.next(true);
            this.disposeLoading();
            this.notificationService.showErrorNotification(
              $localize`An error has occurred. Please try again.`,
              $localize`Coupon Not Posted`
            );
          }
        );
    } else {
      this.applicationService.setShowLoginDialog(true);
    }
  }

  bookCoupon(): void {
    if (!this.canBookCoupon$) {
      return;
    }

    this.canBookCoupon$.next(false);
    this.couponService
      .validateAndPostBookCoupon()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.canBookCoupon$.next(true);
          if (response) {
            this.bookedCouponData = new BookedCouponModel({
              couponData: cloneDeep(this.couponQuery.couponData),
              bookedCouponCode: response.couponCode,
              bookedCouponDate: response.date,
            });

            this.couponSelectionService.parseSelections(this.bookedCouponData.couponData.Odds);
          }
        },
        error => {
          this.canBookCoupon$.next(true);
          this.notificationService.showErrorNotification($localize`An error has occurred. Please try again.`, $localize`Coupon Not Booked`);
        }
      );
  }

  groupingTabClicked(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      if (groupingType === CouponGroupingType.Combination) {
        this.couponService.enforceSingleCombination = true;
      }
      this.couponService.clearAllBankers();
      this.couponGroupingsService.setGroupingTab(groupingType);
    }
  }

  updateAllowOddsToChange = () => {
    const value = !this.couponQuery.couponSettings.allowOddChanges;
    this.couponStore.updateCouponSettings({
      ...this.couponQuery.couponSettings,
      lastAllowOddChanges: value,
      allowOddChanges: value,
    });
  };

  updateAllowCompetitionGrouping = () => {
    this.couponStore.updateCouponSettings({
      ...this.couponQuery.couponSettings,
      allowCompetitionGrouping: !this.couponQuery.couponSettings.allowCompetitionGrouping,
    });
  };

  updateAllowSaveDefault = () => {
    const allowSaveDefault = !this.couponQuery.defaultCouponStake?.allowSaveDefault;
    const defaultCouponStake = new DefaultCouponStake({
      allowSaveDefault,
      defaultStake: allowSaveDefault ? this.couponQuery.couponData.StakeGross : this.couponQuery.globalVariables.MinBetStake,
    });

    this.couponStakeHandlerService.updateDefaultCouponStake(defaultCouponStake);
  };

  toggleBetslipSettingsModal(): void {
    this.showBetslipSettings$.next(!this.showBetslipSettings$.getValue());
  }

  handleCloseModal(): void {
    this.showBetslipSettings$.next(!this.showBetslipSettings$.getValue());
  }

  ngOnDestroy(): void {
    this.couponEditService.clearEditData();
    this.stopCouponOddChanges();
    this.disposeLoading();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goToDepositPage(): void {
    window.location.href = '/account/payments/deposit';
  }

  private showLoading(): void {
    this.showLoadingOverlay$.next(true);
  }

  private disposeLoading(): void {
    if (this.loadingTimeout) {
      this.showLoadingOverlay$.next(true);
      clearTimeout(this.loadingTimeout);
      this.showLoadingOverlay$.next(false);
    }
  }

  private scrollToBottomAfterInitialization(): void {
    const initializationSub = this.viewInitialized$.subscribe(initialized => {
      if (initialized) {
        const { betslipScrollTop } = this.couponQuery;
        const scrollHeight = betslipScrollTop ? betslipScrollTop : window.document.body.scrollHeight;
        setTimeout(() => {
          window.scrollTo(0, scrollHeight);
        }, 300);
        initializationSub.unsubscribe();
      }
    });
  }

  private stopCouponOddChanges(): void {
    if (this.oddChangesInterval) {
      clearInterval(this.oddChangesInterval);
    }
  }

  private getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        marketTypeId: odd.MarketTypeId,
        selectionId: odd.SelectionId,
        selectionValue: odd.OddValue,
      });
    });
    return couponOdds;
  }
}
