import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatOdd' })
export class FormatOddPipe implements PipeTransform {
  transform(value: any): string {
    try {
      const num = this.strToNumber(value);
      const withDecimals = num.toFixed(this.getDecimalCount(num));

      return withDecimals.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
    } catch (e) {
      return '-';
    }
  }

  private strToNumber(value: number | string): number {
    // Convert strings to numbers
    if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
      return Number(value);
    }
    if (typeof value !== 'number') {
      throw new Error(`${value} is not a number`);
    }
    return value;
  }

  private getDecimalCount(num: number): number {
    if (num > 999) {
      return 0;
    } else if (num > 99) {
      return num % 1 === 0 ? 0 : 2;
    }

    return 2;
  }
}
