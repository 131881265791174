<div class="header">
  <div class="title">
    <span>{{headerTitle}}</span>
    <span class='title-badge' *ngIf="headerBadge">{{headerBadge}}</span>
  </div>
  <span class="close-icon material-icons" (click)="closeSlideUps()">
    {{ 'close' }}
  </span>
</div>

<div #content class="content">
  <ng-content></ng-content>
</div>