<app-acca-bonus-unacceptable-selections-prompt (closePrompt)="closeOddsValueInfoPopup()"
  *ngIf="applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async">
</app-acca-bonus-unacceptable-selections-prompt>
<div class="nav-bar" [class.rounded-corners]="applicationQuery.isShowingNavbarBetslipSelections$ | async"
  [ngClass]="{ 'logged-in': accountQuery.isAuthenticated }">
  <div class="nav-bar-popups-container">
    <app-betslip-selections-bar
      *ngIf="(applicationQuery.isShowingNavbarBetslipSelections$ | async) && (applicationQuery.isSlideUpVisible$ | async) === false">
    </app-betslip-selections-bar>
    <app-acca-bonus-progression-bar
      *ngIf="(accaBonusProgressBarElementVisibility$ | async) && (applicationQuery.isSlideUpVisible$ | async) === false"
      (closeOddsValueInfoPopup)="closeOddsValueInfoPopup()">
    </app-acca-bonus-progression-bar>
  </div>
  <app-nav-bar-items></app-nav-bar-items>
</div>