<app-breadcrumb i18n-title title="Coupon Check" icon="coupon-check"></app-breadcrumb>
<div class="coupon-check-container">
  <div class="title" i18n>
    Coupon Check
  </div>
  <div class="content">
    <input [formControl]="couponCode" type="text" placeholder="Input your coupon code">
    <div class="check-button" [class.disabled]="couponCode.invalid" (click)="checkCoupon()" i18n>CHECK
    </div>
  </div>
</div>
