import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VerifyAccountType } from 'src/app/shared/models/account.model';
import { VERIFY_ACCOUNT_DATA_KEY } from 'src/app/shared/utils/local-storage-keys';

@Component({
  selector: 'app-phone-unverified-tooltip',
  templateUrl: './phone-unverified-tooltip.component.html',
  styleUrls: ['./phone-unverified-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneUnverifiedTooltipComponent implements OnInit {
  verifyData: any;

  verifyAccountType: typeof VerifyAccountType = VerifyAccountType;
  daysLeft: number;
  hoursLeft: number;

  constructor(
    readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.verifyData = this.localStorage.retrieve(VERIFY_ACCOUNT_DATA_KEY);
  }

  verifyExistingPhoneNumber(): void {
    this.registrationService
      .checkPhoneNumber(undefined, this.verifyData.mobileNumber)
      .pipe(first())
      .subscribe(
        () => {
          // Phone Number not exist
        },
        err => {
          if (err.error.ResponseCode === 20214) {
            // Mobile number already exists on multiple accounts
            this.accountService.updateMultipleUnconfirmedUsers(true);
          } else if (err.error.ResponseCode === 20216) {
            // UnConfirmed Mobile Number Already Exists
            this.accountService.updateMultipleUnconfirmedUsers(false);
          }

          this.navigateTo('/account/phone-number-verification');
        }
      );
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }
}
