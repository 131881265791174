export enum ProductType {
  SportsBook = 'Sports',
  Virtuals = 'Virtuals',
  JackpotBets = 'Pick 11',
}

export enum VirtualsLeagueType {
  Scheduled = 'Scheduled',
  Instant = 'Instant',
}

export enum VirtualsCategoryType {
  FootballLeagues = 'Football Leagues',
  Games = 'Games',
  Racing = 'Racing',
}

export enum VirtualsProductType {
  Tournament = 2,
}
