import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RecentSearchesService } from 'src/app/core/services/recent-searches.service';
import { SearchService } from 'src/app/core/services/search.service';
import { SearchQuery } from 'src/app/core/state/search/search.query';
import { fadeIn } from 'src/app/shared/animations';
import { RecentSearchTheme } from 'src/app/shared/models/search.model';

@Component({
  selector: 'app-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class RecentSearchesComponent {
  @Input() theme: RecentSearchTheme;

  constructor(
    private readonly recentSearchesService: RecentSearchesService,
    private readonly searchService: SearchService,
    public searchQuery: SearchQuery,
    private readonly router: Router
  ) {}

  clearRecentSearches(): void {
    this.recentSearchesService.clearRecentSearches();
  }

  recentSearchTrackBy(index: number, item: string): string {
    return item;
  }

  onBadgeClick(keyword: string): void {
    this.searchService.search(keyword).subscribe();
    this.searchService.badgeClicked(keyword);
    if (this.router.url === '/') {
      this.router.navigateByUrl('search');
    }
  }
}
