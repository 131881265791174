import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDynamicSvgBackground]',
})
export class DynamicSvgBackgroundDirective implements AfterViewChecked {
  @Input() appDynamicSvgBackground: string;

  constructor(public elementRef: ElementRef, readonly renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    const normalizedFileName = this.appDynamicSvgBackground.toLowerCase();
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundImage', `url('./assets/${normalizedFileName}.svg')`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundSize', '100%');
  }
}
