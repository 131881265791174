import { BetCoupon, BetCouponGlobalVariable, Dictionary } from 'clientside-coupon';
import { BetCouponGroup, BetCouponGroupMissing, BetCouponOdd, EvaluationMotivation } from 'clientside-coupon/lib/clientside-coupon.model';
import { BookedCoupon, CouponGroupingType, DefaultCouponStake } from './coupon.model';
import { SportModel } from './sport.model';
import { VirtualsCouponSettings, VirtualsCouponUIState } from './virtuals-coupon.model';

export interface InstantCouponState {
  bookedCoupons: BookedCoupon[];
  couponData: BetCoupon;
  selections: SportModel[];
  couponInitialized: boolean;
  isCouponSend: boolean;
  couponSettings: VirtualsCouponSettings;
  defaultCouponStake: DefaultCouponStake;
  correctScoreOddsMatrix: any;
  globalVariables: BetCouponGlobalVariable;
  groupingsTabSelected: CouponGroupingType;
  marketExceptions: Dictionary<number, number[]>;
  ui: VirtualsCouponUIState;
  leagueSwitchContent: any;
  lastDataCouponStake: number;
}

export class InstantPostCoupon {
  tagsId: number;
  timeSend: string;
  sellStaff: SellStaff;
  calculationId: number;
  walletId: number;
  details: CouponDetails;

  constructor(init: Partial<InstantPostCoupon>) {
    Object.assign(this, init);
  }
}

export interface CouponDetails {
  events: InstantEvents[];
  systemBets: SystemBets[];
  jackpotDetails: [];
  additionalDetails: [];
}

export interface SystemBets {
  grouping: number;
  systemCount: number;
  stake: number;
  winningData: WinningData;
}

export interface InstantEvents {
  finalOutcome: [];
  bets: [];
  eventId: number;
  extId: number;
  extData: string;
  playlistId: number;
  description: string;
  gameType: {
    val: string;
  };
  eventTime: string;
  data: EventData;
  isBanker: boolean;
}

export interface EventBets {
  marketId: string;
  oddIdd: string;
  oddValue: string;
  minOdd: string;
  maxOdd: string;
  solvedOdd: string;
  numCombis: string;
  wonCombis: string;
  stake: number;
  cancelledStake: number;
  wonAmount: number;
  targetRTP: string;
  targetBalance: string;
  betParam: string;
  status: string;
  profitType: string;
  timeSolved: string;
}

export interface EventData {
  classType: string;
  participants: EventParticipants[];
  competitionType: string;
  data: EventDataData;
  eventNdx: number;
  competitionSubType: string;
}

export interface InstantEvent {
  data: {
    participants: [];
    oddValues: [];
    stats: [];
  };
  eventData: EventDataData;
  eventNdx: number;
  playlistId: number;
  description: string;
  gameType: {
    val: string;
  };
  eBlockId: number;
}

export interface EventParticipants {
  classType: string;
  id: string;
  name: string;
  shortDesc: string;
  longDesc: string;
  fifaCode: string;
  stars: number;
}

export interface EventDataData {
  classType: string;
  champId: number;
  weekDay: number;
  legOrder: number;
  phase: string;
  matchDay: number;
  eBlockId: number;
}

export interface WinningData {
  limitMaxPayout: number;
  minWinning: number;
  maxWinning: number;
  minBonus: number;
  maxBonus: number;
  winningCount: number;
}

export interface SellStaff {
  entityType: [];
  id: number;
  name: string;
  extId: string;
  extData: string;
  status: string;
}

export interface InstantTicket {
  details: CouponDetails;
  winningData: WinningData;
  ticketId: number;
}

export interface RoundSelection {
  matchId: number;
  marketId: number;
  marketName: string;
  selectionId: number;
  selectionName: string;
  oddValue: number;
  oddsBoost: boolean;
}

export declare enum CouponType {
  Unknown = 0,
  Single = 1,
  Multiple = 2,
  System = 3,
  Flexicut = 4,
}

export declare class InstantCoupon {
  // UserId: number;
  // Odds: Array<InstantBetCouponOdd>;
  IsClientSideCoupon: boolean;
  UserId: number;
  CouponTypeId: CouponType;
  MinWin: number;
  MinWinNet: number;
  NetStakeMinWin: number;
  MaxWin: number;
  MaxWinNet: number;
  NetStakeMaxWin: number;
  MinBonus: number;
  MaxBonus: number;
  MinPercentageBonus: number;
  MaxPercentageBonus: number;
  MinOdd: number;
  MaxOdd: number;
  TotalOdds: number;
  Stake: number;
  UseGroupsStake: boolean;
  StakeGross: number;
  StakeTaxed: number;
  TaxPercentage: number;
  Tax: number;
  MinWithholdingTax: number;
  MaxWithholdingTax: number;
  TurnoverTax: number;
  TotalCombinations: number;
  Odds: Array<BetCouponOdd>;
  Groupings: Array<BetCouponGroup>;
  PossibleMissingGroupings: Array<BetCouponGroupMissing>;
  CurrencyId: number;
  ChannelId: number;
  IsLive: boolean;
  IsVirtual: boolean;
  MatchsMarketsCompatibilities: string;
  CurrentEvalMotivation: EvaluationMotivation;
  BetCouponGlobalVariable: BetCouponGlobalVariable;
  LiveDelay: number;
  Language: string;
  HasLive: boolean;
  CouponType: CouponType;
  AllGroupings: Array<BetCouponGroup>;
  TransactionId: string;
  constructor(globalVariables?: BetCouponGlobalVariable);
  MaxGrouping(): number;
  setAllowFixed(): void;
  setTotalOdds(): void;
  getTax(): number;
  setTax(): void;
  getStakeTaxed(): number;
  setStakeTaxed(): void;
  getStake(): number;
  setStake(): void;
  calculateAndSetTaxes(coupon: BetCoupon, brandID: number, isRetailInstallation: boolean): void;
}

export declare class InstantBetCouponOdd {
  categoryId: number;
  categoryName: string;
  combinability: string;
  eventCategory: string;
  marketId: string;
  marketName: string;
  marketTag: number;
  marketTypeId: string;
  matchDate: Date;
  matchId: number;
  matchName: string;
  oddId: number;
  oddValue: number;
  orderId: number;
  selectionName: string;
  selectionTypeId: number;
  smartBetCode: string;
  sportId: number;
  sportName: string;
  spreadValue: number;
  tournamentId: number;
  tournamentName: string;
  unboostedOddValue: number;
}
