import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { expandCollapseSimpler, fadeInBy, fadeInByStaggered } from 'src/app/shared/animations';
import { MenuItemModel, MenuTab } from 'src/app/shared/models/menu.model';

@Component({
  selector: 'app-menu-quicklinks',
  templateUrl: './menu-quicklinks.component.html',
  styleUrls: ['./menu-quicklinks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInByStaggered(), fadeInBy(), expandCollapseSimpler()],
})
export class MenuQuicklinksComponent implements OnInit {
  searchFieldQuicklinks = new FormControl('');
  menuTab = MenuTab;
  expandLanguageSelector$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly menuQuery: MenuQuery,
    readonly applicationService: ApplicationService,
    readonly applicationQuery: ApplicationQuery,
    readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    const timeSinceLastCall = this.applicationService.getEpochTimeDifference(this.applicationQuery.cms.lastSidebarQuicklinksUpdate);
    if (timeSinceLastCall > this.applicationQuery.cms.cacheTTL) {
      this.applicationService.getSidebarLinks();
    }
  }

  quickLinksTrackBy(index: number, quicklink: MenuItemModel): string {
    return quicklink.linkURL;
  }

  handleClick(isLocked: boolean, newTab: boolean, linkURL: string, functionName: string): void {
    if (!isLocked) {
      if (newTab) {
        this.openNewTab(linkURL);
      } else {
        if (functionName) {
          this.handleFunction(functionName);
        } else {
          this.applicationService.navigateTo(linkURL);
        }
      }
    }
  }

  handleFunction(functionName: string): void {
    this.applicationService.menuMethodCalls[functionName]();
  }

  openNewTab(url: string): void {
    window.open(url);
  }

  clickLanguageSelector(): void {
    this.expandLanguageSelector$.next(!this.expandLanguageSelector$.value);
  }
}
