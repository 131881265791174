import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GeoAccessControlStore } from 'src/app/core/state/geo-access-control/geo-access-control.store';

import { CountrySelectionDetails, GeoAccessControlState, GeoAccessModel } from 'src/app/shared/models/geo-access-control.model';

@Injectable({
  providedIn: 'root',
})
export class GeoAccessControlQuery extends Query<GeoAccessControlState> {
  accessList$ = this.select(state => state.accessList);
  userCountryCode$ = this.select(state => state.userCountryCode);
  userCountryName$ = this.select(state => state.userCountryName);
  countrySelectionDetails$ = this.select(state => state.countrySelectionDetails);

  constructor(protected store: GeoAccessControlStore) {
    super(store);
  }

  get accessList(): GeoAccessModel[] {
    return this.getValue().accessList;
  }

  get userCountryCode(): string {
    return this.getValue().userCountryCode;
  }

  get userCountryName(): string {
    return this.getValue().userCountryName;
  }

  get countrySelectionDetails(): CountrySelectionDetails {
    return this.getValue().countrySelectionDetails;
  }
}
