import { OddModel } from 'src/app/shared/models/coupon.model';

export type bannerState = 'Authorised' | 'Deposited' | 'PlacedBet' | undefined;
export type bannerStateHistory = Record<
  string,
  {
    bannerState: bannerState;
    isLastUser: boolean;
  }
> | null;
export class BannerState {
  bannerDetails: BannerModel[];
  allBanners: BannerModel[];
  bannerStateHistory: bannerStateHistory;
  constructor(init: Partial<BannerState>) {
    Object.assign(this, init);
  }
}

export class BannerModel {
  title: string;
  imageUrl: string;
  redirectionUrl: string;
  display: BannerVisibility;
  couponCode: string;
  bannerType: BannerType;
  eventOnlineCode: string;
  marketsData: MarketsData;
  homeShirtUrl: string;
  awayShirtUrl: string;
  bannerBackgroundCss: string;

  constructor(init: Partial<BannerModel>) {
    Object.assign(this, init);
  }
}

export class MarketsData {
  idEvent: number;
  eventName: string;
  teamHome: string;
  teamAway: string;
  eventDate: string;
  idEventType: number;
  idSport: number;
  sportName: string;
  idTournament: number;
  tournamentName: string;
  idCategory: number;
  categoryName: string;
  totalOdds: number;
  markets: Market[];
  smartBetCode: number;

  constructor(init: Partial<MarketsData>) {
    Object.assign(this, init);
  }
}

export class Market {
  oddsID: number;
  specialBetValue: number;
  specialValueDisplay: string;
  markets: OddModel[];
  gamePlay: number;

  constructor(init: Partial<Market>) {
    Object.assign(this, init);
  }
}

export enum BannerVisibility {
  Always,
  NewUser,
  Authorised,
  Deposited,
  PlacedBet,
}

export enum BannerType {
  Default,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OneX2,
  H2H,
}

export enum Module {
  Sports = 'sports',
  Payments = 'payments',
}
