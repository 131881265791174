import { Component, Input } from '@angular/core';
import { EventBetInsightModel } from 'src/app/modules/sport/models/event-view/prematch.model';
import { MatchService } from 'src/app/modules/sport/services/match.service';

@Component({
  selector: 'app-bet-insight-card',
  templateUrl: './bet-insight-card.component.html',
  styleUrls: ['./bet-insight-card.component.scss'],
})
export class BetInsightCardComponent {
  @Input() betInsight: EventBetInsightModel;
  @Input() showInfo: boolean = true;
  @Input() eventLocation: string = undefined;

  constructor(private readonly matchService: MatchService) {}

  navigateToMatch(matchId: number, name: string): void {
    if (!matchId) return;
    this.matchService.navigateToMatch(matchId, name);
  }
}
