<ng-container *ngIf="odd && odd.enabled; else disabled">
  <div class="odd live-odd" (click)="selectOdd()" [class.selected]="selected$ | async" [@oddChange]="odd.value">
    <div class="odd-value">
      {{ odd.value | number:'1.2-2' }}
    </div>
    <div class="animation-helper">
      {{ odd.value | number:'1.2-2' }}
    </div>
    <div class="increased"></div>
    <div class="decreased"></div>
  </div>
</ng-container>
<ng-template #disabled>
  <div class="odd live-odd">
    <ng-container *ngIf="!odd || odd.value === undefined" i18n="Missing odd indicator">-</ng-container>
    <i *ngIf="odd && odd.value !== undefined" class="fa fa-lock"></i>
  </div>
</ng-template>
