import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MenuStore } from 'src/app/core/state/menu/menu.store';
import { MenuState } from 'src/app/shared/models/menu.model';

@Injectable({ providedIn: 'root' })
export class MenuQuery extends Query<MenuState> {
  selectedTab$ = this.select(state => state.selectedTab);
  quickLinks$ = this.select(state => state.quickLinks);

  constructor(protected store: MenuStore) {
    super(store);
  }
}
