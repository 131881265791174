import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mobver-already-verified',
  templateUrl: './already-verified.component.html',
  styleUrls: ['./already-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileVerificationCampaignAlreadyVerifiedComponent {
  @Input() content = {
    header: '',
    ctaText: 'Proceed',
    ctaURL: '/',
  };

  ctaAdditionalStyles = {
    fontSize: '14px',
    minWidth: '110px',
    height: '40px',
  };

  constructor(private readonly router: Router) {}

  proceedClicked(): void {
    this.router.navigateByUrl(this.content ? this.content.ctaURL : '/');
  }
}
