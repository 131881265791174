import { Injectable } from '@angular/core';

import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { SportModel } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class FavouriteSportsService {
  constructor(private readonly store: SportStore, private readonly query: SportQuery) {}

  removeFromFavourites(sport: SportModel): void {
    const ids = this.query.favouriteSportIds;

    const newIds = ids.filter(id => id !== sport.id);

    this.store.updateFavourites(newIds);
  }

  addToFavourites(sport: SportModel): void {
    const ids = this.query.favouriteSportIds;

    if (ids.indexOf(sport.id) > -1) {
      return;
    }

    this.store.updateFavourites([...ids, sport.id]);
  }
}
