export function smoothScrollTo(element: any, direction: 'top' | 'left', scrollValue: number): void {
  if ('scrollBehavior' in document.documentElement.style) {
    if (direction === 'top') {
      element.scrollTo({ top: scrollValue, behavior: 'smooth' });
    } else {
      element.scrollTo({ left: scrollValue, behavior: 'smooth' });
    }
  } else {
    scrollTo(element, direction, scrollValue);
  }
}

function scrollTo(element: any, direction: 'top' | 'left', scrollValue: number, duration: number = 400): void {
  let startTime: number;
  const initialScroll: number =
    direction === 'top' ? (element.scrollY ? element.scrollY : element.scrollTop) : element.scrollX ? element.scrollX : element.scrollLeft;
  const distance = scrollValue - initialScroll;

  // If the distance is less than 80% of the screen, reduce the animation duration up to half
  const elementInnerPixels = direction === 'top' ? window.innerHeight : window.innerWidth;
  const adjustedDuration = Math.max(Math.min(Math.abs(distance) / elementInnerPixels / 0.8, 1) * duration, duration * 0.5);

  const timing = (t: number) => t * (2 - t); // ease out function
  const animate = (time: number) => {
    if (!startTime) {
      startTime = time;
    }

    let timeFraction = (time - startTime) / adjustedDuration;
    if (timeFraction > 1) {
      timeFraction = 1;
    }

    const progress = timing(timeFraction);

    if (direction === 'top') {
      element.scrollTo({ top: initialScroll + progress * distance });
    } else {
      element.scrollTo({ left: initialScroll + progress * distance });
    }

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  };

  requestAnimationFrame(animate);
}
