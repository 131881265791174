import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EvaluationModel, EvaluationState } from 'src/app/shared/models/evaluation.model';
import { EvaluationStore } from './evaluation.store';

@Injectable({ providedIn: 'root' })
export class EvaluationQuery extends Query<EvaluationState> {
  evaluationList$ = this.select(state => state.evaluationList);
  awaitingReplyList$ = this.select(state => state.awaitingReplyList);

  constructor(protected store: EvaluationStore) {
    super(store);
  }

  evaluationList(): EvaluationModel[] {
    return this.getValue().evaluationList;
  }

  isInEvaluation(couponCode: string): boolean {
    // supports checking by either the cashoutId or the couponCode
    const isInEvaluation = this.evaluationList().find(i => couponCode !== undefined && i.coupon.CouponCode === couponCode);

    return isInEvaluation !== undefined;
  }
}
