import { Injectable, OnDestroy } from '@angular/core';
import { Query } from '@datorama/akita';
import { CouponType } from 'clientside-coupon';
import { BetCouponFreebetDetails } from 'clientside-coupon/lib/clientside-coupon.model';
import { isAfter, isBefore } from 'date-fns';
import { isEqual } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { FreeBetProductType, FreebetRewardStatus, FreebetsState, FreebetsVoucher } from 'src/app/modules/freebets/models/freebets.model';
import { FreebetsStore } from 'src/app/modules/freebets/state/freebets.store';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { COUPON_DATA_KEY, USER_DATA_KEY, VIRTUALS_SCHEDULED_COUPON_DATA_KEY } from 'src/app/shared/utils/local-storage-keys';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';

@Injectable({ providedIn: 'root' })
export class FreebetsQuery extends Query<FreebetsState> implements OnDestroy {
  private readonly _destroy$ = new Subject<boolean>();
  activeFreeBetProduct$ = this.select(state => state.activeProduct);
  vouchers$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].vouchers)),
    takeUntil(this._destroy$)
  );

  freeBetsAvailableContent$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => {
      return this.select(state => ({
        singleFreebetAvailable: state[activeFreeBetProduct].freeBetsBetslipContent?.singleFreebetAvailable,
        multipleFreebetsAvailable: state[activeFreeBetProduct].freeBetsBetslipContent?.multipleFreebetsAvailable,
      }));
    }),
    takeUntil(this._destroy$)
  );

  freeBetsModalContent$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => {
      return this.select(state => ({
        modalTitle: state[activeFreeBetProduct].freeBetsBetslipContent?.modalHeaderTitle,
        modalSubtitle: state[activeFreeBetProduct].freeBetsBetslipContent?.modalSubHeaderTitle,
        modalBodyText: state[activeFreeBetProduct].freeBetsBetslipContent?.modalSubHeaderDescription,
        modalTCLabel: state[activeFreeBetProduct].freeBetsBetslipContent?.tcLabel,
        modalTCUrl: state[activeFreeBetProduct].freeBetsBetslipContent?.tcUrl,
      }));
    }),
    takeUntil(this._destroy$)
  );

  // Check if freebets are enabled
  allowFreebets$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].freeBetsConfig.allowFreeBets)),
    takeUntil(this._destroy$)
  );

  // Check if freebets are enabled + if they should be currently shown in coupon
  // They should only be shown if user is authenticated and coupon is of correct type
  showFreebetsInSportsbookCoupon$ = combineLatest([
    this.allowFreebets$,
    this.accountQuery.isAuthenticated$,
    this.select(state => state[FreeBetProductType.SportsBook].freeBetsConfig.allowOnSingleBets),
    this.select(state => state[FreeBetProductType.SportsBook].freeBetsConfig.allowOnMultipleBets),
    this.select(state => state[FreeBetProductType.SportsBook].freeBetsConfig.allowOnSystemBets),
    this.sportsCouponQuery.couponData$.pipe(
      map(couponData => (couponData ? couponData.CouponTypeId : -1)),
      distinctUntilChanged()
    ),
  ]).pipe(
    map(
      ([allowFreebets, isAuth, allowOnSingleBets, allowOnMultipleBets, allowOnSystemBets, couponTypeId]) =>
        allowFreebets && isAuth && this._couponTypeCheck(couponTypeId, allowOnSingleBets, allowOnMultipleBets, allowOnSystemBets)
    ),
    distinctUntilChanged(),
    takeUntil(this._destroy$)
  );

  showFreebetsInVirtualsCoupon$ = combineLatest([
    this.allowFreebets$,
    this.accountQuery.isAuthenticated$,
    this.select(state => state[FreeBetProductType.VirtualsScheduled].freeBetsConfig.allowOnSingleBets),
    this.select(state => state[FreeBetProductType.VirtualsScheduled].freeBetsConfig.allowOnMultipleBets),
    this.select(state => state[FreeBetProductType.VirtualsScheduled].freeBetsConfig.allowOnSystemBets),
    this.virtualsScheduledCouponQuery.couponData$.pipe(
      map(couponData => (couponData ? couponData.CouponTypeId : -1)),
      distinctUntilChanged()
    ),
  ]).pipe(
    map(
      ([allowFreebets, isAuth, allowOnSingleBets, allowOnMultipleBets, allowOnSystemBets, couponTypeId]) =>
        allowFreebets && isAuth && this._couponTypeCheck(couponTypeId, allowOnSingleBets, allowOnMultipleBets, allowOnSystemBets)
    ),
    distinctUntilChanged(),
    takeUntil(this._destroy$)
  );

  freebetsStakeDisclaimer$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].freeBetsMyBetsContent?.freebetsStakeDisclaimer)),
    takeUntil(this._destroy$)
  );

  freebetsAvailableLabel$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => {
      return combineLatest([
        this.numberOfAvailableFreebets$(activeFreeBetProduct),
        this.select(state => state[activeFreeBetProduct].freeBetsBetslipContent?.singleFreebetAvailable),
        this.select(state => state[activeFreeBetProduct].freeBetsBetslipContent?.multipleFreebetsAvailable),
      ]).pipe(
        map(([numberOfAvailableFreebets, singleFreebetAvailable, multipleFreebetsAvailable]) => {
          if (numberOfAvailableFreebets) {
            if (numberOfAvailableFreebets > 1) {
              return this.labelGenerator(multipleFreebetsAvailable, numberOfAvailableFreebets.toString());
            }
            return this.labelGenerator(singleFreebetAvailable, numberOfAvailableFreebets.toString());
          }
          return undefined;
        })
      );
    }),
    takeUntil(this._destroy$)
  );

  configCallDone$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].configCallDone)),
    takeUntil(this._destroy$)
  );

  betslipContentCallDone$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct =>
      this.select(state => state[activeFreeBetProduct].betslipContentCallDone || !state[activeFreeBetProduct].freeBetsConfig.allowFreeBets)
    ),
    takeUntil(this._destroy$)
  );

  myBetsContentCallDone$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].mybetsContentCallDone)),
    takeUntil(this._destroy$)
  );

  updateVoucherCallIsLoading$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].voucherCallIsLoading)),
    takeUntil(this._destroy$)
  );

  lastTimeVouchersHaveBeenRetrieved$ = this.activeFreeBetProduct$.pipe(
    switchMap(activeFreeBetProduct => this.select(state => state[activeFreeBetProduct].lastTimeVouchersHaveBeenRetrieved)),
    takeUntil(this._destroy$)
  );

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly sportsCouponQuery: CouponQuery,
    private readonly virtualsScheduledCouponQuery: VirtualsCouponQuery,
    private readonly currencyPipe: CurrencyFormatPipe,
    private readonly localStorageService: LocalStorageService,
    protected store: FreebetsStore
  ) {
    super(store);
  }

  get activeFreeBetProduct(): FreeBetProductType {
    return this.getValue().activeProduct;
  }

  get allowFreeBets(): boolean {
    return this.getValue()[this.activeFreeBetProduct].freeBetsConfig.allowFreeBets;
  }

  get showFreeBets(): boolean {
    return this.allowFreeBets && this.accountQuery.isAuthenticated && this.couponTypeCheck(this.sportsCouponQuery.couponData.CouponTypeId);
  }

  get allFreebetVouchers(): FreebetsVoucher[] {
    return this.getValue()[this.activeFreeBetProduct].vouchers;
  }

  get numberOfFreebetsVouchers(): number {
    return this.localStorageService.retrieve(USER_DATA_KEY)?.vouchers?.length || 0;
  }

  get allAvailableFreebetVouchers(): FreebetsVoucher[] {
    return this.allFreebetVouchers?.filter(
      (voucher: FreebetsVoucher) =>
        voucher.reward.status === FreebetRewardStatus.Available &&
        isBefore(new Date(voucher.startDate), new Date()) &&
        isAfter(new Date(voucher.endDate), new Date())
    );
  }

  get freeBetsAvailableContent(): { singleFreebetAvailable: string; multipleFreebetsAvailable: string } {
    return {
      singleFreebetAvailable: this.getValue()[this.activeFreeBetProduct].freeBetsBetslipContent?.singleFreebetAvailable,
      multipleFreebetsAvailable: this.getValue()[this.activeFreeBetProduct].freeBetsBetslipContent?.multipleFreebetsAvailable,
    };
  }

  get lastTimeVouchersHaveBeenRetrieved(): number {
    return this.getValue()[this.activeFreeBetProduct].lastTimeVouchersHaveBeenRetrieved;
  }

  private _couponDataVoucher$(productType: FreeBetProductType): Observable<BetCouponFreebetDetails> {
    if (productType === FreeBetProductType.SportsBook) {
      return this.sportsCouponQuery.couponData$.pipe(
        map(couponData => (couponData ? couponData.BetDetails?.FreeBetDetails : undefined)),
        takeUntil(this._destroy$)
      );
    }

    if (productType === FreeBetProductType.VirtualsScheduled) {
      return this.virtualsScheduledCouponQuery.couponData$.pipe(
        map(couponData => (couponData ? couponData.BetDetails?.FreeBetDetails : undefined)),
        takeUntil(this._destroy$)
      );
    }
  }

  availableFreebetVouchers$(productType: FreeBetProductType): Observable<FreebetsVoucher[]> {
    return this.vouchers$.pipe(
      // Do not operate observable if no vouchers have been retrieved
      filter(vouchers => !!vouchers && vouchers.length > 0),
      distinctUntilChanged(isEqual),
      // Only get vouchers with 'Available' status, and are within operational dates
      map(
        vouchers =>
          vouchers.filter(
            (voucher: FreebetsVoucher) =>
              voucher.reward.status === FreebetRewardStatus.Available &&
              isBefore(new Date(voucher.startDate), new Date()) &&
              isAfter(new Date(voucher.endDate), new Date())
          ) as FreebetsVoucher[]
      ),
      // Only return if freebets are enabled
      mergeMap(vouchers => {
        if (productType === FreeBetProductType.SportsBook) {
          return this.showFreebetsInSportsbookCoupon$.pipe(map(canShowFreebets => (canShowFreebets ? vouchers : [])));
        }

        if (productType === FreeBetProductType.VirtualsScheduled) {
          return this.showFreebetsInVirtualsCoupon$.pipe(map(canShowFreebets => (canShowFreebets ? vouchers : [])));
        }
      }),
      takeUntil(this._destroy$)
    );
  }

  numberOfAvailableFreebets$(productType: FreeBetProductType): Observable<number> {
    return this.availableFreebetVouchers$(productType).pipe(
      map((vouchers: FreebetsVoucher[]) => vouchers.length),
      takeUntil(this._destroy$)
    );
  }

  selectedFreebetVoucher$(productType: FreeBetProductType): Observable<FreebetsVoucher> {
    return combineLatest([this._couponDataVoucher$(productType), this.availableFreebetVouchers$(productType)]).pipe(
      // Complete voucher info that was retreived from coupon data with that in user data
      map(([selectedVoucher, allAvailableFreebetVouchers]) =>
        !!selectedVoucher?.code && allAvailableFreebetVouchers
          ? allAvailableFreebetVouchers.find(voucher => selectedVoucher.code === voucher.code)
          : undefined
      ),
      takeUntil(this._destroy$)
    );
  }
  hasSelectedFreebetVoucher$(productType: FreeBetProductType): Observable<boolean> {
    if (productType === FreeBetProductType.SportsBook) {
      return this.selectedFreebetVoucher$(productType).pipe(
        map(voucher => !!voucher),
        mergeMap(hasSelected => this.showFreebetsInSportsbookCoupon$.pipe(map(canShowFreebets => hasSelected && canShowFreebets))),
        takeUntil(this._destroy$)
      );
    }

    if (productType === FreeBetProductType.VirtualsScheduled) {
      return this.selectedFreebetVoucher$(productType).pipe(
        map(voucher => !!voucher),
        mergeMap(hasSelected => this.showFreebetsInVirtualsCoupon$.pipe(map(canShowFreebets => hasSelected && canShowFreebets))),
        takeUntil(this._destroy$)
      );
    }
  }

  betslipFreeBetsAppliedLabel$(productType: FreeBetProductType): Observable<string> {
    return this.activeFreeBetProduct$.pipe(
      switchMap(activeFreeBetProduct => {
        return combineLatest([
          this.selectedFreebetVoucher$(productType),
          this.select(state => state[activeFreeBetProduct].freeBetsBetslipContent?.freebetApplied),
        ]).pipe(
          map(([selectedVoucher, label]) =>
            selectedVoucher ? this.labelGenerator(label, this.currencyPipe.transform(selectedVoucher.reward.remainingValue, '1.0-0')) : ''
          ),
          takeUntil(this._destroy$)
        );
      })
    );
  }

  readonly isSportsFreebetSelected = (code: string): boolean =>
    this.localStorageService.retrieve(COUPON_DATA_KEY).BetDetails?.FreeBetDetails?.code === code;

  readonly isVirtualsScheduledFreebetSelected = (code: string): boolean =>
    this.localStorageService.retrieve(VIRTUALS_SCHEDULED_COUPON_DATA_KEY).BetDetails?.FreeBetDetails?.code === code;

  readonly couponTypeCheck = (couponType: CouponType): boolean =>
    this._couponTypeCheck(
      couponType,
      this.getValue()[this.activeFreeBetProduct].freeBetsConfig.allowOnSingleBets,
      this.getValue()[this.activeFreeBetProduct].freeBetsConfig.allowOnMultipleBets,
      this.getValue()[this.activeFreeBetProduct].freeBetsConfig.allowOnSystemBets
    );

  readonly myBetsFreeBetsAppliedLabel$ = (freebetAmount: number): Observable<string> =>
    this.activeFreeBetProduct$.pipe(
      switchMap(activeFreeBetProduct => {
        return this.select(state => state[activeFreeBetProduct].freeBetsMyBetsContent?.freebetApplied).pipe(
          map(label => this.labelGenerator(label, this.currencyPipe.transform(freebetAmount, '1.0-0')))
        );
      }),
      takeUntil(this._destroy$)
    );

  private readonly _couponTypeCheck = (
    couponType: CouponType,
    allowOnSingleBets: boolean,
    allowOnMultipleBets: boolean,
    allowOnSystemBets: boolean
  ): boolean =>
    (couponType === CouponType.Single && allowOnSingleBets) ||
    (couponType === CouponType.Multiple && allowOnMultipleBets) ||
    (couponType === CouponType.System && allowOnSystemBets);

  private readonly labelGenerator = (label: string, replacingValue: string): string =>
    label.includes('{0}') ? label.replace('{0}', replacingValue) : label;

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
