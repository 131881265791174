import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { GeoAccessControlConfig } from 'src/app/shared/models/geo-access-control.model';

@Component({
  selector: 'app-site-unavailable',
  templateUrl: './app-site-unavailable.component.html',
  styleUrls: ['./app-site-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteUnavailableComponent implements OnInit, OnDestroy {
  constructor(private readonly appConfig: AppConfigService, private readonly applicationService: ApplicationService) {}

  ngOnInit(): void {
    this.applicationService.hideNavbar();

    const config: GeoAccessControlConfig = this.appConfig.get('geoAccessControl');
    if (!config || !config.accessBlockingEnabled) {
      window.location.href = '/';
      return;
    }
  }

  ngOnDestroy(): void {
    this.applicationService.showNavbar();
  }
}
