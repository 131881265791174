import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { BetLiveDetailsModel } from 'src/app/modules/my-bets/models/my-bets.model';

/**
 * This will handle live detail retrieval of my-bets products and coupon-details.
 */

@Injectable({
  providedIn: 'root',
})
export class MyBetsLiveService {
  private _pollingLiveEvents = undefined;

  /**
   * Handle the live polling of bets. Scope of function is to handle the timings rather than the business logic of things.
   * Function will keep on working and polling until:
   * - No live bets are to be polled
   * - Live polling is not enabled
   * - Destroy$ is triggered
   * @param liveDataCall The function that will handle live data API call
   * @param updateLiveBetsDetails The function that will store the live details of the bets
   * @param interval The interval in ms
   * @param isLiveDetailsPollingEnabled$ The config of whether polling is enabled or not
   * @param numberOfLiveBets$ The number of live bets for which we are polling
   * @param destroy$ When to stop the function
   */
  livePolling(
    liveDataCall: Function,
    updateLiveBetsDetails: Function,
    interval: number,
    isLiveDetailsPollingEnabled$: Observable<boolean>,
    numberOfLiveBets$: Observable<number>,
    destroy$: Subject<boolean>
  ) {
    combineLatest([isLiveDetailsPollingEnabled$, numberOfLiveBets$])
      .pipe(
        tap(([enabled, numberOfliveBets]) => {
          if (numberOfliveBets > 0 && enabled) {
            if (!this._pollingLiveEvents) {
              const getLiveData = () => {
                liveDataCall()
                  .pipe(
                    tap(liveBetDetails => {
                      updateLiveBetsDetails(liveBetDetails);
                    })
                  )
                  .subscribe();
              };
              getLiveData();
              this._pollingLiveEvents = setInterval(() => {
                getLiveData();
              }, interval);
            }
          } else {
            this.clearPolling();
          }
        }),
        takeUntil(destroy$)
      )
      .subscribe();

    destroy$.pipe(
      first(),
      tap(() => {
        this.clearPolling();
      })
    );
  }

  parseSportsLiveEvents(response: any): BetLiveDetailsModel[] {
    const retVal: BetLiveDetailsModel[] = [];
    if (!response.Sports || response.Sports.length === 0) {
      return;
    }

    response.Sports.forEach(responseSport => {
      responseSport.Tournaments.forEach(responseTournament => {
        responseTournament.Events.forEach(responseEvents => {
          const newLiveEvent: BetLiveDetailsModel = {
            awayGameScore: responseEvents.AwayGameScore,
            eventId: responseEvents.Id,
            eventStatus: responseEvents.EventStatus,
            homeGameScore: responseEvents.HomeGameScore,
            matchStatus: responseEvents.MatchStatus,
            setScores: responseEvents.SetScores,
            matchTime: responseEvents.MatchTime,
          };

          retVal.push(newLiveEvent);
        });
      });
    });
    return retVal;
  }

  private clearPolling() {
    if (this._pollingLiveEvents) {
      clearInterval(this._pollingLiveEvents);
      this._pollingLiveEvents = undefined;
    }
  }
}
