import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';
import { FormMessageTypes } from 'src/app/shared/models/informational-message.model';

@Component({
  selector: 'app-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMessageComponent {
  @Input() type: FormMessageTypes = 'danger';
  @Input() text = 'Text is missing';
  @Input() size: 'small' | 'big' = 'small';
  @Input() faIcon: string;
  @Input() padding: string;
}
