import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class PageVisibilityApi {
  hidden: string;
  visibilityChange: string;
  isPageVisible$: Observable<boolean>;
  visibilityChange$: Observable<Event>;

  constructor(private readonly appConfig: AppConfigService) {
    this.checkCompatibilityAndInitStreams();
  }

  checkCompatibilityAndInitStreams(): void {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
    } else if (typeof (window as any).document.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (typeof (window as any).document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }
    this.visibilityChange$ = fromEvent(document, this.visibilityChange);
    this.isPageVisible$ = fromEvent(document, this.visibilityChange).pipe(map(() => !document[this.hidden]));
  }
}
