import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BetCoupon,
  BetCouponGroup,
  ClientsideCouponService,
  CouponAction,
  CouponType,
  Selection,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { map } from 'rxjs/operators';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoggerService } from 'src/app/core/services/logger.service';
import { InstantTicket, RoundSelection } from 'src/app/shared/models/instant-coupon.model';
import { InstantCouponStore } from 'src/app/core/state/instant-coupon/instant-coupon.store';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { APIService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountService } from 'src/app/core/services/account/account.service';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { InstantCategory } from 'src/app/shared/models/virtuals.model';
import { INSERT_COUPON_STATUS_CODES, UPDATE_VIRTUALS_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { mapCouponType } from 'src/app/shared/utils/virtuals-instant-league';
import { BuildInstantCouponService } from './build-instant-coupon.service';

@Injectable({
  providedIn: 'root',
})
export class InstantCouponService {
  enforceSingleCombination: boolean = false;
  userLimits = this.virtualsQuery.instantUserData?.userLimits;
  loading: boolean = false;

  constructor(
    private readonly instantCouponStore: InstantCouponStore,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly instantCouponQuery: InstantCouponQuery,
    private readonly accountQuery: AccountQuery,
    private readonly virtualsQuery: VirtualsQuery,
    private readonly appConfig: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly apiService: APIService,
    private readonly localStorage: LocalStorageService,
    private readonly notificationService: NotificationService,
    private readonly virtualsInstantService: VirtualsInstantService,
    private readonly loggerService: LoggerService,
    private readonly buildInstantCouponService: BuildInstantCouponService,
    private readonly accountService: AccountService,
    private readonly dataLayerService: DataLayerService,
    private readonly router: Router
  ) {}

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('virtuals').instantLeague.defaultCurrency;
  }

  initialize(): void {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    // Update bonus list, because we are storing bonus list for sports/virtuals/instant at one place in the store
    this.accumulatorBonusStore.updateBonusList(this.virtualsQuery.instantUserData?.userBonusList);

    if (!this.instantCouponQuery.couponInitialized) {
      this.apiService.get<any>(APIType.VirtualsFeeds, `v1/settings/globalvariables`, apiSettings).subscribe(globalData => {
        if (globalData !== undefined) {
          if (this.userLimits) {
            this.parseGlobalVariablesFromLocalStorage(globalData);
          }

          this.instantCouponStore.updateGlobalVariables(globalData);
          this.instantCouponStore.updateCouponInitialized(true);
        }
      });
    }
  }

  addOdd(odd: any): any {
    const response = this.updateCoupon({
      action: CouponAction.AddOdd,
      couponData: this.instantCouponQuery.couponData,
      requestData: {
        selection: odd,
        allowSameMatchSelections: this.appConfig.get('virtuals').instantLeague.allowSameMatchSelections,
      },
      saveResponse: false,
    });

    // Checking for bigger than 0 because client side coupon returns -1 when total combinations are reached
    if (response.updatedCoupon.TotalCombinations > 0) {
      this.instantCouponStore.updateCouponData(response.updatedCoupon);
    } else {
      this.notificationService.showErrorNotification($localize`Maximum number of combinations reached.`);
    }

    return response;
  }

  removeOdd(oddId: number): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.updateCoupon({
      action: CouponAction.RemoveOdd,
      couponData: this.instantCouponQuery.couponData,
      requestData: {
        selection,
      },
    });

    this.removeOddFromStorage(oddId);

    return response;
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.UpdateGroupings,
      couponData: this.instantCouponQuery.couponData,
      requestData: {
        groupings,
      },
    });
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.UpdateGroupingStakeValue,
      couponData: this.instantCouponQuery.couponData,
      requestData: {
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      },
    });
  }

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      return this.updateCoupon({
        action: CouponAction.UpdateStakeValue,
        couponData: this.instantCouponQuery.couponData,
        requestData: {
          stakeValue,
        },
      });
    }
  }

  getShowInstantLeagueSwitchPopup(): Observable<boolean> {
    return this.localStorage.observe('showInstantLeagueSwitchPopup');
  }

  setShowInstantLeagueSwitchPopup(value: boolean): void {
    this.localStorage.store('showInstantLeagueSwitchPopup', value);
  }

  validateCoupon(): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.ValidateCoupon,
      couponData: this.instantCouponQuery.couponData,
      saveResponse: false,
    });
  }

  clearCouponData(): void {
    this.instantCouponStore.clearCouponData();
    this.buildInstantCouponService.clearCouponData();
  }

  clearLeagueBetsFromCoupon(league): void {
    const { additionalCouponData, couponData, betsToRemove } = this.instantCouponStore.clearLeagueBetsFromCoupon(league);
    if (additionalCouponData.selectedOdds.length && couponData.Odds.length) {
      betsToRemove.forEach(bet => {
        this.removeOdd(bet.oddIdd);
      });
      const newBets = additionalCouponData.selectedOdds.filter(bet => bet.categoryId !== league);
      additionalCouponData.selectedOdds = newBets;
      this.instantCouponStore.restoreCoupon(additionalCouponData);
    }
  }

  validateAndPostCoupon(): Observable<boolean> {
    if (this.instantCouponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postCoupon();
    }
  }

  getUpdateCouponStatus(statusCode: number): string {
    return UPDATE_VIRTUALS_COUPON_STATUS_CODES[statusCode];
  }

  // Centralized function for calling the CSC updateCoupon function, to reduce code repetition
  private updateCoupon({
    action,
    couponData,
    requestData,
    saveResponse = true,
  }: {
    action: CouponAction;
    couponData: BetCoupon;
    requestData?: Partial<UpdateCouponRequest>;
    saveResponse?: boolean;
  }): UpdateCouponResponse {
    const request = new UpdateCouponRequest({
      action,
      coupon: couponData,
      brandID: this.appConfig.get('brandId'),
      bonusList: this.accumulatorBonusQuery.bonusList,
      globalVariables: this.instantCouponQuery.globalVariables,
      marketExceptions: undefined,
      correctScoreOddsMatrix: undefined,
      ...requestData,
    });
    const response = this.clientsideCouponService.updateCoupon(request);

    if (saveResponse) {
      this.instantCouponStore.updateCouponData(response.updatedCoupon);
    }
    return response;
  }

  private postCoupon(): Observable<boolean> {
    const couponData = this.buildInstantCouponService.buildCouponData();
    this.loading = true;
    const numberOfSelections = this.buildInstantCouponService.getTotalBets();

    return this.insertCoupon(couponData).pipe(
      map(insertResponse => {
        this.loading = false;

        if (!insertResponse.success) {
          this.handleInsertCouponFailure(insertResponse);

          this.dataLayerService.addBetslipEventToDataLayer({
            event: BetslipActions.BetFailure,
            product: DataLayerProduct.VirtualsInstant,
            userId: this.accountQuery.userData?.id,
            currency: this.accountQuery.userData.currency.name,
            betStake: couponData?.details?.systemBets[0]?.stake,
            betType: mapCouponType(couponData?.details?.systemBets[0]?.systemCount, couponData.details?.events?.length),
            errorMessage: this.getInsertCouponStatus(insertResponse.statusCode),
            selections: numberOfSelections,
          });
          this.loggerService.logEvent('Instant Coupon Not Posted', insertResponse.message, SeverityLevel.Error);
          return false;
        }

        this.dataLayerService.addBetslipEventToDataLayer({
          event: BetslipActions.BetSuccess,
          product: DataLayerProduct.VirtualsInstant,
          userId: this.accountQuery.userData?.id,
          betStake: couponData?.details?.systemBets[0]?.stake,
          betType: mapCouponType(couponData?.details?.systemBets[0]?.systemCount, couponData.details?.events?.length),
          currency: this.accountQuery.userData.currency.name,
          couponId: insertResponse.couponId,
          selections: numberOfSelections,
        });
        this.accountService.updateBalance();
        this.instantCouponStore.updateLastDataCouponStakeValue(couponData.details.systemBets[0].stake ?? 0);
        return true;
      })
    );
  }

  private handleInsertCouponFailure(response): void {
    const message: string = this.getInsertCouponStatus(response.statusCode);

    if (response.statusCode === '606') {
      this.notificationService.showCustomNotification(
        message,
        'info',
        () => (window.location.href = '/account/payments/deposit'),
        $localize`Deposit`,
        $localize`Insufficient balance`,
        undefined,
        undefined,
        false,
        'redesign'
      );
    } else {
      this.notificationService.showNotification(
        new NotificationSettings({
          contentHtml: message,
          showConfirmButton: true,
          title: $localize`Coupon Not Posted`,
          type: 'error',
          confirmButtonCallback: () => {
            // Gather for cases when user is connected on two devices, to sync current round
            if (Number(response.statusCode) === 602) {
              this.clearCouponData();
              this.reloadAndContinueBetting();
            }
          },
        })
      );
    }
  }

  private insertCoupon(couponData): Observable<any> {
    return this.virtualsInstantService.postDataToGR('/tickets/send', couponData).pipe(
      map(responseData => {
        let couponPosted = false;
        let response;
        if (responseData.ticket) {
          couponPosted = true;
          this.instantCouponStore.clearCouponData();
          this.saveSelectionsToLocalStorage(responseData.ticket);
          this.saveWinningsToLocalStorage(responseData.ticket);
          response = {
            success: couponPosted,
            statusCode: '200',
            message: 'success',
            couponId: responseData.ticket.ticketId,
          };
        } else {
          response = {
            success: couponPosted,
            statusCode: responseData.errorCode,
            message: responseData.message,
          };
        }

        return response;
      })
    );
  }

  private saveSelectionsToLocalStorage(ticket: any): void {
    const recentBetsStorageKey = this.appConfig.get('virtuals').instantLeague?.recentBetsStorageKey;
    if (!recentBetsStorageKey) {
      return;
    }

    let recentSelections: RoundSelection[] = JSON.parse(localStorage.getItem(recentBetsStorageKey)) || [];
    ticket.details.events.forEach(event => {
      const selectionsForTournament = recentSelections || [];

      if (selectionsForTournament.length === 0) {
        recentSelections = selectionsForTournament;
      }
      event.bets.forEach(bet => {
        selectionsForTournament.push({
          marketId: bet.marketId,
          marketName: bet.marketId,
          matchId: event.eventId,
          oddValue: bet.oddValue,
          selectionId: bet.oddId,
          selectionName: bet.oddId,
          oddsBoost: false,
        });
      });
    });

    this.localStorage.store(recentBetsStorageKey, recentSelections);
  }

  private getInsertCouponStatus(statusCode: number): string {
    return INSERT_COUPON_STATUS_CODES[statusCode];
  }

  // Values available from GR BO are being added to the Global Variables
  private parseGlobalVariablesFromLocalStorage(globalData) {
    if (!this.userLimits) {
      return;
    }

    if (this.userLimits.maxStake) {
      globalData.MaxBetStake = this.userLimits.maxStake;
    }

    if (this.userLimits.minStake) {
      globalData.MinBetStake = this.userLimits.minStake;
    }

    if (this.userLimits.minGroupingsBetStake) {
      globalData.MinGroupingsBetStake = this.userLimits.minGroupingsBetStake;
    }

    if (this.userLimits.maxGroupingsBetStake) {
      globalData.MaxGroupingsBetStake = this.userLimits.maxGroupingsBetStake;
    }
  }

  private saveWinningsToLocalStorage(ticket: InstantTicket): void {
    const instantWinningsStorageKey = this.appConfig.get('virtuals').instantLeague?.winningsStorageKey;
    if (!instantWinningsStorageKey) {
      return;
    }

    const winningsData = {
      maxWinning: ticket?.winningData?.maxWinning || null,
      limitMaxPayout: ticket?.winningData?.limitMaxPayout || null,
      ticketId: ticket?.ticketId,
    };
    this.localStorage.store(instantWinningsStorageKey, winningsData);
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);
    const numberOfSelections = this.buildInstantCouponService.getTotalBets();

    // Show error message according to the status code returned
    if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsInstant,
        errorMessage: `${errorMessage} of ${this.getFormattedMinStake()}`,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(`${errorMessage} of ${this.getFormattedMinStake()}`, $localize`Coupon Not Posted`);
    } else if (statusCode === 21) {
      const message = $localize`Maximum win amount over the limit of ${this.getFormattedMaxWin()}`;

      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsInstant,
        errorMessage: message,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(message, $localize`Coupon Not Posted`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`Minimum total stake is ${this.getFormattedTotalStake()}.`;

      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsInstant,
        errorMessage: `${errorMessage} of ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(
        `${errorMessage} ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        $localize`Coupon Not Posted`
      );
    } else {
      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsInstant,
        errorMessage: errorMessage,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
    }
  }

  private getFormattedMinStake(): string {
    const minAllowedStake = this.instantCouponQuery.globalVariables.MinBetStake;
    return formatCurrency(minAllowedStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedMaxWin(): string {
    let maxWin = 0;
    const couponType =
      this.instantCouponQuery.couponData && this.instantCouponQuery.couponData.CouponTypeId !== undefined
        ? this.instantCouponQuery.couponData.CouponTypeId
        : CouponType.Single;

    switch (couponType) {
      case CouponType.Single: {
        maxWin = this.instantCouponQuery.globalVariables.MaxSingleBetWin;
        break;
      }
      case CouponType.Multiple: {
        maxWin = this.instantCouponQuery.globalVariables.MaxMultipleBetWin;
        break;
      }
      case CouponType.System: {
        maxWin = this.instantCouponQuery.globalVariables.MaxCombinationBetWin;
        break;
      }
      default:
        maxWin = this.instantCouponQuery.globalVariables.MaxSingleBetWin;
    }

    return this.formatShortNumber(maxWin);
  }

  private getFormattedMinGroupStake(): string {
    const minAllowedGroupingStake = this.instantCouponQuery.globalVariables.MinGroupingsBetStake;
    return formatCurrency(minAllowedGroupingStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private formatShortNumber(num: number): string {
    let abs = Math.abs(num);
    let multiplicatorSymbol = '';

    const multiplicatorSymbols = [
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (const symbol of multiplicatorSymbols) {
      let quotient = abs / symbol.value;
      quotient = Math.round(quotient * 10) / 10;
      if (quotient >= 1) {
        abs = quotient;
        multiplicatorSymbol = symbol.key;
        break;
      }
    }

    // TODO: this doesn't support localization, Intl.NumberFormat could be an option
    return `${this.currencySymbol}${num < 0 ? '-' : ''}${abs}${multiplicatorSymbol}`;
  }

  private getFormattedTotalStake(): string {
    const minAllowedGroupingStake = this.instantCouponQuery.globalVariables.MinGroupingsBetStake;
    let totalCombinations = 0;
    let totalStake = 0;

    this.instantCouponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    totalStake = minAllowedGroupingStake * totalCombinations;

    return formatCurrency(totalStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private reloadAndContinueBetting(): void {
    const instantCategories: InstantCategory[] = Object.values(this.appConfig.get('virtuals').instantLeague?.categories);
    const selectedLeague = instantCategories.find(
      category => category.name === this.instantCouponQuery.selections?.[0]?.categories?.[0].name
    );
    const redirectLeagueUrl = selectedLeague ? selectedLeague.url : '';

    // Force reload to get new round feeds
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/virtual/instant/${redirectLeagueUrl}`]);
    });
  }

  private removeOddFromStorage(oddId: number): void {
    const couponExtraDataStorageKey = this.appConfig.get('virtuals').instantLeague?.couponExtraDataStorageKey;
    const additionalCouponData = this.localStorage.retrieve(couponExtraDataStorageKey);
    additionalCouponData.selectedOdds = additionalCouponData.selectedOdds.filter(odd => odd.oddIdd !== oddId);

    this.localStorage.store(couponExtraDataStorageKey, additionalCouponData);
  }
}
