<div class="confirm-book-bet-container" *ngIf="bookedBetData">

  <app-notification-message type="info" i18n-message message="There are expired events in your booked coupon">
  </app-notification-message>

  <div class="expired-details">
    <div class="container split">
      <div class="content">
        <div class="label" i18n>Events requested:</div>
        <div class="value">{{ bookedBetData.originalEventCount }}</div>
      </div>
      <div class="content">
        <div class="label" i18n>Events still available:</div>
        <div class="value">{{ bookedBetData.availableEventCount }}</div>
      </div>
    </div>
    <div class="container events-expired">
      <div class="title" i18n>The following events are unavailable:</div>
      <div class="content" *ngFor="let eventName of bookedBetData.removedEvents; trackBy: indexTrackBy;">
        <div class="value">{{ eventName }}</div>
      </div>
    </div>

    <div class="confirm-title" i18n>Would you like to continue?</div>

    <div class="buttons">
      <app-button i18n-text text="Cancel" [customButtonStyle]="actionButtonStyle"
        [buttonType]="buttonType.TransparentSecondary" (click)="cancel.emit()">
      </app-button>

      <app-button i18n-text text="Proceed" [customButtonStyle]="actionButtonStyle"
        [buttonType]="buttonType.Highlight" (click)="confirm.emit()">
      </app-button>
    </div>
  </div>
</div>
