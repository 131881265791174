import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EvaluationSignalrService {
  private readonly cashoutOutputMessageSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  cashoutEvaluationComplete$: Observable<any> = this.cashoutOutputMessageSubject$.asObservable();

  private readonly betOutputMessageSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  betEvaluationComplete$: Observable<any> = this.betOutputMessageSubject$.asObservable();

  private hubConnection: HubConnection;
  private isConnectionStartState = false;

  constructor(private readonly accountQuery: AccountQuery, private readonly appConfigService: AppConfigService) {}

  startConnection(): void {
    if (!this.hubConnection) {
      // Open Connection with the server
      const apiBaseUrl = this.appConfigService.get('apiBaseUrl');
      let apiUrl;
      if (environment.production) {
        apiUrl = apiBaseUrl.sportsbookWebSocket;
      } else {
        const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookWebSocketUrl');
        apiUrl = overrideUrl || apiBaseUrl.sportsbookWebSocket;
      }

      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}/evaluationHub`, {
          headers: {
            'X-TOKEN': this.accountQuery.accessToken,
            'X-BRAND': this.appConfigService.get('brandId'),
          },
        })
        .withAutomaticReconnect()
        .build();

      // Set Listeners
      this.addListeners();
    }

    if (!this.isConnectionStartState) {
      // Start Connection
      this.hubConnection.start();
      this.isConnectionStartState = true;
    }
  }

  stopConnection(): void {
    if (this.hubConnection && this.isConnectionStartState) {
      // Stop Connection
      this.hubConnection.stop();
      this.isConnectionStartState = false;
    }
  }

  private getLocalStorageOverride(key: string): string {
    // making use of window.localStorage directly in order to avoid having
    // to include the ngx-webstorage prefix value to the keys
    return window.localStorage.getItem(key);
  }

  private addListeners(): void {
    // Cashout Evaluation Listener
    this.hubConnection.on('CashoutEvaluationComplete', (response: any) => {
      this.cashoutOutputMessageSubject$.next(response);
    });

    // Bet Evaluation Listener
    this.hubConnection.on('BetEvaluationComplete', (response: any) => {
      this.betOutputMessageSubject$.next(response);
    });
  }
}
