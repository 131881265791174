import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RebetService } from 'src/app/core/services/rebet.service';

@Injectable({
  providedIn: 'root',
})
export class RebetResolver implements Resolve<any> {
  constructor(private readonly rebetService: RebetService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.rebetService.performRebet(route.params.couponCode).subscribe(() => {
      if (window.location.pathname.includes('rebet')) {
        window.history.replaceState({}, document.title, '/');
      }
    });
  }
}
