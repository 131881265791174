export const USER_DATA_KEY = 'userData';
export const F2P_USER_DATA_KEY = 'f2pUserData';
export const VERIFY_ACCOUNT_DATA_KEY = 'verifyAccountData';
export const USER_BANK_PROFILE_STATE_KEY = 'userBankProfileStateKey';
export const COUPON_DATA_KEY = 'couponData';
export const VIRTUALS_SCHEDULED_COUPON_DATA_KEY = 'virtualsCouponData';
export const LAST_PLACED_COUPON_KEY = 'lastPlacedCoupon';
export const COUPON_SETTINGS_KEY: string = 'couponSettings';
export const DEFAULT_COUPON_STAKE_KEY: string = 'defaultCouponStake';
export const ODD_CHANGES_KEY: string = 'oddChanges';
export const BOOKED_COUPONS_KEY: string = 'bookedCoupons';
export const EXPIRED_EVENTS_KEY: string = 'expiredEvents';
export const BANNER_STATE_HISTORY: string = 'bannerStateHistory';
export const BOOKING_CODE_KEY: string = 'bookingCode';
