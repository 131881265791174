import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-virtuals-instant-coupon-checkbox',
  templateUrl: './virtuals-instant-coupon-checkbox.component.html',
  styleUrls: ['./virtuals-instant-coupon-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsInstantCouponCheckboxComponent {
  private readonly INSTANT_PROCEED_TO_RESULTS = 'instantProceedToResults';
  proceedToResults: boolean = this.localStorage.retrieve(this.INSTANT_PROCEED_TO_RESULTS) || false;

  constructor(private readonly localStorage: LocalStorageService) {}

  toggleCheckbox(): void {
    this.proceedToResults = !this.proceedToResults;
    this.localStorage.store(this.INSTANT_PROCEED_TO_RESULTS, this.proceedToResults);
  }
}
