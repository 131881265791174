<div class="saved-book-bet" *ngIf="couponQuery.bookedCoupons$ | async as bookedCoupons">
  <ng-container *ngIf="withExpiredPrompt">
    <app-expired-booked-bet-prompt
    *ngIf="(this.couponQuery.bookedBetData$ | async)?.hasExpiredEvents"
      class="expired-prompt"
      [bookedBetData]="(this.couponQuery.bookedBetData$ | async)"
      (confirm)="onExpiredSubmit()"
      (cancel)="onCancel()"
    >
    </app-expired-booked-bet-prompt>
  </ng-container>
  <div class="booked-wrapper">
    <div class="label" i18n>Your booked coupons</div>
    <div class="content" *ngFor="let coupon of bookedCoupons; let i = index; trackBy: indexTrackBy;">
      <div class="booked-bet">
        <div class="bet-code-date" (click)="getBookBetData(coupon.couponCode)" i18n>
          <span class="number">{{ i + 1 }}.</span>
          <span class="code">{{ coupon.couponCode }}</span>
          <span class="divider">|</span>
          <span class="date">Booked {{ coupon.date }}</span>
        </div>
        <div class="bet-remove material-icons" (click)="removeBookedBet(coupon.couponCode)">{{ 'delete' }}</div>
      </div>
    </div>
  </div>
</div>
