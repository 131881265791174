import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  appInsights: ApplicationInsights;
  isInitialized: boolean = false;

  constructor(private readonly appConfig: AppConfigService) {}

  initialize(): void {
    const appInsightsConfig: any = this.appConfig.get('appInsights');
    if (appInsightsConfig && appInsightsConfig.enabled && appInsightsConfig.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey:
            Capacitor.getPlatform() === 'android' ? appInsightsConfig.androidInstrumentationKey : appInsightsConfig.instrumentationKey,
          samplingPercentage: appInsightsConfig.samplingPercentage,
          loggingLevelTelemetry: 1,
          autoTrackPageVisitTime: false,
          enableAutoRouteTracking: true,
        },
      });
      this.appInsights.loadAppInsights();
      // The trackPageView() call has been introduced during the Angular 13 upgrade work, however I've commented it out because
      // whilst testing it I've noticed an increase in bandwidth usage (through frequent tracking calls). We can safely enable it
      // if we get the OK from business.
      // this.appInsights.trackPageView();
      this.isInitialized = true;
    }
  }
}
