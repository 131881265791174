import { Injectable } from '@angular/core';

import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map, mergeMap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { BonusInfoModel, BonusModel, PromotionType } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import { UserType } from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  private readonly ACTIVATION_PROMPT_TITLES = {
    welcome: 'Activate Welcome Bonus?',
  };
  private readonly ACTIVATION_PROMPT_MESSAGES_BONUS = {
    welcome: {
      1: $localize`To activate the welcome bonus, you must first add your bank details`,
      2: $localize`, and you will get 200% of your first deposit in your bonus wallet once account is verified. You will only be able to withdraw these funds when you meet the bonus wagering requirements stated `,
      3: $localize`Also, note that the Bonus is valid for 30 days from the day of activation.`,
      cta: $localize`here`,
    },
  };
  private readonly ACTIVATION_PROMPT_MESSAGES = {
    welcome: {
      1: $localize`To activate the welcome bonus, you must first add your bank details`,
      2: $localize`, and you will get 200% of your first deposit in your bonus wallet once account is verified. You will only be able to withdraw these funds when you meet the bonus wagering requirements stated `,
      3: $localize`Also, note that the Bonus is valid for 30 days from the day of activation.`,
      cta: $localize`here`,
    },
  };
  private readonly bonusEnabled = this.appConfigService.get('bonusEnabled');

  constructor(
    private readonly apiService: APIService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfigService: AppConfigService,

    private readonly notificationService: NotificationService
  ) {}

  getUserBonuses(): Observable<void> {
    this.accountStore.queueLoading();

    if (!this.accountQuery.hasBonusInfo) {
      this.getBonuses().subscribe();
    }

    return this.userProgressData();
  }

  getBonuses(): Observable<void> {
    return this.apiService
      .get(
        APIType.CMS,
        `SiteRoot/GetPromotions?userType=${
          this.accountQuery.isAuthenticated
            ? this.accountQuery.userData.userTypeCode === UserType.ShopOwner ||
              this.accountQuery.userData.userTypeCode === UserType.Master ||
              this.accountQuery.userData.userTypeCode === UserType.SuperAgent
              ? 'Agent'
              : 'User'
            : 'User'
        }`
      )
      .pipe(
        first(),
        map((bonusInfoData: any[]) => {
          this.accountStore.updateBonusInfo(bonusInfoData.map(bonusInfo => this.mapBonusInfoToModel(bonusInfo)));
        })
      );
  }

  getBonus(id: number): void {
    this.accountStore.updateViewingBonus(this.accountQuery.getBonus(id));
  }

  clearViewingBonus(): void {
    this.accountStore.updateViewingBonus(undefined);
  }

  activateBonus(id: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.accountStore.queueLoading();

    return this.apiService.post(APIType.Website, 'api/Bonus/Claims/Activate', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.dequeueLoading();
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (!responseData || responseData.ErrorCode) {
          this.accountStore.setError('Bonus Activation Failed');
        }
      }),
      catchError(err => {
        if (err?.error?.ErrorCode === 'BONUS_CLAIM_ACTIVATION_ISSUES') {
          // populate the eligibility details in case activation failed due to verification issues, since this
          // is currently the only API endpoint that provides this data
          this.accountStore.updateBonusEligibility({
            bankTransferDetailsStatus: err.error.ErrorDetails?.BankTransferDetails,
            bankTransferDetailsVerified: err.error.ErrorDetails?.BankTransferDetails === undefined ? true : false,
            userKYCStatus: err.error.ErrorDetails?.UserKYC,
            userKYCVerified: err.error.ErrorDetails?.UserKYC === undefined ? true : false,
          });

          this.accountStore.setError(err);
          return throwError(err);
        }
      })
    );
  }

  pauseBonus(id: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.accountStore.queueLoading();

    return this.apiService.post(APIType.Website, 'api/Bonus/Claims/Pause', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.dequeueLoading();
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (responseData.ErrorCode) {
          this.accountStore.setError('Pausing of Bonus Failed');
        }
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  resumeBonus(id: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.accountStore.queueLoading();

    return this.apiService.post(APIType.Website, 'api/Bonus/Claims/UnPause', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.dequeueLoading();
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (responseData.ErrorCode) {
          this.accountStore.setError('Failed to Resume Bonus');
        }
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  clearError(): void {
    this.accountStore.setError(undefined);
  }

  updateViewingActive(isViewingActive: boolean): void {
    this.accountStore.updateBonusUI({ isViewingActive });
  }

  updateViewingPaused(isViewingPaused: boolean): void {
    this.accountStore.updateBonusUI({ isViewingPaused });
  }

  updateViewingInactive(isViewingInactive: boolean): void {
    this.accountStore.updateBonusUI({ isViewingInactive });
  }

  updateViewingPrevious(isViewingPrevious: boolean): void {
    this.accountStore.updateBonusUI({ isViewingPrevious });
  }

  updateViewingMissed(isViewingMissed: boolean): void {
    this.accountStore.updateBonusUI({ isViewingMissed });
  }

  resetBonusUI(): void {
    this.accountStore.updateBonusUI({
      isViewingActive: false,
      isViewingPaused: false,
      isViewingInactive: false,
      isViewingPrevious: false,
      isViewingMissed: false,
    });
  }

  clearUserBonuses(): void {
    this.accountStore.updateBonuses(undefined);
  }

  showActivationPrompt(id: number, confirmCallback: Function, cancelCallback: Function): void {
    switch (this.accountQuery.getBonusType(id)) {
      case PromotionType.Welcome:
        this.showWelcomeBonusActivationPrompt(this.accountQuery.getBonusAdditionalInfoURL(id), confirmCallback, cancelCallback);
        if (this.bonusEnabled) {
          document.querySelector('.close-more-info').addEventListener('click', event => {
            this.moreInfo(id);
            document.querySelector('.swal2-container').remove();
            document.querySelector('html').className = '';
            document.querySelector('body').className = '';
          });
        }
        break;
      case PromotionType.Other:
      default:
        // No need for this for now. Just perform action
        confirmCallback();
        break;
    }
  }
  getClaims() {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    return this.apiService.get(APIType.Website, 'api/Bonus/Claims/', apiSettings).pipe(
      map(response => {
        return response;
      })
    );
  }
  private userProgressData(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    return this.apiService.get(APIType.Website, 'api/Bonus/Claims/', apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.dequeueLoading();
      }),
      mergeMap(responseBonusData => {
        if (responseBonusData) {
          if (responseBonusData.length > 0) {
            const apiCalls: any[] = [];
            const bonuses: BonusModel[] = [];
            this.resetBonusUI();
            responseBonusData.forEach(bonus => {
              bonuses.push(this.mapBonusDataToModel(bonus));

              if (bonus.IsActivated === false && bonus.IsExpired === false && bonus.IsCancelled === false) {
                this.updateViewingInactive(true);
              }
              if (bonus.IsActivated === true && bonus.IsPaused === false && bonus.IsExpired === false && bonus.IsCancelled === false) {
                this.updateViewingActive(true);
              }
              if (bonus.IsActivated && bonus.IsPaused === true && bonus.IsExpired === false && bonus.IsCancelled === false) {
                this.updateViewingPaused(true);
              }

              if (bonus.IsActivated) {
                // Only poll for progress if bonus is activated
                apiCalls.push(this.apiService.get(APIType.Website, `api/Bonus/Claims/${bonus.Id}/Progress`, apiSettings));
              }
            });

            if (apiCalls.length) {
              return forkJoin(apiCalls).pipe(
                first(),
                finalize(() => {
                  this.accountStore.updateBonuses(bonuses);
                }),
                map(responseProgressData => {
                  if (responseProgressData) {
                    responseProgressData.forEach((progressData: any) => {
                      if (progressData) {
                        this.mapProgressDataToModel(
                          bonuses.find(bonus => bonus.id === progressData.BonusClaimId),
                          progressData
                        );
                      }
                    });
                  }
                })
              );
            } else {
              this.accountStore.updateBonuses(bonuses);
            }
          }
        }

        return new Observable<void>();
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  private mapBonusDataToModel(bonus: any): BonusModel {
    return new BonusModel({
      canPause: bonus.CanPauseClaim,
      expirationDate: bonus.ExpiresOn,
      id: bonus.Id,
      isActivated: bonus.IsActivated,
      isCancelled: bonus.IsCancelled,
      isExpired: bonus.IsExpired,
      isPaused: bonus.IsPaused,
      bonusCode: parseInt(bonus.TermsCode, 10),
    });
  }

  private mapBonusInfoToModel(promoInfo: any): BonusInfoModel {
    return {
      bonusCode: promoInfo.promotionCode,
      name: promoInfo.promotionTitle,
      imageURL: promoInfo.promotionImageURL,
      summary: promoInfo.promotionSummary,
      contentTitle: promoInfo.promotionContentTitle,
      content: promoInfo.promotionContent,
      type: promoInfo.promotionType,
      additionalInfoURL: promoInfo.additionalInfoURL,
    };
  }

  private mapProgressDataToModel(bonus: BonusModel, progress: any): void {
    bonus.currentProgress = progress.CurrentContributionAmount;
    bonus.nextReleaseAmount = progress.NextReleaseContributionAmount;
    bonus.isBonusTransferPossible = progress.IsBonusTransferPossible;
  }

  moreInfo(id: number) {
    this.getBonus(id);
    this.resetBonusUI();
  }

  private showWelcomeBonusActivationPrompt(additionalInfoURL: string, confirmCallback: Function, cancelCallback: Function): void {
    if (this.bonusEnabled) {
      this.notificationService.showCustomNotification(
        `<p>${this.ACTIVATION_PROMPT_MESSAGES_BONUS.welcome[1]}
          <a href="/account/bank-profile">${this.ACTIVATION_PROMPT_MESSAGES_BONUS.welcome.cta}</a>${this.ACTIVATION_PROMPT_MESSAGES_BONUS.welcome[2]}<a href="javascript:void(0)" class='close-more-info'>${this.ACTIVATION_PROMPT_MESSAGES.welcome.cta}</a></p>
      <p>${this.ACTIVATION_PROMPT_MESSAGES_BONUS.welcome[3]}</p>`,
        'info',
        confirmCallback,
        $localize`Proceed`,
        this.ACTIVATION_PROMPT_TITLES.welcome,
        cancelCallback,
        $localize`Cancel`
      );
    }
    if (!this.bonusEnabled) {
      this.notificationService.showCustomNotification(
        `<p>${this.ACTIVATION_PROMPT_MESSAGES.welcome[1]}&nbsp;
            <a href="${additionalInfoURL ? additionalInfoURL : '/help'}">
              ${this.ACTIVATION_PROMPT_MESSAGES.welcome.cta}
            </a></p>
        <p>${this.ACTIVATION_PROMPT_MESSAGES.welcome[2]}</p>`,
        'info',
        confirmCallback,
        $localize`Proceed`,
        this.ACTIVATION_PROMPT_TITLES.welcome,
        cancelCallback,
        $localize`Cancel`
      );
    }
  }
}
