import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CasinoXSellSwiperComponent } from 'src/app/modules/x-sell/components/casino-x-sell-swiper/casino-x-sell-swiper.component';
import { XSellSwiperComponent } from 'src/app/modules/x-sell/components/swiper/x-sell-swiper.component';
import { XSellSwipeableDirective } from 'src/app/modules/x-sell/directives/x-sell-swipeable.directive';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [CasinoXSellSwiperComponent, XSellSwiperComponent, XSellSwipeableDirective],
  exports: [CasinoXSellSwiperComponent],
})
export class XSellModule {}
