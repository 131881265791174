<div class="multi-line-odds">
  <div class="spread-wrapper" *ngFor="let spread of spreadMap | keyvalue" [class.with-spread-labels]="showSpreadLabels">
    <div class="spread-label" *ngIf="showSpreadLabels">
      {{ spread.key }}
    </div>
    <div class="odds" *ngFor="let odd of spread.value; trackBy: oddTrackBy">
      <div class="label">
        {{ odd.selectionName }}
      </div>
      <app-odd [odd]="odd" eventLocation="Match_info"></app-odd>
    </div>
  </div>
</div>
