<div class="sports-container" *ngIf="sportQuery.sportsList$ | async as sports">
  <div class="search-field" [@fadeInBy]="{ value: '', params: { duration: 150 } }" *ngIf="sports.length >= 15">
    <div class="input-container">
      <input type="text" [formControl]="searchFieldSports" i18n-placeholder placeholder="Input your search" maxlength="30" />
      <i class="fa fa-search"></i>
    </div>
  </div>
  <ng-container *ngIf="favourites$ | async as favouriteSports">
    <app-sport-list
      *ngIf="favouriteSports.length > 0"
      [sports]="favouriteSports"
      i18n-title
      title="Favourites"
      [selectedSport]="selectedSport$ | async"
    ></app-sport-list>
  </ng-container>
  <app-sport-list [sports]="allSports$ | async" [selectedSport]="selectedSport$ | async"> </app-sport-list>
</div>
