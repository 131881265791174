import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownModel, DropdownOptionModel } from 'src/app/shared/models/dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit {
  @Input() dropdownModel: string;
  @Input() disabled: boolean;
  @Input() options: DropdownOptionModel[];
  @Output() readonly dropdownChange = new EventEmitter();
  dropdownSettings: DropdownModel;

  ngOnInit(): void {
    this.dropdownSettings = new DropdownModel({
      options: this.options,
    });
  }

  onChange(): void {
    this.dropdownChange.emit();
  }

  dropdownTrackBy(index: number, item: DropdownOptionModel): string {
    return item.value;
  }
}
