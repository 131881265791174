import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { ToastNotification } from 'src/app/shared/models/notification.model';
import { MenuTabModel } from './menu.model';
import { ProductType, VirtualsLeagueType } from './product.model';

export enum LoginState {
  Login,
  PasswordRecovery,
}

export interface LoaderState {
  /** Controls the full screen loader */
  show: boolean;
  text: string;
  isFullscreen: boolean;
}

export interface MenuMethodCalls {
  loadLiveChat(): void;
  openBetSlip(): void;
}

export class ApplicationState {
  brandId: number;
  ui: ApplicationUIState;
  sideBarQuickLinks: MenuItemModel[];
  sideBarMenu: MenuTabModel[];
  activeUrl: string[];
  siteMessages: ApplicationSiteMessagesState;
  cms: CmsModel;
  selectedLanguage: LanguageModel;
  activeProduct: ProductType;
  activeVirtualsLeague: VirtualsLeagueType;
  embedConfig: EmbedConfig;

  constructor(init: Partial<ApplicationState>) {
    Object.assign(this, init);
  }
}

export class CmsModel {
  cacheTTL: number;
  fallbackTimer: number;
  lastQuicklinksUpdate: number;
  lastSportsBannerUpdate: number;
  lastPaymentsBannerUpdate: number;
  lastLandingPageMenuItemsUpdate: number;
  lastLandingPageWidgetsUpdate: number;
  lastSidebarQuicklinksUpdate: number;
  lastLivePageMarketGroupingDetailsUpdate: number;
  lastUnreadMessagesUpdate: number;

  constructor(init: Partial<CmsModel>) {
    Object.assign(this, init);
  }
}

export class LanguageModel {
  language: string;
  languageDescription: string;
  locale: string;

  constructor(init: LanguageModel) {
    Object.assign(this, init);
  }
}

export class ApplicationSiteMessagesState {
  landing: ToastNotification[];
  sports: ToastNotification[];
}

export class ApplicationUIState {
  inCoupon: boolean;
  inMyBets: boolean;
  isShowingAccumulatorBonusUnacceptableSelectionsPrompt: boolean;
  isShowingNavbarBetslipAccumulatorBonusBar: boolean;
  isShowingNavbarBetslipSelections: boolean;
  isSportsSection: boolean;
  isVirtuals: boolean;
  isInstant: boolean;
  isZohoLiveChatLoading: boolean;
  landingMenuExpanded: boolean;
  loader: LoaderState;
  loadingQuicklinks: boolean;
  loadingSFKChat: boolean;
  loginState: 'Login' | 'Password Recovery';
  scrollY: number;
  showAccountMenu: boolean;
  showCoupon: boolean;
  showDropdownOverlay: boolean;
  showLogin: boolean;
  showLoginDialog: boolean;
  showMenu: boolean;
  showMyBets: boolean;
  showNavbar: boolean;
  showQuickCoupon: boolean;
  showSFKChat: boolean;

  constructor(init: Partial<ApplicationUIState>) {
    Object.assign(this, init);
  }
}

export interface EmbedConfig {
  hideHeader: boolean;
  hideBreadcrumbs: boolean;
  hideNavbar: boolean;
  hideSEOFooter: boolean;
  disableRouteChanges: boolean;
  routeChangeWhitelist: string[];
}
