import { Injectable } from '@angular/core';
import { EntityStore, ID, StoreConfig } from '@datorama/akita';
import { ToastNotification, ToastNotificationState } from 'src/app/shared/models/notification.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'toast' })
export class ToastNotificationStore extends EntityStore<ToastNotificationState> {
  constructor() {
    super();
  }

  addToList(toast: ToastNotification): void {
    this.add(toast);
  }

  removeFromListById(id: ID): void {
    this.remove(id);
  }
}
