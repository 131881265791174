import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';

@Directive({
  selector: '[appAdjustTabOnSelected]',
})
export class AdjustTabOnSelectedDirective implements AfterViewInit, AfterViewChecked {
  @Input() manuallyAdjustTab: boolean = false; // this input is used in Live Betting since it refreshes frequently

  private readonly el: ElementRef;

  constructor(element: ElementRef) {
    this.el = element;
  }

  ngAfterViewInit(): void {
    this.adjustTab();
  }

  ngAfterViewChecked(): void {
    if (!this.manuallyAdjustTab) {
      this.adjustTab();
    }
  }

  adjustTab(): void {
    setTimeout(() => {
      const selectedTab = Array.from(this.el.nativeElement.children).find((ele: HTMLElement) =>
        ele.classList.contains('selected')
      ) as HTMLElement;
      if (selectedTab) {
        const scrollValue =
          parseInt(this.el.nativeElement.scrollLeft, 10) +
          selectedTab.getBoundingClientRect().left -
          (window.innerWidth / 2 - selectedTab.clientWidth / 2);

        smoothScrollTo(this.el.nativeElement, 'left', scrollValue);
      }
    }, 0);
  }
}
