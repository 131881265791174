import { Pipe, PipeTransform } from '@angular/core';
import { OddModel, OddsByMarket } from 'src/app/shared/models/coupon.model';

@Pipe({
  name: 'groupOddsByMarket',
})
export class GroupOddsByMarketPipe implements PipeTransform {
  transform(odds: OddModel[]): OddsByMarket[] {
    const oddsByMarket: OddsByMarket[] = [];

    for (const odd of odds) {
      let market: OddsByMarket = oddsByMarket.find(m => m.marketId === odd.marketId);

      if (!market) {
        market = {
          marketId: odd.marketId,
          marketName: odd.marketName,
          odds: [],
        };

        oddsByMarket.push(market);
      }

      market.odds.push(odd);
    }

    return oddsByMarket.sort((a, b) => (a.marketName > b.marketName ? 1 : -1));
  }
}
