import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FreeBetProductType, FreebetsVoucher } from 'src/app/modules/freebets/models/freebets.model';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { VirtualsScheduledFreeBetService } from 'src/app/modules/freebets/services/virtuals-scheduled-free-bet.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { dataLayerFreeBetCategory, dataLayerFreeBetProduct } from 'src/app/shared/utils/data-layer-product-map';

@Component({
  selector: 'freebets-list-modal',
  templateUrl: './freebets-list-modal.component.html',
  styleUrls: ['./freebets-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreebetsListModalComponent implements OnInit {
  @Input() show = false;
  @Input() readonly productType: FreeBetProductType;
  @Output() readonly closeModal = new EventEmitter();

  readonly modalContent$ = this.freebetQuery.freeBetsModalContent$;
  freebetVouchers$;

  constructor(
    private readonly freebetsService: SportsbookFreeBetService,
    private readonly virtualsScheduledFreebetsService: VirtualsScheduledFreeBetService,
    private readonly dataLayerService: DataLayerService,
    private readonly freebetQuery: FreebetsQuery,
    private readonly accountQuery: AccountQuery
  ) {}

  ngOnInit() {
    this.freebetVouchers$ = this.productType && this.freebetQuery.availableFreebetVouchers$(this.productType);
  }

  voucherTrackBy(_index: number, value: FreebetsVoucher) {
    return value.code;
  }

  handleCloseModal(): void {
    this.closeModal.emit();
  }

  handleClickToggle(code: string): void {
    if (this.productType === FreeBetProductType.SportsBook) {
      this.freebetsService.toggleFreebetVoucherUseInCoupon(code);
    }

    if (this.productType === FreeBetProductType.VirtualsScheduled) {
      this.virtualsScheduledFreebetsService.toggleFreebetVoucherUseInCoupon(code);
    }

    this.dataLayerService.createDataLayerEvent({
      event: 'free_bet_selected',
      userId: this.accountQuery.userData?.id,
      product: dataLayerFreeBetProduct(this.productType),
      category: dataLayerFreeBetCategory(this.productType),
      sub_category: this.productType,
    });
  }
}
