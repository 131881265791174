import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appNavBarSvgLoader]',
})
export class NavBarSvgLoaderDirective implements AfterViewInit, OnChanges {
  @Input() appNavBarSvgLoader: string;
  @Input() isSelected: boolean; // optional

  /**
   * Could not use Image() due to that type not being available in SSR.
   * We are only using src, so this can be declared as follows.
   */
  logo: HTMLImageElement = { src: undefined } as any as HTMLImageElement;
  selectedLogo: HTMLImageElement = { src: undefined } as any as HTMLImageElement;

  constructor(public elementRef: ElementRef, readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundSize', 'contain');
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundRepeat', 'no-repeat');
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundPosition', 'center');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const iconChange = changes.appNavBarSvgLoader;
    const isSelectedChange = changes.isSelected;

    if (this.isSelected === undefined) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundImage', `url('./assets/${this.appNavBarSvgLoader}.svg')`);
    } else {
      if ((isSelectedChange && isSelectedChange.firstChange) || iconChange) {
        this.selectedLogo.src = `./assets/${this.appNavBarSvgLoader}-selected.svg`;
        this.logo.src = `./assets/${this.appNavBarSvgLoader}-unselected.svg`;
      }

      if (!this.isSelected) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundImage', `url(${this.logo.src})`);
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundImage', `url(${this.selectedLogo.src})`);
      }
    }
  }
}
