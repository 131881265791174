<ng-lottie [options]="circleLoaderSuccess"></ng-lottie>
<h1>
  {{  content ? content.header : '' }}
</h1>
<div class="message" [innerHTML]="(content ? content.bodyHTML : '') | safeHTML"></div>
<app-button
  [text]="content && content.ctaText ? content.ctaText: 'Proceed'"
  [customButtonStyle]="ctaAdditionalStyles"
  (click)="proceedClicked()"
></app-button>
