import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { ProductType } from 'src/app/shared/models/product.model';

@Component({
  selector: 'my-bets-booked-bets',
  templateUrl: './booked-bets.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookedBetsComponent {
  readonly betsTab = BetsTab;
  readonly productTab = ProductType;

  constructor(readonly couponQuery: CouponQuery) {}
}
