export class UserProfilesModel {
  id: number;
  bankCode: string;
  accountNumber: string;
  accountHolderName: string;
  sortCode: string;
  swiftCode: string;
  routingNumber: string;
  iBANCode: string;
  default: boolean;
  profileEnabled: boolean;
  createdOn: Date;
  updatedOn: Date;
  verificationStatusID: number;
  verificationStatusCode: string;
  declineReason: string;
  statusChangedDate: string;
  statusChangedBy: string;
  imageRef: string;
  class: string;
  state: string;

  constructor(init: Partial<UserProfilesModel>) {
    Object.assign(this, init);
  }
}

export class BankListModel {
  Id: number;
  Name: string;
  BankCode: string;

  constructor(init: Partial<BankListModel>) {
    Object.assign(this, init);
  }
}
