<div class="menu-tabs-container with-indicator item-count-{{(applicationQuery.sideBarMenu$ | async).length}}">
  <div *ngFor="let tab of applicationQuery.sideBarMenu$ | async" class="menu-tab" (click)="changeTab(tab.type)"
    [ngClass]="{ selected: (menuQuery.selectedTab$ | async) === tab.type }">
    <div class="icon" [appDynamicSvgMask]="'images/menu-icons/' + menuTabsIcons[tab.type]"
      [ngClass]="{ selected: (menuQuery.selectedTab$ | async) === tab.type }"></div>
    <div class="title" [ngClass]="{ selected: (menuQuery.selectedTab$ | async) === tab.type }">{{ tab.title }}
    </div>
    <div class="ribbon-container">
      <div class="ribbon" *ngIf="tab.isNew" i18n>NEW!</div>
    </div>
  </div>
</div>
