import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Subject, throwError } from 'rxjs';
import { catchError, filter, first, takeUntil, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { VirtualsJackpotBanner, VirtualsJackpotBannerParentType, VirtualsLobbyGame } from 'src/app/shared/models/virtuals.model';
import { LoadingService } from 'src/app/core/services/loading.service';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { CookieService } from 'src/app/core/services/cookie.service';
import { DataLayerEvent } from 'src/app/shared/models/datalayer.model';

@Component({
  selector: 'virtuals-game-lobby',
  templateUrl: './virtuals-game-lobby.component.html',
  styleUrls: ['./virtuals-game-lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsGameLobbyComponent implements OnInit, OnDestroy {
  @Input() showPageTitle: boolean = true;
  @Input() showInfoBubble: boolean = true;
  @Input() showHeader: boolean = true;

  readonly displayJackpotBanner$ = new BehaviorSubject<boolean>(false);
  readonly jackpotBanner$ = new BehaviorSubject<VirtualsJackpotBanner>({
    visibility: {
      lobby: false,
      games: false,
      scheduledLeagues: false,
      instantLeagues: false,
    },
    bannerMessage: '',
    latestJackpotUrl: '',
    getJackpotValuesInterval: 0,
  });
  private readonly requestedGameCode: string = ''; // Store the code of the game which the user wants to load(for games that require login)
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly virtualsQuery: VirtualsQuery,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly applicationService: ApplicationService,
    private readonly authenticationService: AuthenticationService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly virtualsInstantService: VirtualsInstantService,
    private readonly virtualsService: VirtualsService,
    private readonly virtualsStore: VirtualsStore,
    private readonly cookieService: CookieService
  ) {
    this.requestedGameCode = this.router.getCurrentNavigation()?.extras?.state?.route.split('/').pop().split('?')[0];
  }

  ngOnInit(): void {
    this.requestedGameCode && this.loadingService.enqueueLoader();
    this.virtualsService.getBreadcrumbNavigation().subscribe();
    this.virtualsService.getLobbyInfoSections().subscribe();

    this.virtualsService
      .getJackpotBanner()
      .pipe(takeUntil(this.destroy$))
      .subscribe(jackpotBanner => {
        if (jackpotBanner.visibility[VirtualsJackpotBannerParentType.Lobby]) {
          this.displayJackpotBanner$.next(true);
          this.jackpotBanner$.next(jackpotBanner);
        }
      });

    forkJoin([this.accountService.refreshEnabledGameProviders(), this.virtualsService.getLobbyContent(true)])
      .pipe(
        catchError(error => {
          this.loadingService.dequeueLoader();
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getCategoryEventsTimings();
        this.getTournamentsEventsTimings();
        if (this.requestedGameCode) {
          this.loadingService.dequeueLoader();
          const requestedGame = this.virtualsQuery.lobbyGames?.find(game => game.code === this.requestedGameCode);
          if (requestedGame) {
            this.openGame(requestedGame);
          }
        }
      });
  }

  isGameDisabled(game: VirtualsLobbyGame): boolean {
    const virtualsConfig = this.appConfig.get('virtuals');
    const scheduledLeaguesDisabled = game.gameType === 'scheduled-league' && !virtualsConfig.scheduledLeague?.enabled;
    const instantLeaguesDisabled = game.gameType === 'instant-league' && !virtualsConfig.instantLeague?.enabled;
    return !!(
      !game.isEnabled ||
      scheduledLeaguesDisabled ||
      instantLeaguesDisabled ||
      this.virtualsService.isEndpointDisabledForUser(game.code)
    );
  }

  openGame(game: VirtualsLobbyGame): void {
    if (this.isGameDisabled(game)) {
      this.notificationService.showInfoNotification($localize`Please check back later`, $localize`Coming Soon`);
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      // validate if the token is still valid
      game.gameType === 'instant-league' ? this.loginUserToGRBO(game.code, game.gameType) : this.validateToken(game);
    } else {
      if (game.showLoginDialog) {
        this.applicationService.setShowLoginDialog(true);
        this.applicationQuery.showLoginDialog$
          .pipe(
            filter(state => !state),
            tap(() => this.loginDialogClosed(game.code, game.gameType)),
            takeUntil(this.destroy$)
          )
          .subscribe();
      } else {
        this.navigateToGame(game.code, game.gameType);
      }
    }
  }

  ctaClick(ctaClickUrl: string): void {
    this.navigateToUrl(ctaClickUrl);
  }

  refreshEventTiming(gameType: string): void {
    if (gameType !== 'tournaments') {
      this.getCategoryEventsTimings();
    } else {
      this.getTournamentsEventsTimings();
    }
  }

  private formatTimeValue(value: number, singularSuffix: string, pluralSuffix: string): string {
    return `${value} ${value > 1 ? pluralSuffix : singularSuffix}`;
  }

  private getCategoryEventsTimings(): void {
    const categoryIds: (number | string)[] = this.virtualsQuery.lobbyGames
      ?.filter(game => game.gameType !== 'tournaments' && game.isEnabled && game.showCountdown && game.providerId)
      .map(game => game.providerId);

    if (categoryIds?.length) {
      this.virtualsService.getCategoryEventsTimings(categoryIds).pipe(takeUntil(this.destroy$)).subscribe();
    } else {
      this.virtualsStore.clearCategoryEventsTimings();
    }
  }

  private getTournamentsEventsTimings(): void {
    const categoryIds: string[] = this.virtualsQuery.lobbyGames
      ?.filter(game => game.gameType === 'tournaments' && game.isEnabled && game.showCountdown && game.providerId)
      .map(game => game.providerId)
      .filter(Boolean); // remove falsy values

    if (categoryIds?.length) {
      from(categoryIds)
        .pipe(
          switchMap(categoryId => this.virtualsService.getTournamentsEventsTimings(categoryId)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  private navigateToUrl(url: string): void {
    if (url) {
      const urlRegex = /^https?:\/\//i;
      if (urlRegex.test(url)) {
        // absolute url
        window.location.href = url;
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  private loginDialogClosed(gameCode: string, gameType: string): void {
    if (this.accountQuery.isAuthenticated) {
      this.loginUserToGRBO(gameCode, gameType);
    }
  }

  private validateToken(game: VirtualsLobbyGame): void {
    this.authenticationService
      .validateToken(this.accountQuery.accessToken)
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data && data.isValidated === false) {
            // if token is invalid, logout the user
            this.accountService.clearUserData();

            // show the login dialog if required
            if (game.showLoginDialog) {
              this.showLogin(game.code, game.gameType);
            } else {
              this.navigateToGame(game.code, game.gameType);
            }
          } else {
            // if token is valid or no data was returned, proceed to the game
            this.navigateToGame(game.code, game.gameType);
          }
        },
        () => {
          // if an error occurs during token validation, proceed to the game with the current user details
          this.navigateToGame(game.code, game.gameType);
        }
      );
  }

  private showLogin(gameCode: string, gameType: string): void {
    if (this.appConfig.get('virtuals')?.showBetslipLoginDialog) {
      this.applicationService.setShowLoginDialog(true);
      this.applicationQuery.showLoginDialog$
        .pipe(
          filter(state => !state),
          tap(() => this.loginDialogClosed(gameCode, gameType)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.notificationService.showInfoNotification($localize`Please log into the site`, $localize`Login Required`);
    }
  }

  private loginUserToGRBO(gameCode: string, gameType: string) {
    const uniqueUserIdentifier: string = Math.random().toString().substring(7);
    this.cookieService.setCookie('uniqueInstaUserIdentifier', uniqueUserIdentifier);

    this.createDataLayerEvent('user-requested-instant-game', gameCode, uniqueUserIdentifier);
    this.loadingService.enqueueLoader();
    this.loadingService.updateFullscreen(true);
    this.virtualsInstantService
      .loginToGR()
      .pipe(
        catchError(err => {
          this.loadingService.dequeueLoader();
          return throwError(err.message);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.loadingService.dequeueLoader();
        this.createDataLayerEvent('user-retrieved-onlineHash', gameCode, uniqueUserIdentifier);
        this.navigateToGame(gameCode, gameType);
      });
  }

  private navigateToGame(gameCode: string, gameType: string): void {
    this.router.navigate(
      gameType === 'tournaments' ? [this.appConfig.get('virtuals')?.tournaments.mountingPoint, gameCode] : ['/virtual', gameCode]
    );
  }

  private createDataLayerEvent(eventName: string, gameCode: string, uniqueUserIdentifier: string): void {
    const event: DataLayerEvent = {
      event: eventName,
    };
    event.uniqueUserIdentifier = uniqueUserIdentifier;
    event.gameCode = gameCode;
    this.virtualsInstantService.createDataLayerEvent(event);
  }

  ngOnDestroy(): void {
    this.virtualsStore.clearCategoryEventsTimings();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
