import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDropdownArrowRotator]',
})
export class DropdownArrowRotatorDirective implements AfterViewChecked {
  @Input() appDropdownArrowRotator: boolean;
  @Input() rotateDegrees: number = 90;
  @Input() openAndCloseColors: string[];

  constructor(public elementRef: ElementRef, readonly renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    const degree = this.appDropdownArrowRotator ? this.rotateDegrees : 0;
    let color;
    if (this.openAndCloseColors) {
      this.openAndCloseColors.length === 1
        ? (color = this.openAndCloseColors[0])
        : (color = this.appDropdownArrowRotator ? this.openAndCloseColors[0] : this.openAndCloseColors[1]);
    }
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', '0.3s');
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `rotate(${degree}deg)`);

    if (color) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', color);
    } else {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'color');
    }
  }
}
