import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { XSellStore } from 'src/app/modules/x-sell/state/x-sell.store';
import { APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class XSellCMSAPIService {
  constructor(private readonly apiService: APIService, private readonly xSellStore: XSellStore) {}

  getCasinoXSellAccessConfiguration(language = 'en-us'): Observable<boolean> {
    return this.apiService.get(APIType.CMS, `XSell/Casino/XSellAccessConfiguration?language=${language}`).pipe(
      tap(res => {
        this.xSellStore.setCasinoAccessConfiguration(res);
      }),
      map(() => true) // Marks call as done
    );
  }

  getCasinoXSellContent(language = 'en-us'): Observable<boolean> {
    return this.apiService.get(APIType.CMS, `XSell/Casino/XSellContent?language=${language}`).pipe(
      tap(res => {
        this.xSellStore.setCasinoContent(res);
      }),
      map(() => true) // Marks call as done
    );
  }
}
