<div class="toast-notification-container">
  <div *ngFor="let toast of toastNotificationQuery.list$ | async; trackBy: toastTrackBy">
    <div class="toast" [class.info]="toast.priority === toastType.Info" [@fadeIn] (click)="closeToast(toast.id)"
      [class.error]="toast.priority === toastType.Error" [class.warning]="toast.priority === toastType.Warning"
      [class.critical]="toast.priority === toastType.Critical" [class.success]="toast.priority === toastType.Success">
      <div class="message">{{toast.message}}</div>
      <div class="fa fa-times close-button"></div>
    </div>
  </div>
</div>
