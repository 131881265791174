import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { HelpSection, HelpState } from 'src/app/shared/models/help.model';

function createInitialState(): HelpState {
  return {
    sections: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'help' })
export class HelpStore extends Store<HelpState> {
  constructor() {
    super(createInitialState());
  }

  setHelp(sections: HelpSection[]): void {
    this.update({ sections });
  }
}
