export class CountryModel {
  twoLetterCode: string;
  threeLetterCode: string;
  name: string;

  constructor(init: Partial<CountryModel>) {
    Object.assign(this, init);
  }
}

export class StateModel {
  code: string;
  name: string;

  constructor(init: StateModel) {
    Object.assign(this, init);
  }
}

export class TelephoneExtensionModel {
  countryCode: string;
  prefixNumber: string;

  constructor(init: TelephoneExtensionModel) {
    Object.assign(this, init);
  }
}

export class CityModel {
  id: string;
  name: string;

  constructor(init: CityModel) {
    Object.assign(this, init);
  }
}

export class IdTypeModel {
  id: string;
  name: string;
  code: string;

  constructor(init: IdTypeModel) {
    Object.assign(this, init);
  }
}
