import { MetaSettings } from './models/meta-settings.model';
import { PageTitlePositioning } from './models/page-title-positioning.model';

export abstract class MetaLoader {
  abstract get settings(): MetaSettings;
}

export class MetaStaticLoader implements MetaLoader {
  constructor(
    private readonly providedSettings: MetaSettings = {
      pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
      defaults: {},
    }
  ) {}

  get settings(): MetaSettings {
    return this.providedSettings;
  }
}
