import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import {
  LinkF2PAccountResponseCodes,
  LinkF2PAccountResponseModel,
  LoginResponseModel,
  UserModel,
} from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class AccountF2PService {
  constructor(
    private readonly apiService: APIService,
    private readonly authService: AuthenticationService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService
  ) {}

  getF2PUserData(username: string, accessToken: string): Observable<LoginResponseModel> {
    return this.getF2PUserProfile(accessToken).pipe(
      map(profileData => {
        if (!profileData) {
          return new LoginResponseModel({ success: false, errorMessage: $localize`User data not found` });
        }

        const userData = this.parseF2PUserData(accessToken, username, profileData);
        if (!userData) {
          return new LoginResponseModel({ success: false, errorMessage: $localize`Invalid user data` });
        }
        this.accountStore.updateF2PUserData(userData);
        return new LoginResponseModel({ success: true, loginMessages: undefined });
      })
    );
  }

  f2pLogout(): Observable<boolean> {
    const username = this.accountQuery.f2pUserData.username.toString();
    return this.authService.revokeF2PToken(this.accountQuery.f2pUserData.accessToken).pipe(
      map(() => {
        // remove user from local storage
        this.accountStore.clearF2PUserData();
        return true;
      }),
      tap(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'f2p-user-logout',
          username,
        });
      })
    );
  }

  linkToF2PAccount(bkAccessToken: string): Observable<LinkF2PAccountResponseModel> {
    this.accountStore.queueLoading();
    if (this.accountQuery.f2pUserData && bkAccessToken) {
      return this.apiService
        .post(
          APIType.Website,
          '/api/User/Profile/LinkF2PAccountWithUnverifiedMobileNumberAccount',
          {
            Token: this.accountQuery.f2pUserData.accessToken,
          },
          new APISettings({
            forceAuthToken: bkAccessToken,
          })
        )
        .pipe(
          map(response => ({
            success: true,
            responseCode: LinkF2PAccountResponseCodes.OK,
          })),
          finalize(() => {
            this.accountStore.dequeueLoading();
          }),
          catchError(err => {
            throwError(err);
            return of({
              success: false,
              responseCode: LinkF2PAccountResponseCodes.ServerError,
            });
          })
        );
    } else {
      of({
        success: false,
        responseCode: LinkF2PAccountResponseCodes.ApplicationError,
      });
    }
  }

  private getF2PUserProfile(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    return this.apiService.post<any>(
      APIType.Website,
      'api/User/Profile/Agents/GetF2PUserBasicDetails',
      {
        token: accessToken,
      },
      new APISettings({
        forceAuthToken: accessToken,
        contentType: 'application/json',
      })
    );
  }

  private parseF2PUserData(accessToken: string, username: string, profileData: any): UserModel {
    try {
      return new UserModel({
        name: profileData.FirstName,
        surname: profileData.LastName,
        username,
        accessToken: accessToken,
      });
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
