<div class="jackpot-banner-item" data-testid="jackpot-banner">
  <div class="jackpot-banner-item-jackpot">
    <span class="material-icons jackpot-banner-item-subtext-icon" (click)="navigateToLatestJackpots()"
          data-testid="jackpot-banner-info-btn" *ngIf="inGameBanner">{{ 'info' }}</span>
    <div class="jackpot-banner-item-jackpot-info" data-testid="jackpot-banner-info">
      <span class="jackpot-banner-item-jackpot-info-drop" data-testid="jackpot-banner-drop-info">{{ jackpotDropInfo }}</span>
      <span class="jackpot-banner-item-jackpot-info-amount" data-testid="jackpot-banner-amount">
        {{ this.currencyService.getCurrency(this.applicationQuery.currency).symbol }}
        {{ jackpotAmount | number: '1.0-0'}}
      </span>
    </div>
    <img class="jackpot-banner-item-jackpot-logo" [src]="jackpotLogoUrl" alt="jackpot-logo" data-testid="jackpot-banner-logo">
  </div>
  <div class="jackpot-banner-item-progress-bar-container" data-testid="jackpot-banner-progress-bar" *ngIf="!inGameBanner">
    <div class="jackpot-banner-item-progress-bar">
      <div class="jackpot-banner-item-progress-bar-fill" [class]="jackpotClass" [style.width]="progressBarFill + '%'"></div>
    </div>
  </div>
  <div class="jackpot-banner-item-subtext" *ngIf="jackpotInfo && !inGameBanner">
    <span class="material-icons jackpot-banner-item-subtext-icon" (click)="navigateToLatestJackpots()"
    data-testid="jackpot-banner-info-btn">{{ 'info' }}</span>
    <span class="jackpot-banner-item-subtext-text" data-testid="jackpot-banner-info-text">{{ jackpotInfo }}</span>
  </div>
</div>
