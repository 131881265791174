export class GeoAccessControlConfig {
  accessBlockingEnabled: boolean;
  tldRedirectionEnabled: boolean;
  siteCountryCode: string;

  constructor(init: Partial<GeoAccessControlConfig>) {
    Object.assign(this, init);
  }
}

export class GeoAccessControlState {
  accessList: GeoAccessModel[];
  userCountryCode: string;
  userCountryName: string;
  countrySelectionDetails: CountrySelectionDetails;

  constructor(init: Partial<GeoAccessControlState>) {
    Object.assign(this, init);
  }
}

export class GeoAccessModel {
  actionName: string;
  isAllowed: boolean;

  constructor(init: Partial<GeoAccessModel>) {
    Object.assign(this, init);
  }
}

export class CountrySelectionDetails {
  brandLogoUrl: string;
  userLocationText: string;
  topText: string;
  bottomText: string;
  countryList: CountryListItemDetails[] = [];

  constructor(init: Partial<CountrySelectionDetails>) {
    Object.assign(this, init);
  }
}

export class CountryListItemDetails {
  countryCode: string;
  flagIconUrl: string;
  buttonText: string;
  siteUrl: string;

  constructor(init: Partial<CountryListItemDetails>) {
    Object.assign(this, init);
  }
}
