import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { merge } from 'rxjs';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Directive({
  selector: '[appScrollToTopPositioner]',
})
export class ScrollToTopPositionerDirective implements OnInit {
  constructor(public elementRef: ElementRef, readonly renderer: Renderer2, private readonly sportQuery: SportQuery) {}

  ngOnInit(): void {
    merge(
      this.sportQuery.allCompetitionByCountry$,
      this.sportQuery.competitionsAZ$,
      this.sportQuery.outrights$,
      this.sportQuery.selectedPrematch$
    ).subscribe(() => {
      this.handleElementHeight();
    });
  }

  handleElementHeight(): void {
    setTimeout(() => {
      const proceedBarPresence = window.document.querySelector('.proceed-bar-wrapper') === null ? false : true;
      if (proceedBarPresence) {
        this.renderer.addClass(this.elementRef.nativeElement, 'with-proceed-bar');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'with-proceed-bar');
      }
    });
  }
}
