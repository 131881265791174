import { Injectable } from '@angular/core';
import { kebabCase } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { HelpQuery } from 'src/app/core/state/help/help.query';
import { HelpStore } from 'src/app/core/state/help/help.store';
import { APIType } from 'src/app/shared/models/api.model';
import { HelpModel, HelpSection } from 'src/app/shared/models/help.model';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(
    private readonly apiService: APIService,
    private readonly helpStore: HelpStore,
    private readonly helpQuery: HelpQuery,
    private readonly accountQuery: AccountQuery
  ) {}

  getHelp(): Observable<HelpSection[]> {
    return this.helpQuery.sections$.pipe(
      take(1),
      switchMap(sections => (sections.length > 0 ? of(sections) : this.getHelpFromApi()))
    );
  }

  private getHelpFromApi(): Observable<HelpSection[]> {
    return this.apiService.get(APIType.CMS, `Help/GetHelp`).pipe(
      map(res => res.map(this.mapHelpContent)),
      tap(sections => {
        const depositHelpSection = sections.find(t => t.title === 'Deposits');
        if (depositHelpSection != null) {
          const userData = this.accountQuery.userData;
          if (userData) {
            sections.forEach(t => {
              if (t.title === 'Deposits' || t.title === 'Account Tutorials') {
                t.content = t.content.replace('USERID', userData?.id?.toString());
              }
            });
          }
        }
        this.helpStore.setHelp(sections);
      })
    );
  }

  private readonly mapHelpContent = (response: HelpModel): HelpSection => ({
    ...response,
    url: kebabCase(response.title),
  });
}
