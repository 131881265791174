import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { AccumulatorBonusContentModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import { APIType } from 'src/app/shared/models/api.model';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Injectable({
  providedIn: 'root',
})
export class AccumulatorBonusService {
  constructor(
    private readonly apiService: APIService,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService
  ) {}

  dismissAccumulatorBonusOddsValuePopup(): void {
    this.accumulatorBonusStore.dismissAccumulatorBonusOddsValuePopup();
  }

  getCMSContent(): void {
    if (this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled) {
      this.applicationQuery.isVirtuals$
        .pipe(
          tap(isVirtuals => {
            const apiURL = isVirtuals ? '/Virtuals/GetVirtualsAccumulatorBonusPopup/' : '/Bonuses/GetAccumulatorBonusContent/';
            this.apiService
              .get(APIType.CMS, `${apiURL}?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
              .pipe(
                tap((response: AccumulatorBonusContentModel) => {
                  this.accumulatorBonusStore.updateCMSContent(response);
                })
              )
              .subscribe();
          })
        )
        .subscribe();
    }
  }
}
