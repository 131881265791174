<div class="close" (click)="close()">
  <i class="fa fa-close"></i>
</div>
<ng-container [ngSwitch]="state$ | async">
  <ng-container *ngSwitchCase="otpVerifyStates.InputNumber">
    <mobver-mobile-number-verification
      [content]="mobileInputContent$ | async"
      (proceedClicked)="proceedFromVerifyNumber($event)"
    >
    </mobver-mobile-number-verification>
  </ng-container>
  <ng-container *ngSwitchCase="otpVerifyStates.InputOTP">
    <app-otp-screen
      [content]="inputOTPContent"
      [mobilePrefix]="mobilePrefix$ | async"
      [mobileNumber]="mobileNumber$ | async"
      [genericError]="otpInputGenericError$ | async"
      [otpOption]="5"
      (postOTP)="postOTP()"
      (startLoading)="startLoading()"
      (stopLoading)="stopLoading()"
      *ngIf="(mobilePrefix$ | async) && (mobileNumber$ | async)"
    ></app-otp-screen>
  </ng-container>
  <ng-container *ngSwitchCase="otpVerifyStates.NumberVerified">
    <mobver-success
      [content]="numberVerifiedContent$ | async"
    ></mobver-success>
  </ng-container>
  <ng-container *ngSwitchCase="otpVerifyStates.AlreadyVerified">
    <mobver-already-verified
      [content]="alreadyVerifiedContent$ | async"
    ></mobver-already-verified>
  </ng-container>
</ng-container>

<app-loading-circle [show]="showLoadingOverlay$ | async"></app-loading-circle>
