import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BetCouponFreebetDetails } from 'clientside-coupon/lib/clientside-coupon.model';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';

@Component({
  selector: 'my-bets-freebet-voucher',
  templateUrl: './my-bets-freebet-voucher.component.html',
  styleUrls: ['./my-bets-freebet-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyBetsFreebetVoucherComponent {
  @Input() voucher: { amount: number } & BetCouponFreebetDetails;
  @Input() showStakeDisclaimer: boolean;

  constructor(readonly freebetsQuery: FreebetsQuery) {}
}
