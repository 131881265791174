import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-mobile-prefix',
  templateUrl: './mobile-prefix.component.html',
  styleUrls: ['./mobile-prefix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilePrefixComponent {
  defaultCountry = this.appConfig.get('registration').defaultCountry;
  loginPrefix = this.appConfig.get('account').loginPrefix;

  constructor(private readonly appConfig: AppConfigService) {}
}
