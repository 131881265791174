import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { AdjustContainerOnHorizontalScrollDirective } from 'src/app/shared/directives/adjust-container-on-horizontal-scroll.directive';

import { EventSummaryModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-summary-live',
  templateUrl: './events-summary-live.component.html',
  styleUrls: ['./events-summary-live.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSummaryLiveComponent {
  @Input() eventsInfo: EventSummaryModel;
  @Input() eventLocation: string = undefined; // Used for data layer events

  @ViewChildren(AdjustContainerOnHorizontalScrollDirective) viewChildren: QueryList<AdjustContainerOnHorizontalScrollDirective>;

  eventTrackBy(index: number, match: MatchModel): number {
    return match.id;
  }
}
