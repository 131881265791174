<div class="container" *ngIf='(geoAccessQuery.countrySelectionDetails$ | async) as pageDetails'>
  <div class="brand-logo">
    <img [src]="pageDetails.brandLogoUrl" />
  </div>

  <span class="user-location-text">
    {{pageDetails.userLocationText}} {{geoAccessQuery.userCountryName$ | async}}
  </span>

  <span class="top-text">{{pageDetails.topText}}</span>

  <div class="button-container">
    <div class="button highlight" *ngIf="(userLocaleData$ | async) as userLocaleData"
      (click)="goToSite(userLocaleData)">
      <img *ngIf="userLocaleData.flagIconUrl" [src]="userLocaleData.flagIconUrl" alt="Flag" class="flag" />
      <span>{{ userLocaleData.buttonText }}</span>
    </div>

    <div class="button transparent" *ngIf="(siteLocaleData$ | async) as siteLocaleData"
      (click)="goToSite(siteLocaleData)">
      <img *ngIf="siteLocaleData.flagIconUrl" [src]="siteLocaleData.flagIconUrl" alt="Flag" class="flag" />
      <span>{{ siteLocaleData.buttonText }}</span>
    </div>
  </div>

  <span class="bottom-text">{{pageDetails.bottomText}}</span>
</div>
