import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Query } from '@datorama/akita';
import { AndroidSettingsState, AndroidVersionState } from 'src/app/modules/native-app/models/android-settings.model';
import { AndroidSettingsStore } from 'src/app/modules/native-app/state/android-settings.store';

@Injectable({ providedIn: 'root' })
export class AndroidSettingsQuery extends Query<AndroidSettingsState> {
  useWebViewMode$ = this.select(state => state.webViewMode);
  useOfflineMode$ = this.select(state => state.offlineMode);
  useNativeComponents$ = this.select(
    state =>
      state.webViewMode ||
      state.offlineMode ||
      (!state.latestVersion.optionalUpdate && state.latestVersion.versionCode !== state.appVersionCode) ||
      state.minVersionCode > state.appVersionCode
  );
  forceUpdate$ = this.select(
    state =>
      (!state.latestVersion.optionalUpdate && state.latestVersion.versionCode !== state.appVersionCode) ||
      state.minVersionCode > state.appVersionCode
  );
  initialVersionCode: number = 1;

  constructor(protected store: AndroidSettingsStore) {
    super(store);
  }

  get versionState(): AndroidSettingsState {
    return this.getValue();
  }

  get appLatestVersionCode(): number {
    return this.getValue().latestVersion.versionCode;
  }

  get optionalUpdate(): boolean {
    return this.getValue().latestVersion.optionalUpdate;
  }

  get appVersionName(): string {
    if (Capacitor.getPlatform() === 'android') {
      return this.getValue().appVersionName;
    } else {
      return undefined;
    }
  }

  get offlineMode(): boolean {
    return this.getValue().offlineMode;
  }

  get latestVersion(): AndroidVersionState {
    return this.getValue().latestVersion;
  }

  get isWebViewMode(): boolean {
    return this.getValue().webViewMode;
  }

  get minVersionCode(): number {
    return this.getValue().minVersionCode;
  }
}
