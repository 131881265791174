import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { from, Observable, of, Subject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NativeUserDetails } from 'src/app/modules/native-app/models/native-user-details.model';
import { PushMessagingService } from 'src/app/modules/native-app/services/push-messaging.service';
import { SecureStorePlugin } from 'src/app/modules/native-app/plugins/secure-store-plugin';

@Injectable({
  providedIn: 'root',
})
export class NativeUserDetailsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly appConfig: AppConfigService, private readonly pushMessagingService: PushMessagingService) {}

  verifyUsersData(savedUser: any): any {
    if (Capacitor.getPlatform() === 'android') {
      const timestamp = this.getSessionStartTimestamp();

      if (savedUser.name === '' || typeof timestamp === 'undefined') {
        return false;
      }

      const maxSessionLength = Number(this.appConfig.get('nativeApp').daysOfMaxSessionLength.toString()) * 86400000;
      if (Date.now() - Number(timestamp) > maxSessionLength) {
        this.removeUser();
        return false;
      }
      return savedUser;
    }
    return false;
  }

  getUser(): Observable<any> {
    if (Capacitor.getPlatform() === 'android') {
      return from(SecureStorePlugin.getUser());
    }
    return of(undefined);
  }

  removeUser(): boolean {
    if (Capacitor.getPlatform() === 'android') {
      from(SecureStorePlugin.removeUser()).subscribe(() => {
        this.removeSessionStartTimestamp();
        this.pushMessagingService.updatePushMessagingUserID();
      });
      return true;
    }
    return false;
  }

  saveUser(username: string, password: string): boolean {
    const user: NativeUserDetails = { name: username, password };
    if (Capacitor.getPlatform() === 'android') {
      try {
        from(SecureStorePlugin.saveUser(user)).subscribe(() => {
          this.storeSessionStartTimestamp(Date.now());
          this.pushMessagingService.updatePushMessagingUserID();
        });
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  storeSessionStartTimestamp(timestamp: number): void {
    localStorage.setItem('timestamp', timestamp.toString());
  }

  getSessionStartTimestamp(): string {
    return localStorage.getItem('timestamp');
  }

  removeSessionStartTimestamp(): void {
    localStorage.removeItem('timestamp');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
