import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { from, Subject } from 'rxjs';

import { LoggerService } from 'src/app/core/services/logger.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { XtremePushPlugin } from 'src/app/modules/native-app/plugins/xtreme-push-plugin';
import { LogTypeModel } from 'src/app/shared/models/log.model';
import { validateUrl } from 'src/app/shared/utils/validate-url';

@Injectable({
  providedIn: 'root',
})
export class PushMessagingService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(readonly accountQuery: AccountQuery, private readonly loggerService: LoggerService, private readonly router: Router) {}

  initDeeplinks(): void {
    XtremePushPlugin.addListener('push_message_deeplink', (data: any) => {
      this.handleDeeplinks(data);
    });
    from(XtremePushPlugin.getBackgroundEvents()).subscribe();
  }

  hitEvent(eventName: string): void {
    from(XtremePushPlugin.hitEvent({ eventName: eventName })).subscribe();
  }

  updatePushMessagingUserID(): void {
    if (Capacitor.getPlatform() === 'android') {
      const userId = this.accountQuery.userData ? this.accountQuery.userData.id : 0;
      if (userId > 0) {
        from(XtremePushPlugin.setUserId({ user_id: userId.toString() })).subscribe();
        this.hitEvent('login');
        this.loggerService.logEvent(
          'Push massaging userId added',
          `userId: ${userId}`,
          SeverityLevel.Information,
          LogTypeModel.Application
        );
      } else {
        from(XtremePushPlugin.setUserId({ user_id: '' })).subscribe();
        this.loggerService.logEvent(
          'Push massaging userId removed',
          `Push massaging userId removed`,
          SeverityLevel.Information,
          LogTypeModel.Application
        );
      }
    }
  }

  initialisePushMessaging(): void {
    from(XtremePushPlugin.hitSessionStartEvent()).subscribe();
    this.initDeeplinks();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private handleDeeplinks(data: any): void {
    if (data.deeplink.length > 1 && validateUrl(data.deeplink)) {
      try {
        this.router.navigateByUrl(data.deeplink);
        this.loggerService.logEvent(
          'Push massaging deeplink success',
          `deeplink: ${data.deeplink}`,
          SeverityLevel.Information,
          LogTypeModel.Application
        );
      } catch (error) {
        this.loggerService.logEvent(
          'Push massaging deeplink error',
          `deeplink: ${data.deeplink}`,
          SeverityLevel.Error,
          LogTypeModel.Application
        );
      }
    } else {
      this.loggerService.logEvent(
        'Push massaging deeplink error (empty or not safe link)',
        `deeplink: ${data.deeplink}`,
        SeverityLevel.Error,
        LogTypeModel.Application
      );
    }
  }
}
