<div class="details">
  <div class="event-competitors">
    <div class="team">
      <span class="name" [innerHTML]="event.homeTeam | highlight: (searchQuery.searchKeyword$ | async)"> </span>
      <span class="score" [@fadeIn]>
        {{ event.score | displayScore: 'home' }}
      </span>
    </div>
    <div class="team">
      <span class="name" [innerHTML]="event.awayTeam | highlight: (searchQuery.searchKeyword$ | async)"> </span>
      <span class="score" [@fadeIn]>
        {{ event.score | displayScore: 'away' }}
      </span>
    </div>
  </div>
</div>
