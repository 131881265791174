import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';

@Component({
  selector: 'app-virtuals-coupon-page-wrapper',
  templateUrl: './virtuals-coupon-page-wrapper.component.html',
  styleUrls: ['./virtuals-coupon-page-wrapper.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class VirtualsCouponPageWrapperComponent {}
