import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { determineCouponProduct } from 'src/app/shared/utils/determine-coupon-product';
import { ProductType } from 'src/app/shared/models/product.model';

@Component({
  selector: 'app-coupon-check',
  templateUrl: './coupon-check.component.html',
  styleUrls: ['./coupon-check.component.scss'],
})
export class CouponCheckComponent {
  couponCode = new FormControl('', Validators.required);

  constructor(private readonly router: Router) {}

  checkCoupon(): void {
    if (this.couponCode.invalid) {
      return;
    }
    const couponProduct = determineCouponProduct(this.couponCode.value);
    if (couponProduct.productType === ProductType.Virtuals) {
      this.router.navigateByUrl(`/coupon-check/${this.couponCode.value}`);
    } else {
      window.location.href = `${window.location.origin}/sports/coupon-check/${this.couponCode.value}`;
    }
  }
}
