<div class="legend-table-container">
  <div class="header" i18n>Legend</div>
  <div class="legends">
    <div class="legend">
      <span class="legend-icon in-progress">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </span>
      <span class="legend-name" i18n>Running</span>
    </div>
    <div class="legend">
      <span class="legend-icon won"></span>
      <span class="legend-name" i18n>Won</span>
    </div>
    <div class="legend">
      <span class="legend-icon partially-won"></span>
      <span class="legend-name" i18n>Partially Won</span>
    </div>
    <div class="legend">
      <span class="legend-icon lost"></span>
      <span class="legend-name" i18n>Lost</span>
    </div>
    <div class="legend">
      <span class="legend-icon cancelled"></span>
      <span class="legend-name" i18n>Cancelled</span>
    </div>
    <div class="legend">
      <span class="legend-icon void"></span>
      <span class="legend-name" i18n>Void</span>
    </div>
    <div class="legend">
      <span class="legend-icon in-evaluation"></span>
      <span class="legend-name" i18n>Evaluation</span>
    </div>
    <div class="legend">
      <span class="legend-icon cashout"></span>
      <span class="legend-name" i18n>Cashout</span>
    </div>
    <div class="legend">
      <span class="legend-icon banker" i18n>B</span>
      <span class="legend-name" i18n>Banker Bet</span>
    </div>
    <div class="legend jackpot" *ngIf="isVirtuals">
      <span class="legend-icon jackpot"></span>
      <span class="legend-name">{{ jackpotNaming }}</span>
    </div>
  </div>
</div>
