import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appZIndex]',
})
export class ZIndexDirective implements AfterContentInit, OnDestroy {
  @Input() appZIndex: boolean;

  constructor(public elementRef: ElementRef, readonly renderer2: Renderer2) {}

  ngAfterContentInit(): void {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'z-index', 2);
  }

  ngOnDestroy(): void {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'z-index', 1);
  }
}
