import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoggerService } from 'src/app/core/services/logger.service';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private readonly loggerService: LoggerService) {}

  getCookie(name: string): string {
    try {
      return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '');
    } catch (err) {
      // This should not happen, apart from in SSR when using Domino.
      this.loggerService.logEvent(`Could not retrieve cookie: ${name}`, err.message, SeverityLevel.Warning, LogTypeModel.Application);
      return '';
    }
  }

  setCookie(name: string, value: string, days: number = 7, path: string = '/', sameSite: string = '', secure: boolean = false): void {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    let cookieDef: string = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
    if (sameSite) {
      cookieDef += `; SameSite=${sameSite}`;
    }
    if (secure) {
      cookieDef += `; Secure`;
    }

    document.cookie = cookieDef;
  }

  clearCookie(name: string, path: string = '/'): void {
    this.setCookie(name, '', -1, path);
  }
}
