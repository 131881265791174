import { Component, Input } from '@angular/core';
import { EventBetInsightModel } from 'src/app/modules/sport/models/event-view/prematch.model';

@Component({
  selector: 'app-bet-insight-wrapper',
  templateUrl: './bet-insight-wrapper.component.html',
  styleUrls: ['./bet-insight-wrapper.component.scss'],
})
export class BetInsightWrapperComponent {
  @Input() collapsed: boolean = false;
  @Input() betInsights: EventBetInsightModel[] = [];
  @Input() showInfo: boolean = true;
  @Input() eventLocation: string = undefined;
}
