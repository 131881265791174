import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CouponSelectionsComponent } from './components/coupon-selections/coupon-selections.component';
import { CouponTotalsComponent } from './components/coupon-totals/coupon-totals.component';
import { QuickCouponRoutingModule } from './quick-coupon-routing.module';
import { QuickCouponComponent } from './quick-coupon.component';

const COMPONENTS = [QuickCouponComponent, CouponSelectionsComponent, CouponTotalsComponent];

@NgModule({
  imports: [SharedModule, QuickCouponRoutingModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class QuickCouponModule {}
