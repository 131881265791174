import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, finalize, first, map } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Component({
  selector: 'app-book-bet',
  templateUrl: './book-bet.component.html',
  styleUrls: ['./book-bet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookBetComponent implements OnInit {
  disableSend$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    readonly couponQuery: CouponQuery,
    protected readonly bookBetService: BookBetService,
    protected readonly notificationService: NotificationService,
    protected readonly applicationService: ApplicationService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute) {
      this.activatedRoute.queryParams
        .pipe(
          filter(params => params['booking-code']),
          map(params => params['booking-code']),
          first()
        )
        .subscribe(code => {
          this.bookBet(code);
        });
    }
  }

  bookBet(bookCode: string): void {
    this.disableSend$.next(true);

    this.bookBetService
      .getBookBet(bookCode)
      .pipe(
        finalize(() => {
          this.disableSend$.next(false);
        })
      )
      .subscribe(() => {
        if (!this.couponQuery.bookedBetData.hasExpiredEvents) {
          if (this.couponQuery.bookedBetData.bookedCoupon) {
            this.applicationService.showCoupon();
            this.notificationService.showSuccessNotification(
              $localize`Successfully loaded booked coupon ${this.couponQuery.bookedBetData.bookCode.toLocaleUpperCase()}`
            );
          } else {
            this.notificationService.showWarningNotification(
              $localize`Booked coupon ${this.couponQuery.bookedBetData.bookCode.toLocaleUpperCase()} was not found`
            );
          }
        }
      });
  }

  onExpiredSubmit(): void {
    if (this.couponQuery.bookedBetData.hasExpiredEvents) {
      this.bookBetService.loadBookedCoupon(this.couponQuery.bookedBetData);
      this.applicationService.showCoupon();
      this.notificationService.showSuccessNotification(
        $localize`Successfully loaded booked coupon ${this.couponQuery.bookedBetData.bookCode.toLocaleUpperCase()} with ${
          this.couponQuery.bookedBetData.availableEventCount
        } events`
      );
    }
    this.bookBetService.clearData();
  }

  onCancel(): void {
    this.bookBetService.clearData();
  }
}
