<app-slide-panel [activePanel]="inTaxInfoView ? 'right' : 'left'">
  <div class="groupings-container normal" panelLeft>
    <div class="header-row tax-info-button" *ngIf="(couponQuery.couponData$ | async).TurnoverTax"
      (click)="onTaxInfoButton()">
      <span i18n>View Net Stake and Winning Info</span>
      <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
    </div>
    <div class="header-row">
      <div class="header-column grouping" i18n>Grp</div>
      <div class="header-column combinations" i18n>Com</div>
      <div class="header-column stake">
        <span *ngIf="(couponQuery.couponData$ | async).TurnoverTax === 0" i18n>Stake</span>
        <span *ngIf="(couponQuery.couponData$ | async).TurnoverTax" i18n>Gross Stake</span>
      </div>
      <div class="header-column winnings">
        <span *ngIf="(couponQuery.couponData$ | async).TurnoverTax === 0" i18n>Winnings</span>
        <span *ngIf="(couponQuery.couponData$ | async).TurnoverTax" i18n>Gross Win.</span>
      </div>
    </div>

    <!-- ng-container - set for loop here -->
    <ng-container *ngFor="let group of groups; let isLast = last; trackBy: indexTrackBy;">
      <div class="data-row" *ngIf="group.Combinations !== 0"
        [hidden]="!(group | isGroupingVisible : isLast : couponQuery.groupingsTabSelected)">
        <div class="data-column grouping" (click)="groupSelector($event, group)">
          <span class="grouping-container">
            <!-- one or the other with an if statement -->
            <input type="checkbox" *ngIf="(groups | hasGroupings) || !group.Selected" class="grouping-checkbox"
              [(ngModel)]="group.Selected" (ngModelChange)="updateGroupings([group])" />
            <input type="checkbox" *ngIf="!(groups | hasGroupings) && group.Selected" class="grouping-checkbox"
              [(ngModel)]="group.Selected" [disabled]="true" />
            <label></label>
          </span>
          <span class="group-name">{{ group.Grouping | groupName }}</span>
        </div>

        <div class="data-column combinations">
          <span class="no-wrap">{{ group.Combinations }}</span>
        </div>

        <div class="data-column stake">
          <!-- one or the other with an if statement -->
          <input type="number" [attr.data-group]="'groupStakeValue_' + group.Grouping" *ngIf="group.Stake !== -1"
            class="grouping-stake" [placeholder]="group.Stake" [value]="group.Stake"
            (input)="updateGroupStakeValue($event.target.value, group)" #groupingStake appSelectAll />
          <input type="number" *ngIf="group.Stake === -1" class="grouping-stake"
            [disabled]="group.Stake === -1 ? true : false" appSelectAll />
        </div>

        <div class="data-column winnings" *ngIf="(couponQuery.couponData$ | async).TurnoverTax === 0">
          <span>{{ group.NetStakeMinWin | odd }}</span>
          <span>{{ group.NetStakeMaxWin | odd }}</span>
        </div>
        <div class="data-column winnings" *ngIf="(couponQuery.couponData$ | async).TurnoverTax > 0">
          <span>{{ group.MinWin | odd }}</span>
          <span>{{ group.MaxWin | odd }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="groupings-container tax-info" *ngIf="(couponQuery.couponData$ | async).TurnoverTax" panelRight>
    <div class="header-row tax-info-button" (click)="onTaxInfoButton()">
      <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
      <span i18n>Back to Stake Input</span>
    </div>
    <div class="header-row">
      <div class="header-column grouping" i18n>Grp</div>
      <div class="header-column combinations" i18n>Com</div>
      <div class="header-column stake" i18n>Net Stake</div>
      <div class="header-column winnings" i18n>Net Win.</div>
    </div>

    <!-- ng-container - set for loop here -->
    <ng-container *ngFor="let group of groups; let isLast = last; trackBy: indexTrackBy;">
      <div class="data-row" *ngIf="group.Combinations !== 0"
        [hidden]="!(group | isGroupingVisible : isLast : couponQuery.groupingsTabSelected)"
        [class.unselected]="!group.Selected">
        <div class="data-column grouping" (click)="groupSelector($event, group)">
          <span>{{ group.Grouping | groupName }}</span>
          <span class="grouping-container">
            <i class="fa fa-check" *ngIf="group.Selected"></i>
          </span>
        </div>

        <div class="data-column combinations">
          <span class="no-wrap">{{ group.Combinations }}</span>
        </div>

        <div class="data-column stake">
          {{ (group.NetStake > 0 ? group.NetStake : 0) | odd}}
        </div>

        <div class="data-column winnings">
          <span>{{ group.NetStakeMinWin | odd }}</span>
          <span>{{ group.NetStakeMaxWin | odd }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</app-slide-panel>
