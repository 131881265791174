import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { oddChange } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { ABTestService } from 'src/app/core/services/ab-test.service';
@Component({
  selector: 'app-odd',
  templateUrl: './odd.component.html',
  styleUrls: ['./odd.component.scss'],
  animations: [oddChange()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OddComponent implements OnInit, OnDestroy {
  @Input() odd: OddModel | undefined;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() isLive: boolean = false;
  @Input() itemIndex: number;
  // Inputs for styling
  @Input() multiple = false;
  @Input() first = false;
  @Input() last = false;
  @Input() haveSpreadSelector = false;
  @Input() @HostBinding('class.betbuilder-boosted') betBuilderBoosted: boolean;

  @HostBinding('class.selected') selected: boolean;

  private readonly destroy$ = new Subject<any>();

  constructor(
    private readonly couponService: CouponService,
    private readonly couponQuery: CouponQuery,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService,
    private readonly cdr: ChangeDetectorRef,
    private readonly abTestService: ABTestService
  ) {}

  @HostBinding('class.no-radius-left')
  get noRadiusLeft(): boolean {
    return (this.multiple && !this.first) || this.haveSpreadSelector;
  }

  @HostBinding('class.no-radius-right')
  get noRadiusRight(): boolean {
    return this.multiple && !this.last;
  }

  ngOnInit(): void {
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const selected = this.odd && this.couponService.isOddInCoupon(this.odd.id);
      if (selected !== this.selected) {
        this.selected = selected;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click') selectOdd(): void {
    if (this.odd) {
      if (this.selected) {
        this.couponService.removeOdd(this.odd.id, this.odd.marketId);
      } else if (this.odd.enabled) {
        this.createDataLayerEvent();
        this.couponService.addOdd(this.odd);
      }
    }
  }

  private createDataLayerEvent(): void {
    const { eventLocation } = this;

    const eventObj = {
      user_id: this.accountQuery.userData?.id,
      product: this.isLive ? DataLayerProduct.SportsBookLive : DataLayerProduct.SportsBookPrematch,
      selection_id: this.odd.id,
      location: eventLocation ? eventLocation : window.location.pathname,
      position: this.itemIndex >= 0 ? this.itemIndex + 1 : null,
      sport_name: this.odd.sportName,
    };

    // GTM
    this.dataLayerService.createDataLayerEvent({ ...eventObj, event: BetslipActions.AddToBetslip });

    // VWO
    this.abTestService.track('live-bet-widget-v2', 'gtm.add_to_betslip', { eventProperties: eventObj }).subscribe();
  }
}
