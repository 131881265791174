<div class="odds-table-container" *ngIf="eventsInfo">
  <ng-container *ngIf="eventsInfo.marketSelected?.selections?.length >= 4">
    <!-- Multi-line -->
    <app-multi-line-event
      *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy"
      [event]="event"
      [market]="eventsInfo.marketSelected"
      [sportId]="eventsInfo.sportId"
      [eventLocation]="'Homepage_'+ eventLocation +'_'+ eventsInfo.marketSelected?.name + (event.eventCategory === 'L' ? '_Live' : '')"
    ></app-multi-line-event>
  </ng-container>
  <ng-container *ngIf="eventsInfo.marketSelected?.selections?.length < 4">
    <!-- Single-line -->
    <div class="markets-header">
      <app-events-markets-header
        appStickyTab
        [nonSticky]="isLiveListWidget"
        [zIndex]="stickyZIndex"
        [infoLine]="true"
        [haveHeader]="true"
        [showMarketName]="showMarketName"
        [marketName]="eventsInfo.marketSelected?.name"
        [markets]="eventsInfo.marketSelected?.selections"
        [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
        [selectedOption]="selectedOption"
        (optionSelected)="groupedMarketSelected($event)">
      </app-events-markets-header>
    </div>
    <ng-container *ngIf="isLiveListWidget; else prematch">
      <div class="event-container" appPagination #pagination="appPagination" [itemsPerPage]="pageSize">
        <ng-container [ngTemplateOutlet]="contentMatches"></ng-container>
      </div>
      <div class="bottom-content-footer" *ngIf="eventsInfo.matches.length > pageSize">
        <div class="pagination">
          <span (click)="previousPage()" class="material-icons" [class.disabled]="(currentPage$ | async) === 1">{{'chevron_left'}}</span>
          <span class="page-number" *ngFor="let page of (pages$ | async)" [class.active]="page === (currentPage$ | async)" (click)="goToPage(page)">
            {{ page }}
          </span>
          <span (click)="nextPage()" class="material-icons" [class.disabled]="(currentPage$ | async) === (totalPages$ | async)">{{'chevron_right'}}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #prematch>
      <div class="event-container">
        <ng-container [ngTemplateOutlet]="contentMatches"></ng-container>
      </div>
    </ng-template>

    <ng-template #contentMatches>
      <ng-container *ngIf="sortable; else nonSortable">
        <app-single-line-event
          *ngFor="let event of eventsInfo.matches | sortTodaysEventsByOdd: (landingQuery.sortType$ | async)?.value : eventsInfo?.marketSelected; trackBy: eventTrackBy; let last = last; let index = index"
          [event]="event"
          [itemIndex]="index"
          [sportId]="eventsInfo.sportId"
          [last]="last"
          [selectedMarket]="eventsInfo.marketSelected"
          [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
          [selectedOption]="selectedOption"
          [eventLocation]="'Homepage_'+ eventLocation +'_'+ eventsInfo.marketSelected?.name + (event.eventCategory === 'L' ? '_Live' : '')"
          [isLiveListWidget]="isLiveListWidget"
        ></app-single-line-event>
      </ng-container>
    </ng-template>
    <ng-template #nonSortable>
      <app-single-line-event
        *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy; let last = last; let index = index"
        [event]="event"
        [itemIndex]="index"
        [sportId]="eventsInfo.sportId"
        [last]="last"
        [selectedMarket]="eventsInfo.marketSelected"
        [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
        [selectedOption]="selectedOption"
        [eventLocation]="'Homepage_'+ eventLocation +'_'+ eventsInfo.marketSelected?.name + (event.eventCategory === 'L' ? '_Live' : '')"
        [isLiveListWidget]="isLiveListWidget"
      ></app-single-line-event>
    </ng-template>
  </ng-container>
</div>
