import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoginService } from 'src/app/core/services/account/login.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { fadeInOutBy } from 'src/app/shared/animations';
import { LoginResponseModel, LoginType } from 'src/app/shared/models/account.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { FirebaseEvent } from 'src/app/shared/models/datalayer.model';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  animations: [fadeInOutBy()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent implements OnDestroy {
  @Input() show: boolean = false;
  @Output() readonly closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly buttonType = ButtonType;
  readonly loginType = LoginType;
  readonly loginForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  readonly ctaRegisterAdditionalStyles = {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    padding: 0,
    fontWeight: 'normal',
    fontSize: '12px',
    minWidth: '60px',
  };
  readonly showPassword$ = new BehaviorSubject(false);
  readonly errorMessage$ = new BehaviorSubject(undefined);
  readonly loginInProgress$ = new BehaviorSubject(false);
  private loginStarted: boolean = false;
  private readonly loginPrefix = this.appConfig.get('account').loginPrefix;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly applicationQuery: ApplicationQuery,
    private readonly appConfig: AppConfigService,
    private readonly loginService: LoginService,
    private readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly mobileNumberInputService: MobileNumberInputService,
    private readonly notificationService: NotificationService,
    private readonly dataLayerService: DataLayerService
  ) {}

  loginClicked(event) {
    if (!this.loginStarted) {
      this.loginStarted = true;
      this.dataLayerService.createDataLayerEvent({
        event: 'login-started',
      });
    }

    event.stopPropagation();
  }
  login(): void {
    const eventId = FirebaseEvent.LoginClickLogin;
    this.dataLayerService.logFirebaseEvent(eventId);

    if (this.loginForm.invalid) {
      return;
    }
    this.dataLayerService.createDataLayerEvent({
      event: 'login-submitted',
    });
    let userNameOrMobile = this.loginForm.controls.userName.value;
    if (this.mobileNumberInputService.isMobileNumber(userNameOrMobile)) {
      userNameOrMobile = `${this.loginPrefix}${this.mobileNumberInputService.mobileNumberCleanup(userNameOrMobile)}`;
    }
    if (!isNaN(userNameOrMobile)) {
      this.accountService.getPlyOnUserStatus(userNameOrMobile).subscribe(
        data => {
          if (data?.data?.userStatus === 'CONF') {
            this.accountService
              .plyOnLogin({
                username: `+${userNameOrMobile}`,
                password: this.loginForm.value.password,
              })
              .subscribe(
                result => {
                  window.location.href = `/my-accounts/registration/user-details?phoneNumber=${userNameOrMobile}&userId=${result.data.userId}`;
                },
                err => {
                  this.loginInProgress$.next(false);
                  this.errorMessage$.next(this.loginService.DEFAULT_LOGIN_ERROR_MESSAGE);
                }
              );
          } else {
            this.proceedLogin(userNameOrMobile);
          }
        },
        error => this.proceedLogin(userNameOrMobile)
      );
    } else {
      this.proceedLogin(userNameOrMobile);
    }
  }

  private proceedLogin(userNameOrMobile) {
    this.errorMessage$.next(undefined);
    this.loginInProgress$.next(true);

    this.loginService.login(userNameOrMobile, this.loginForm.value.password).subscribe(
      (response: LoginResponseModel) => {
        this.loginInProgress$.next(false);
        if (!response || !response.success || !this.accountQuery.userData) {
          this.errorMessage$.next(this.loginService.DEFAULT_LOGIN_ERROR_MESSAGE);
        } else {
          this.loginForm.reset();
          this.successLoginActions();
        }
      },
      error => {
        this.dataLayerService.createDataLayerEvent({
          event: 'login-failure',
          error_message: error?.error?.error_description,
        });
        this.loginService
          .handleLoginErrorResponse(error, this.loginForm.value.userName, this.loginPrefix)
          .subscribe((errorMessage: string) => {
            this.loginInProgress$.next(false);
            this.errorMessage$.next(errorMessage);
          });
      }
    );
  }

  private successLoginActions(): void {
    this.dataLayerService.createDataLayerEvent({
      event: 'login-success',
    });
    if (this.accountQuery.userData?.userStatus === 'PCHANG') {
      this.router.navigate(['account/password-reset']);
    } else {
      this.notificationService.showSuccessMessage($localize`Login successful`);

      const eventId = FirebaseEvent.LoginResponseSuccessful;
      this.dataLayerService.logFirebaseEvent(eventId);
      this.closeDialog(true);
    }
  }

  closeDialog(loggedIn: boolean): void {
    this.loginStarted = false;
    this.loginForm.reset();
    this.showPassword$.next(false);
    this.errorMessage$.next(undefined);
    this.closed.emit(loggedIn);
  }

  onForgetPasswordClick() {
    const eventId = FirebaseEvent.LoginClickForgotPassword;
    this.dataLayerService.logFirebaseEvent(eventId);
    this.closeDialog(false);
    window.location.href = '/account/login/forgot-password';
  }

  onRegisterClick() {
    const eventId = FirebaseEvent.LoginClickRegister;
    this.dataLayerService.logFirebaseEvent(eventId);
    this.closeDialog(false);
    window.location.href = '/account/registration';
  }

  ngOnDestroy(): void {
    this.loginStarted = false;
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeDialogOnBackgroundClick(): void {
    const eventId = FirebaseEvent.LoginClickBackgroundCancel;
    this.dataLayerService.logFirebaseEvent(eventId);
    this.closeDialog(false);
  }

  closeDialogOnCancelClick(): void {
    const eventId = FirebaseEvent.LoginClickCancel;
    this.dataLayerService.logFirebaseEvent(eventId);
    this.closeDialog(false);
  }

  onFieldBlur(fieldName: string): void {
    const value = this.loginForm.get(fieldName)?.value;
    if (value && value.trim() !== '') {
      let eventName: any;

      switch (fieldName) {
        case 'userName':
          eventName = FirebaseEvent.LoginFillUsername;
          break;
        case 'password':
          eventName = FirebaseEvent.LoginFillPassword;
          break;
        default:
          return;
      }

      this.dataLayerService.logFirebaseEvent(eventName);
    }
  }
}
