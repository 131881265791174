export class LogModel {
  type: string;
  user: any;
  logMessage: string;
  logDetails: string;
  timestamp?: string;
  stackTrace?: any;
  httpStatusCode?: number;
  userAgent: string;
  resolution: string;

  constructor(init: Partial<LogModel>) {
    Object.assign(this, init);
  }
}

export enum LogTypeModel {
  Application,
  Network,
}
