import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { VersionService } from 'src/app/modules/native-app/services/version.service';
import { AndroidSettingsQuery } from 'src/app/modules/native-app/state/android-settings.query';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-offline-mode',
  templateUrl: './offline-mode.component.html',
  styleUrls: ['./offline-mode.component.scss'],
})
export class OfflineModeComponent implements OnDestroy {
  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '14px',
    height: '45px',
    textTransform: 'uppercase',
    lineHeight: '16px',
    color: '#1affff',
    fontWeight: 500,
    borderRadius: '50px',
    padding: '10px 15px',
    margin: '3px 59px',
    border: '1px solid #1affff',
    backgroundColor: 'transparent',
  };
  buttonDisabled: boolean = false;
  buttonType: typeof ButtonType = ButtonType;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly versionService: VersionService, readonly androidSettingsQuery: AndroidSettingsQuery) {}

  reconnect(): void {
    this.buttonDisabled = true;
    this.versionService.checkAppVersionUpdate(this.androidSettingsQuery, '/');
    setTimeout(() => {
      this.buttonDisabled = false;
    }, 6000);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
