<div class="rebet-container" *ngIf="couponCode && couponCanBeRebet" [@expandCollapseSimpler]>
  <ng-container *ngIf="(rebetError$ | async) === false">
    <div class="text" (click)="rebetCoupon()">
      <span *ngIf="selectionCount === 1"  i18n>Re-Use This Selection</span>
      <span *ngIf="selectionCount > 1"  i18n>Re-Use These Selections</span>
      <span class="arrow material-icons">{{ 'arrow_right' }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="(rebetError$ | async)">
    <div class="text-error">
      <span i18n>The events are no longer available, please</span>
      <a i18n (click)="navigateHome()">click here</a>
      <span i18n>to make new selections</span>
    </div>
  </ng-container>
</div>
