import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupName',
})
export class GroupNamePipe implements PipeTransform {
  transform(group: number): string {
    let groupName: string = '';
    switch (group) {
      case 1:
        groupName = 'Singles';
        break;
      case 2:
        groupName = 'Doubles';
        break;
      case 3:
        groupName = 'Triples';
        break;
      case 4:
        groupName = 'Quadruples';
        break;
      default:
        groupName = `${group} Folds`;
        break;
    }

    return groupName;
  }
}
