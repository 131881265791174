import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MatchStatisticsTab } from 'src/app/modules/sport/models/event-view/prematch.model';
import { MatchQuery } from 'src/app/modules/sport/state/match-view/match.query';
import { fadeIn } from 'src/app/shared/animations';
import { ProviderType } from 'src/app/shared/models/sport.model';
import { StatisticsType } from 'src/app/shared/models/statistics.model';

@Component({
  selector: 'app-cpp-widget-statistics',
  templateUrl: './cpp-widget-statistics.component.html',
  styleUrls: ['./cpp-widget-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class CppWidgetStatisticsComponent implements OnInit {
  @Input() tabs: MatchStatisticsTab[] = [];
  @Input() statisticsType: StatisticsType;
  @Input() eventLocation: string = undefined; // Used for data layer events

  readonly noStatistics$ = new BehaviorSubject<boolean>(false);
  readonly providerType: typeof ProviderType = ProviderType;
  selectedTab$ = new BehaviorSubject<string>(null);

  constructor(
    readonly matchQuery: MatchQuery,
    private readonly statisticService: StatisticsService,
    private readonly statisticQuery: StatisticsQuery,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService
  ) {
    this.selectedTab$.subscribe(result => {
      if (result) this.addStatWidget(result);
    });
  }

  ngOnInit(): void {
    this.setStatisticsTabs();
  }

  setStatisticsTabs() {
    switch (this.statisticsType) {
      case StatisticsType.LiveList:
        this.tabs = this.statisticQuery.getValue().liveListStatisticsTabs;
        break;
      case StatisticsType.LiveEvent:
        this.tabs = this.statisticQuery.getValue().liveEventStatisticsTabs;
        break;
      case StatisticsType.PreMatchList:
        this.tabs = this.statisticQuery.getValue().preMatchListStatisticsTabs;
        break;
      case StatisticsType.PreMatchEvent:
        this.tabs = this.statisticQuery.getValue().preMatchEventStatisticsTabs;
        break;
      default:
        this.tabs = this.statisticQuery.getValue().preMatchListStatisticsTabs;
        break;
    }
    this.selectedTab$.next(this.tabs[0].key);
  }

  onTabSelected(tab: string) {
    if (this.selectedTab$.value === tab) return;
    this.selectedTab$.next(tab);
    this.createDataLayerEvent(tab);
  }

  private createDataLayerEvent(tab: string): void {
    const { eventLocation } = this;
    this.dataLayerService.createDataLayerEvent({
      event: 'SB-stats-tab-click',
      user_id: this.accountQuery.userData?.id,
      tab_name: tab,
      location: eventLocation ? eventLocation : window.location.pathname,
    });
  }

  private addStatWidget(tab: string) {
    const fixtureInfo = this.matchQuery.fixtureInfo;
    if (!fixtureInfo) {
      this.noStatistics$.next(true);
      return;
    }
    const fixtureId = fixtureInfo[0].sources.find(t => t.sourceId === this.providerType.StatsPerform.toString())?.providerId;
    if (!fixtureId) {
      this.noStatistics$.next(true);
      return;
    }
    this.statisticService.addCppStatWidgets(tab, fixtureId);
  }
}
