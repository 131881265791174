import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { ResolverState } from 'src/app/shared/models/resolver.model';

function createInitialState(): ResolverState {
  return {
    nameCache: undefined,
    idCache: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resolver' })
export class ResolverStore extends Store<ResolverState> {
  constructor() {
    super(createInitialState());
  }

  updateCaches(caches: any): void {
    this.update({ nameCache: caches.nameCache, idCache: caches.idCache });
  }
}
