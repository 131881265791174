import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { circleLoaderLoading } from 'src/assets/json/lottie-configs';

@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCircleComponent {
  @Input() show = false;
  readonly betslipLoadingOptions = circleLoaderLoading;
}
