import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecentSearchesService implements OnDestroy {
  recentSearchesKey = 'recentSearches';
  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly localStorage: LocalStorageService) {}

  saveSearchKeyword(keyword: string): void {
    const storedKeywords: string[] = this.localStorage.retrieve(this.recentSearchesKey) || [];
    const isItUsedBefore = storedKeywords.indexOf(keyword);

    if (isItUsedBefore > -1) {
      storedKeywords.splice(isItUsedBefore, 1);
    }

    if (storedKeywords.length >= 5) {
      storedKeywords.pop();
    }
    this.localStorage.store(this.recentSearchesKey, [keyword, ...storedKeywords]);
  }

  clearRecentSearches(): void {
    this.localStorage.clear(this.recentSearchesKey);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
