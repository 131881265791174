import { AfterViewChecked, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSelectedTabCenterizer]',
})
export class SelectedTabCenterizerDirective implements AfterViewChecked {
  scrollEventHappened = false;

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.scrollEventHappened = true;
    const childArray = Array.from(this.elementRef.nativeElement.children);
    childArray.forEach((child: HTMLElement) => {
      if (child.classList.contains('indicator')) {
        const scrollRight =
          this.elementRef.nativeElement.scrollWidth - this.elementRef.nativeElement.offsetWidth - this.elementRef.nativeElement.scrollLeft;
        if (scrollRight < 20) {
          this.renderer.addClass(child, 'hidden');
        } else {
          this.renderer.removeClass(child, 'hidden');
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.scrollEventHappened) {
      this.scrollEventHappened = false;
      return;
    }
    const childArray = Array.from(this.elementRef.nativeElement.children);
    // If there is not any not selected element, the market chooser row is hidden
    let notSelectedElementFound = false;
    childArray.forEach((element: HTMLElement) => {
      if (!element.classList.contains('selected')) {
        notSelectedElementFound = true;
      }
    });

    if (!notSelectedElementFound) {
      this.renderer.addClass(this.elementRef.nativeElement, 'hidden');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'hidden');
    }
    const actualScrollLeft = parseInt(this.elementRef.nativeElement.scrollLeft, 10);
    const tabWidth = this.elementRef.nativeElement.offsetWidth / 3;
    if (childArray.length > 3) {
      childArray.forEach((child: HTMLElement, index) => {
        if (child.classList.contains('selected')) {
          const targetScrollLeft = (index - 1) * tabWidth;
          const scrollLeftDif = targetScrollLeft - actualScrollLeft;
          const scrollBatch = scrollLeftDif / 10;
          let counter = 1;
          const interval = window.setInterval(() => {
            counter++;
            const inProgressScrollLeft = parseInt(this.elementRef.nativeElement.scrollLeft, 10);
            const nextScrollLeft = inProgressScrollLeft + scrollBatch;
            this.elementRef.nativeElement.scrollLeft = nextScrollLeft;
            if (counter >= 10) {
              clearInterval(interval);
            }
          }, 16);
        }
      });
    }
  }
}
