import { Component } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { AndroidSettingsQuery } from 'src/app/modules/native-app/state/android-settings.query';

import { brandInfo } from 'src/brand-info';
import { first } from 'rxjs/operators';
import { APIType } from 'src/app/shared/models/api.model';
import { APIService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss'],
})
export class FooterSectionComponent {
  protected readonly footerConfig = this.appConfigService.get('footer');
  protected readonly MobilePlusVersion = this.appConfigService.get('siteVersion');

  brandInfo = brandInfo;
  legalNoticeYear = new Date().getFullYear();
  social = this.appConfigService.get('social');
  leagueOneSponsor = this.footerConfig.leagueOneSponsor;
  leagueTwoSponsor = this.footerConfig.leagueTwoSponsor;
  showAwards = this.footerConfig.showAwards;
  showPaymentIcons = this.footerConfig.showPaymentMethodImage;
  footerLicenseNumber = '';

  constructor(
    readonly applicationQuery: ApplicationQuery,
    readonly androidSettingsQuery: AndroidSettingsQuery,
    private readonly appConfigService: AppConfigService,
    private readonly apiService: APIService
  ) {
    this.apiService
      .get(APIType.CMS, 'SiteRoot/GetSiteDetails')
      .pipe(first())
      .subscribe(s => {
        this.footerLicenseNumber = s.footerLicenseNumber;
      });
  }
}
