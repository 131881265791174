<div class="totals-container">
  <div class="amount-container">
    <div class="stake-amount">
      <div class="value">
        <span class="currency-symbol">{{ currencySymbol }}</span>
        <div class="stake" (click)="delimitersOpen = true; delimitersToggle = !delimitersToggle">
          {{ stakeGrossDisplay }}</div>
        <div class="reset" [hidden]="(firstStakePress$ | async) && (firstNumberPress$ | async)"
          (click)="resetStakeValue()">
          <i class="fa fa-times"></i> <span i18n>Clear</span>
        </div>
      </div>

      <div class="setting">
        <app-toggle-switch [switch]="saveDefaultStake" [customClass]="'smaller'" (click)="updateDefaultStake()">
        </app-toggle-switch>

        <div class="label" i18n>Remember</div>
      </div>
    </div>

    <div class="details-row">
      <div class="content">
        <div class="label" i18n>Net Potential Winnings</div>
        <div class="value">{{ couponData.MaxWinNet | currencyFormat }}</div>
      </div>
    </div>
  </div>

  <div class="stake-options-container" [class.open]="delimitersOpen"
    [@expandCollapse]="delimitersToggle ? 'expanded' : 'collapsed'">
    <div class="delimiters-content">
      <div *ngFor="let amount of fixedStakes | slice:0:5; trackBy: indexTrackBy;" class="delimiter delimiter-{{amount}}"
        (click)="addStakeValue(amount)">
        {{ amount | number }}
      </div>
    </div>
    <div class="calculator-content">
      <div class="numpad-wrapper">
        <div class="numpad-container">
          <div *ngFor="let num of calculatorNumbers; trackBy: indexTrackBy;" (click)="addNumberValue(num)">{{ num }}
          </div>
        </div>
      </div>
      <div class="delete" (click)="removeNumberValue()">
        <span class="delete-icon material-icons">{{'backspace'}}</span>
        <span i18n>Del.</span>
      </div>
    </div>
  </div>
</div>
