import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-item-group',
  templateUrl: './form-item-group.component.html',
  styleUrls: ['./form-item-group.component.scss'],
})
export class FormItemGroupComponent {
  @Input() title: string;
}
