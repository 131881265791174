<div class="container" data-testid="free-bets-voucher">
  <div class="details" [class.voucher-details-in-list]="showToggle">
    <div class="right-line"></div>
    <div class="voucher-header" [class.not-selected]="showToggle && (isSelected$ | async) === false">
      <div class="icon-name-date">
        <div class="voucher-icon"></div>
        <div class="name-and-date">
          <div i18n class="free-bets-voucher-amount" data-testid="free-bets-voucher-amount">{{ amount | currencyFormat }}</div>
          <div i18n class="free-bets-voucher-name">{{ name }}</div>
        </div>
      </div>

      <div *ngIf="showRemoveButton" class="remove-button" (click)="handleClickToggle()" i18n>REMOVE</div>

      <div class="toggle" *ngIf="showToggle">
        <app-toggle-switch
          customClass="smaller"
          (click)="handleClickToggle()"
          [switch]="isSelected$ | async"
          data-testid="free-bets-voucher-switch"
        ></app-toggle-switch>
      </div>
    </div>

    <div class="divider-container">
      <div class="divider-line"></div>
      <div [class.not-selected]="showToggle && (isSelected$ | async) === false" class="divider-circle left-circle"></div>
      <div class="outside-circle">
        <div [class.not-selected]="showToggle && (isSelected$ | async) === false" class="divider-circle right-circle"></div>
      </div>
    </div>
    <div class="voucher-footer" [class.not-selected]="showToggle && (isSelected$ | async) === false">
      <div *ngIf="redemptionCriteriaValue" i18n class="criteria">{{ redemptionCriteriaValue }}</div>
      <div *ngIf="showExpirationDate" i18n class="expiration-date" data-testid="free-bets-voucher-date">Expires in {{ expirationDate | daysUntil }}</div>
    </div>
  </div>
</div>
