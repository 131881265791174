import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { kebabCase } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MatchService } from 'src/app/modules/sport/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MatchModel } from 'src/app/shared/models/sport.model';
import { LiveQuery } from 'src/app/core/state/live/live.query';

@Component({
  selector: 'app-event-tournament-details',
  templateUrl: './event-tournament-details.component.html',
  styleUrls: ['./event-tournament-details.component.scss'],
})
export class EventTournamentDetailsComponent implements OnInit {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showOddCount: boolean = true;
  @Input() isLiveListWidget: boolean = false;
  productIconClass: string;
  enableStatistics = false;

  constructor(
    readonly liveQuery: LiveQuery,
    readonly appConfigService: AppConfigService,
    readonly statisticsQuery: StatisticsQuery,
    readonly statisticsService: StatisticsService,
    readonly matchService: MatchService,
    private readonly router: Router
  ) {
    this.enableStatistics = this.showStatistics();
  }

  ngOnInit(): void {
    this.productIconClass = `icon-${this.sportId}`;
  }

  navigateToEvent(matchId: number, isLive: boolean): void {
    if (!this.event.odds[0]) {
      return;
    }

    if (isLive) {
      // TODO with task BCON-2030: kept this below line since we will soon replace it once we go live with the React pages again
      // window.location.href = `${window.location.origin}/sports/live/${matchId}`;
      this.router.navigateByUrl(`/live/${matchId}`);
    } else {
      const sport = kebabCase(this.event.odds[0].sportName);
      const category = kebabCase(this.event.categoryName);
      const tournament = kebabCase(this.event.tournamentName);
      const url = `/sports/events/prematch/${sport}/${category}/${tournament}`;

      this.router.navigate([url]);
    }
  }

  navigateToMatch(matchId: number, name: string, isLive: boolean): void {
    if (!matchId) return;

    if (isLive) {
      // TODO with task BCON-2030: kept this below line since we will soon replace it once we go live with the React pages again
      // window.location.href = `${window.location.origin}/sports/live/${matchId}`;
      this.router.navigateByUrl(`/live/${matchId}`);
    } else {
      this.matchService.navigateToMatch(matchId, name);
    }
  }

  private showStatistics(): boolean {
    return this.appConfigService.get('sports').statistics.enabled;
  }
}
