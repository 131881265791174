import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { fadeOut } from 'src/app/shared/animations';

@Component({
  selector: 'app-betslip-selections-bar',
  templateUrl: './betslip-selections-bar.component.html',
  styleUrls: ['./betslip-selections-bar.component.scss'],
  animations: [fadeOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetslipSelectionsBarComponent implements OnDestroy {
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly sportsCouponQuery: CouponQuery,
    private readonly virtualsScheduledCouponQuery: VirtualsCouponQuery,
    private readonly virtualsInstantCouponQuery: InstantCouponQuery
  ) {}

  get couponQuery$(): Observable<CouponQuery | VirtualsCouponQuery | InstantCouponQuery> {
    return combineLatest([this.applicationQuery.isVirtualsScheduled$, this.applicationQuery.isVirtualsInstant$]).pipe(
      map(([isScheduled, isInstant]) => {
        return isInstant ? this.virtualsInstantCouponQuery : isScheduled ? this.virtualsScheduledCouponQuery : this.sportsCouponQuery;
      }),
      takeUntil(this.destroy$)
    );
  }

  get textContent$(): Observable<string> {
    return this.couponQuery$.pipe(
      switchMap(couponQueryRef => {
        return couponQueryRef.couponData$.pipe(
          map(couponData =>
            couponData?.Odds?.length
              ? couponData.Odds.length === 1
                ? $localize`1 Selection`
                : $localize`${couponData.Odds.length} Selections`
              : ''
          ),
          takeUntil(this.destroy$)
        );
      }),
      takeUntil(this.destroy$)
    );
  }

  get oddValue$(): Observable<number> {
    return this.couponQuery$.pipe(
      switchMap(couponQueryRef =>
        couponQueryRef.couponTotalOdds$.pipe(
          map(totalOdds => totalOdds),
          takeUntil(this.destroy$)
        )
      ),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
