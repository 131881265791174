<div class="correct-scores-market-odds-container">
  <div class="correct-score-selections">
    <div class="selection" i18n>Home To Win</div>
    <div class="selection" i18n>Teams To Draw</div>
    <div class="selection" i18n>Away To Win</div>
  </div>
  <div class="odd-collection" *ngIf="odd">
    <div class="home-to-win odds">
      <div class="odd-wrapper" *ngFor="let oddHTW of odd.homeToWin; trackBy: oddTrackBy">
        <div class="text">
          {{oddHTW.selectionName}}
        </div>
        <app-odd [odd]="oddHTW"></app-odd>
      </div>
    </div>
    <div class="draw odds">
      <div class="draw-wrapper">
        <div class="odd-wrapper" *ngFor="let oddD of odd.draw; trackBy: oddTrackBy">
          <div class="text">
            {{oddD.selectionName}}
          </div>
          <app-odd [odd]="oddD"></app-odd>
        </div>
      </div>
      <div class="other-wrapper">
        <div class="odd-wrapper" *ngFor="let oddO of odd.others; trackBy: oddTrackBy">
          <div class="text">
            {{oddO.selectionName}}
          </div>
          <app-odd [odd]="oddO"></app-odd>
        </div>
      </div>
    </div>
    <div class="away-to-win odds">
      <div class="odd-wrapper" *ngFor="let oddATW of odd.awayToWin; trackBy: oddTrackBy">
        <div class="text">
          {{oddATW.selectionName}}
        </div>
        <app-odd [odd]="oddATW"></app-odd>
      </div>
    </div>
  </div>
</div>
