import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysUntil',
})
export class DaysUntilPipe implements PipeTransform {
  transform(expirationDate: string): string {
    // Parse the input date string into a Date object
    const inputDate = new Date(expirationDate);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = inputDate.getTime() - currentDate.getTime();

    // Convert the difference to minutes, hours, and days
    const minutes = Math.floor(differenceInMs / (1000 * 60));
    const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
    const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    // Determine the appropriate unit to display
    if (days >= 1) {
      return `${days} days`;
    } else if (hours >= 1) {
      return `${hours} hours`;
    } else if (minutes >= 1) {
      return `${minutes} minutes`;
    } else {
      return 'Time has passed'; // In case the input date is in the past
    }
  }
}
