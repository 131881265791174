import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookBetComponent } from './book-bet.component';

const routes: Routes = [
  {
    path: '',
    component: BookBetComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookBetRoutingModule {}
