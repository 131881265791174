import { NgModule } from '@angular/core';
import { BookedBetInputComponent } from 'src/app/modules/book-bet/components/booked-bet-input/booked-bet-input.component';
import { ExpiredBookedBetPromptComponent } from 'src/app/modules/book-bet/components/expired-booked-bet-prompt/expired-booked-bet-prompt.component';
import { SavedCouponsComponent } from 'src/app/modules/book-bet/components/saved-coupons/saved-coupons.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookBetRoutingModule } from './book-bet-routing.module';
import { BookBetComponent } from './book-bet.component';

const COMPONENTS = [BookBetComponent, ExpiredBookedBetPromptComponent, SavedCouponsComponent, BookedBetInputComponent];

@NgModule({
  imports: [SharedModule, BookBetRoutingModule],
  declarations: [...COMPONENTS],
  exports: [SavedCouponsComponent],
})
export class BookBetModule {}
