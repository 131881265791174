import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-update-required',
  templateUrl: './app-update-required.component.html',
  styleUrls: ['./app-update-required.component.scss'],
})
export class UpdateRequiredComponent implements OnDestroy {
  @Input() appDownloadLink: string;
  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '14px',
    height: '45px',
    textTransform: 'uppercase',
    lineHeight: '16px',
    color: '#1affff',
    fontWeight: 500,
    borderRadius: '50px',
    padding: '10px 15px',
    margin: '3px 59px',
    border: '1px solid #1affff',
    backgroundColor: 'transparent',
  };
  buttonType: typeof ButtonType = ButtonType;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  downloadApp(): void {
    window.open(this.appDownloadLink, '_system', 'location=yes,enableViewportScale=yes,hidden=no');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
