import { AfterContentInit, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoggerService } from 'src/app/core/services/logger.service';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Directive({
  selector: '[appAutocompleteOTPInputField]',
})
export class AutocompleteOTPInputFieldDirective implements OnInit, OnDestroy, AfterContentInit {
  private readonly otpAbortController = new AbortController();

  constructor(public elementRef: ElementRef, private readonly loggerService: LoggerService) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.type = 'text';
    this.elementRef.nativeElement.pattern = '[0-9]*';
    this.elementRef.nativeElement.autocomplete = 'one-time-code';
    this.elementRef.nativeElement.inputMode = 'numeric';
  }

  ngAfterContentInit(): void {
    if ('OTPCredential' in window) {
      (window as any).navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: this.otpAbortController.signal,
        } as any)
        .then(otp => {
          this.elementRef.nativeElement.value = otp.code;
        })
        .catch(err => {
          this.loggerService.logEvent('Failed to retrieve OTP from SMS', err, SeverityLevel.Information, LogTypeModel.Application);
        });
    }
  }

  ngOnDestroy(): void {
    this.otpAbortController.abort();
  }
}
