import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-web-view',
  templateUrl: './app-web-view.component.html',
  styleUrls: ['./app-web-view.component.scss'],
})
export class WebViewComponent {
  @Input() websiteUrl: string;
}
