<div class="otp-input-field">
  <form [formGroup]="verificationForm" class="verification-form"
    [ngClass]="{'invalid': (invalidCode$ | async) || (tooManyRequests$ | async) || (limitExceeded$ | async)}">
    <div>
      <input type="text" appAutocompleteOTPInputField formControlName="otpField" [placeholder]="otpFieldPlaceholder()"
        [maxlength]="otpCodeLength" [class.alone]="(showResendButton$ | async) === false || !showResendButton">
      <button class="btn resend-code" *ngIf="(showResendButton$ | async) && showResendButton"
        [disabled]="(resendCodeTimer$ | async) > 0 || (limitExceeded$ | async)" (click)="resendCode()">
        <i class="fa fa-refresh"></i>
        <span class="btn-text"><span i18n>Resend Code</span><span i18n
            *ngIf="(resendCodeTimer$ | async) > 0 && (limitExceeded$ | async) === false">
            ({{ (resendCodeTimer$ | async) }}s)</span></span>
      </button>
    </div>
    <div class="invalid-text" *ngIf="(invalidCode$ | async)">
      <span i18n>The inserted verification code is incorrect. Please try again.</span>
    </div>
    <div class="invalid-text" *ngIf="(tooManyRequests$ | async)">
      <span i18n>Too many request for regeneration of OTP.</span>
      <span i18n>Please try again later.</span>
    </div>
    <div class="invalid-text" *ngIf="(limitExceeded$ | async)">
      <span i18n>You have exceeded the limit of One Time PIN for the day.</span>
      <span i18n>Please try again later.</span>
    </div>
  </form>
</div>
